import { ResolveFn } from "@angular/router";
import { StoreService } from "../services/store.service";
import { inject } from "@angular/core";
import { UsersApi } from "../api/users.api";
import { OrganizationsApi } from "../api/organizations.api";
import { Auth } from "@angular/fire/auth";

export const organizationResolver: ResolveFn<boolean> = async (route, state) => {
  // TODO is this the best place to save the favorite org id?
  const usersApi = inject(UsersApi);
  const organizationsApi: OrganizationsApi = inject(OrganizationsApi);
  const authService = inject(Auth);
  const store: StoreService = inject(StoreService);

  const organizationId = route.params["organizationId"];
  store.organizationId = organizationId;
  store.organization$ = organizationsApi.getOne(organizationId);

  await usersApi.set(authService?.currentUser?.uid!, {
    favoriteOrganization: organizationId,
  });

  return organizationId;
};
