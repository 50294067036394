import { Component, Input } from '@angular/core';
import { IJob, IJobDetails } from '../../../../../../../../../model/IJob';
import { DeepPartial } from '../../../../../../../../../model/utils';

@Component({
  selector: 'job-preview',
  templateUrl: './job-preview.component.html',
  styleUrls: ['./job-preview.component.css']
})
export class JobPreviewComponent {
  @Input() jobDetails!: DeepPartial<IJobDetails>;
  @Input() job!: IJob | undefined;

  constructor() {}
}
