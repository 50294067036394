import {Component, Input} from '@angular/core';

@Component({
  selector: 'resume-overview-employment',
  templateUrl: './resume-overview-employment.component.html',
  styleUrls: ['../resume-overview.component.css', './resume-overview-employment.component.css']
})
export class ResumeOverviewEmploymentComponent {

  @Input() isCurrentlyEmployed: boolean = false;

}
