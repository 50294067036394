import {Component, Input, OnInit} from '@angular/core';
import {IApplicant, IApplicantNote, IApplicantRating} from "../../../../../../../../../../model/IApplicant";
import {Observable} from "rxjs";
import {ApplicantNotesApi} from "../../../../../../../api/applicant.notes.api";
import {ApplicantRatingsApi} from "../../../../../../../api/applicant.ratings.api";

@Component({
  selector: 'pipeline-stage-applicant',
  templateUrl: './pipeline-stage-applicant.component.html',
  styleUrls: ['./pipeline-stage-applicant.component.css']
})
export class PipelineStageApplicantComponent implements OnInit {

  @Input() applicant: IApplicant | undefined;
  notes$: Observable<IApplicantNote[]> | undefined;
  ratings$: Observable<IApplicantRating[]> | undefined;

  notesCount: number = 0;
  ratingsPositiveCount: number = 0;
  ratingsNegativeCount: number = 0;

  constructor(private applicantNotesApi: ApplicantNotesApi, private applicantRatingsApi: ApplicantRatingsApi) {
  }


  calculateRatings(ratings: IApplicantRating[]) {
    this.ratingsPositiveCount = 0;
    this.ratingsNegativeCount = 0;
    if(!ratings || !ratings.length) {
      return;
    }
    ratings.forEach( rating => {
      rating.isHire ? this.ratingsPositiveCount++ : this.ratingsNegativeCount++;
    })
  }

  ngOnInit() {
    if(this.applicant) {
      this.notes$ = this.applicantNotesApi.getAll(this.applicant.id);
      this.notes$.subscribe( notes => {
        this.notesCount = notes.length;
      })
      this.ratings$ = this.applicantRatingsApi.getAll(this.applicant.id);
      this.ratings$.subscribe( ratings => {
        this.calculateRatings(ratings);
      })
    }
  }

}
