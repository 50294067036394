<div class="popup column">

  <div class="popup-header row">
    <div class="popup-header-title fill-space"> Switch Organization </div>
    <div class="popup-header-close" mat-dialog-close> <img src="assets/images/close.svg" /> </div>
  </div>

  <div class="popup-loader row center" *ngIf="isLoading">
    <loader></loader>
  </div>

  <ng-container *ngIf="user$ | async as user">
    <div class="popup-content column" *ngIf="!isLoading">
       <div class="organizations-title"> Select an organization to swtich to: </div>
       <div class="organizations-content">
        <organization-panel
          *ngFor="let organization of user.resolvedOrganizationsWithRole"
          [organization]="organization"
          [routerLink]="['/organization', organization?.id]"
          mat-dialog-close>
        </organization-panel>
       </div>
    </div>
  </ng-container>

  <div class="popup-buttons row center" *ngIf="!isLoading">
    <div class="button button-gray" mat-dialog-close> Close </div>
  </div>

</div>
