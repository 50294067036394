<div class="note" [class]="note.type" *ngIf="note">
  <div class="note-content fill-space column">

    <div class="note-text fill-space" [innerHTML] = "note.text | newlineToBr"> </div>
    <div class="note-footer">
      <div class="note-user-icon center"> <img src="assets/images/user-profile-square.svg" /> </div>
      <div class="note-user row-center-left"> {{note.creatorName}}</div>
      <div class="note-date center"> &bullet;  </div>
      <div class="note-date row-center-left fill-space" *ngIf="note.creationTime"> {{ note.creationTime.toDate() | date: 'mediumDate' }}</div>
      <div class="center" (click)="showDeleteNote()" *ngIf="note.creatorId === store.userId">
        <div class="icon-button center">
          <img src="assets/images/delete.svg"/>
        </div>
      </div>
    </div>
  </div>




</div>