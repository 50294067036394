import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { IOrganizationInvitation } from "../../../../../model/IOrganizationInvitation";
import { OrganizationInvitationsService } from "../../services/organization-invitations.service";
import { Auth } from "@angular/fire/auth";
import { EInvitationStatus } from "../../../../../model/enums/EInvitationStatus";

@Component({
  selector: "settings-invitation",
  templateUrl: "./settings-invitations.component.html",
  styleUrls: ["./settings-invitations.component.css"],
})
export class SettingsInvitationsComponent {
  pendingInvitations$?: Observable<(IOrganizationInvitation | undefined)[]>;

  constructor(
    private organizationInvitationsService: OrganizationInvitationsService,
    private auth: Auth,
  ) {}

  ngOnInit() {
    const userEmail = this.auth.currentUser?.email!;
    this.pendingInvitations$ = this.organizationInvitationsService.getByStatus(
      userEmail,
      EInvitationStatus.PENDING,
    );
  }
}
