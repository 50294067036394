import {Component, Input} from '@angular/core';
import {IInvitation} from "../../../../../../model/IInvitation";
import {IOrganizationUser} from "../../../../../../model/IOrganizationUser";
import {EInvitationStatus} from "../../../../../../model/enums/EInvitationStatus";
import {IUser} from "../../../../../../model/IUser";
import {UserService} from "../../../services/user.service";
import {TransactionsApi} from "../../../api/transactions.api";

@Component({
  selector: 'invitation-panel',
  templateUrl: './invitation-panel.component.html',
  styleUrls: ['./invitation-panel.component.css']
})
export class InvitationPanelComponent {

  @Input() invitation: IInvitation | undefined;

  constructor(private userService: UserService, private transactionsApi: TransactionsApi) {
  }

  acceptInvitation(invitation: IInvitation) {
    const organizationUser: Partial<IOrganizationUser> = {
      status: EInvitationStatus.ACCEPTED,
      name: this.userService.user!.name,
      userId: this.userService.user!.id
    }
    const user: Partial<IUser> = {
      favoriteOrganization: invitation.organizationId,
      organizations: [...this.userService.user?.organizations || [], invitation.organizationId]
    }
    this.transactionsApi.acceptInvitation(this.userService.user!.id, user, invitation, organizationUser).then( result => {

    }).catch( error => {

    }).finally( () =>{

    })
  }

}
