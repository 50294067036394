import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumDisplay',
})
export class EnumDisplayPipe implements PipeTransform {
  transform(value: string, enumType: any): string {
    return enumType[value] || 'Unknown';
  }
}
