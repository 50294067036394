import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  // Utility function to safely retrieve nested property values
  private deepValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  transform(array: any[], field: string, direction: string = 'asc'): any[] {
    if (!Array.isArray(array)) {
      return [];
    }

    array.sort((a, b) => {
      const aValue = this.deepValue(a, field);
      const bValue = this.deepValue(b, field);

      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      } else if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });

    return array;
  }
}
