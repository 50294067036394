import { Component, OnInit } from '@angular/core';
import { PortalSettingsService } from '../../../../services/portal-settings.service';
import { Observable, of, switchMap, tap } from 'rxjs';
import { IOrganization } from '../../../../../../../model/IOrganization';
import { ActivatedRoute } from '@angular/router';
import { OrganizationsApi } from '../../../../api/organizations.api';

@Component({
  selector: 'organization-portal-info',
  templateUrl: './organization-portal-info.component.html',
  styleUrls: ['./organization-portal-info.component.css']
})
export class OrganizationPortalInfoComponent implements OnInit {
  formValues!: Observable<any>;
  organization$: Observable<IOrganization | null | undefined> | undefined;
  logoUrl: string | ArrayBuffer | null = null;
  constructor(
    private portalSettingsService: PortalSettingsService,
    private route: ActivatedRoute,
    private organizationApi: OrganizationsApi
  ) {}

  ngOnInit() {
    this.organization$ = this.route.params.pipe(
      switchMap((params) => {
        const organizationId = params['organizationId'];
        return organizationId ? this.organizationApi.getOne(organizationId) : of(undefined);
      })
    );

    this.formValues = this.portalSettingsService.formValues$.pipe(
      tap((formValues) => {
        if (formValues?.file && formValues?.file?.name) {
          const reader = new FileReader();
          reader.onload = () => {
            this.logoUrl = reader.result;
          };
          reader.readAsDataURL(formValues.file);
        }
      })
    );
  }
}
