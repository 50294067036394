import {Component, Input} from '@angular/core';
import {IJob} from "../../../../../../../../model/IJob";
import {MatDialog} from "@angular/material/dialog";
import {JobApplicantSelectorComponent} from "../../job-applicants-selector/job-applicant-selector.component";
import {UploadComponent} from "../../../../../common/upload/upload.component";

@Component({
  selector: 'existing-or-upload-selector',
  templateUrl: './existing-or-upload-selector.component.html',
  styleUrls: ['./existing-or-upload-selector.component.css']
})
export class ExistingOrUploadSelectorComponent {

  @Input() job: IJob | undefined;

  constructor(private dialog: MatDialog) {
  }

  showApplicantSelector() {
    let dialog = this.dialog.open(JobApplicantSelectorComponent, {});
    dialog.componentInstance.jobId = this.job?.id;
  }

  showUpload(): void {
    let dialog = this.dialog.open(UploadComponent, {});
    dialog.componentInstance.job = this.job;
  }

}
