<a
  class="row row-center-left"
  *ngIf="organization$ | async as organization"
  [routerLink]="'/user-organizations'"
>
  <div class="banner-button row row-center-left">
    <div class="banner-button-icon">
      <img src="assets/images/organization.svg" />
    </div>
    <div class="banner-button-content column column-center-left">
      <div class="banner-button-content-label column-center-left">
        Organization
      </div>
      <div class="banner-button-content-value column-center-left">
        {{ organization.name }}
      </div>
    </div>
  </div>
</a>
