import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationsApi } from '../../../api/organizations.api';
import { StoreService } from '../../../services/store.service';
import { debounceTime, distinctUntilChanged, filter, Observable, switchMap, tap } from 'rxjs';
import {
  ILogoFile,
  IOrganizationContact,
  IOrganizationDetails,
  IOrganizationPortalInfo,
  IPortalConfiguration
} from '../../../../../../model/IOrganization';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StorageApi } from '../../../api/storage.api';
import { PortalSettingsService } from '../../../services/portal-settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'settings-portal',
  templateUrl: './settings-portal.component.html',
  styleUrls: ['./settings-portal.component.css', '../settings.component.css']
})
export class SettingsPortalComponent implements OnInit {
  organization$: Observable<IOrganizationPortalInfo | undefined>;
  organization: IOrganizationPortalInfo | undefined;
  showSubmitButton: boolean = false;
  portalEndpoint: string | undefined = '';
  companyForm: FormGroup = this.fb.group({
    portalEndpoint: ['', Validators.required],
    companyInfo: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    website: ['', Validators.required],
    phone: [''],
    file: [null, Validators.required],
    autoProcess: [false, Validators.required]
  });

  portalDomain: string = 'portal.hirefox.ai';
  isPortalEnabled = new FormControl();
  private initialFormValues: any;
  isPortalEndpointAvailable: boolean = true;
  isLoading: boolean = true;

  constructor(
    public dialog: MatDialog,
    private organizationService: OrganizationsApi,
    private store: StoreService,
    private fb: FormBuilder,
    private storageApi: StorageApi,
    private portalSettingsService: PortalSettingsService,
    private snackBar: MatSnackBar
  ) {
    this.organization$ = this.organizationService.getPortalSettings(this.store.organizationId!);
  }

  ngOnInit(): void {
    this.organization$.subscribe((organization) => {
      this.organization = organization;
      this.initializeForm(organization);
      this.portalEndpoint = organization?.portalConfiguration?.portalEndpoint;
      this.portalSettingsService.updateIsPortalEnabled(organization?.isPortalEnabled ? organization.isPortalEnabled : false);
      this.isLoading = false;
    });

    this.companyForm.valueChanges.subscribe((values) => {
      // set the form values to a shared service
      // so live preview can have access to the data
      this.portalSettingsService.updateFormValues(values);

      this.showSubmitButton = this.showSubmitButton =
        JSON.stringify(this.initialFormValues) !== JSON.stringify(this.companyForm.getRawValue());
    });

    // Checks if the portalEndpoint is available
    this.portalEndpointControl?.valueChanges
      ?.pipe(
        tap((value: string) => {
          if (!value){
            this.isPortalEndpointAvailable = true;
          }
        }),
        debounceTime(400),
        distinctUntilChanged(),
        filter((value: string) => !!value.trim() && value !== this.portalEndpoint),
        switchMap((value: string) => {
          console.log(value);
          return this.organizationService.isPortalEndpointAvailable(value);
        })
      )
      .subscribe((available) => {
        this.isPortalEndpointAvailable = available;
        if (!available) this.portalEndpointControl?.setErrors({});
        console.log(available);
      });
  }

  private initializeForm(organization: IOrganizationPortalInfo | undefined) {
    this.companyForm.patchValue({
      portalEndpoint: organization?.portalConfiguration?.portalEndpoint ?? '',
      companyInfo: organization?.portalConfiguration?.organizationInfo.companyInfo ?? '',
      email: organization?.portalConfiguration?.organizationInfo?.contact?.email ?? '',
      website: organization?.portalConfiguration?.organizationInfo?.contact?.website ?? '',
      phone: organization?.portalConfiguration?.organizationInfo?.contact?.phone ?? '',
      file: organization?.portalConfiguration?.file ?? null,
      autoProcess: organization?.portalConfiguration?.autoProcess ?? false
    });

    this.isPortalEnabled.setValue(organization?.isPortalEnabled ?? false);

    this.initialFormValues = this.companyForm.getRawValue();

    this.showSubmitButton = JSON.stringify(this.initialFormValues) !== JSON.stringify(this.companyForm.getRawValue());
  }

  get portalEndpointControl() {
    return this.companyForm.get('portalEndpoint');
  }
  get emailControl() {
    return this.companyForm.get('email');
  }

  get phoneControl() {
    return this.companyForm.get('phone');
  }
  get websiteControl() {
    return this.companyForm.get('website');
  }
  get companyInfoControl() {
    return this.companyForm.get('companyInfo');
  }
  get fileControl() {
    return this.companyForm.get('file') as FormControl;
  }
  get autoProcessControl() {
    return this.companyForm.get('autoProcess') as FormControl;
  }

  async onSubmit() {
    if (this.companyForm.invalid) return;

    // if the file has the property name that means it's a new file
    // if not it will have the property originalName and that means that the file doesn't need changes
    if (this.fileControl.value.name) {
      const uploadedLogo = await this.storageApi.uploadPortalLogo(this.fileControl.value.name, this.fileControl.value);
    }

    const dto = await this.makeDto();

    // const portalConfig = await this.organizationService.updatePortalConfiguration(this.store.organizationId!, dto);
    this.organizationService
      .updatePortalConfiguration(this.store.organizationId!, dto)
      .then((r) => {
        console.log('Successfully updated Portal details.');
        this.snackBar.open('Portal Settings Were Updated Successfully!', undefined, {
          duration: 3000
        });
      })
      .catch((error) => {
        console.error(error);
        this.snackBar.open('An error occurred while updating the Portal Settings. Please try again.', undefined, {
          duration: 3000
        });
      });
  }

  getChangedValues(): any {
    const currentValues = this.companyForm.getRawValue();
    const changedValues: any = {};

    Object.keys(currentValues).forEach((key) => {
      if (currentValues[key] !== this.initialFormValues[key]) {
        changedValues[key] = currentValues[key];
      }
    });

    return changedValues;
  }

  private async makeDto() {
    const formValues = this.companyForm.getRawValue();
    const contact: IOrganizationContact = {
      email: formValues.email,
      website: formValues.website
    };
    if (formValues.phone) {
      contact.phone = formValues.phone;
    }

    const organizationDetails: IOrganizationDetails = {
      companyInfo: formValues.companyInfo,
      contact: contact
    };

    let file = {} as ILogoFile;
    // if the file has the property name that means it's a new file
    // if not it will have the property originalName and that means that the file doesn't need changes
    if (this.fileControl.value.name) {
      const filePath = `${this.storageApi.getPortalLogoStoragePath()}${this.fileControl.value.name}`;
      const fileUrl = await this.storageApi.getFileUrl(filePath);
      file.type = this.fileControl.value.type;
      file.size = this.fileControl.value.size;
      file.originalName = this.fileControl.value.name;
      file.path = filePath;
      file.url = fileUrl;
    } else {
      file = this.fileControl.value;
    }

    const portalConfiguration: IPortalConfiguration = {
      portalEndpoint: formValues.portalEndpoint,
      organizationInfo: organizationDetails,
      autoProcess: formValues.autoProcess,
      file: file
    };

    return portalConfiguration;
  }

  // private makeDto(changedValues: any) {
  //   const dto: any = {};
  //   console.log(changedValues);
  //   if (changedValues.email) {
  //     dto['portalConfiguration.organizationInfo.contact.email'] = changedValues.email;
  //   }
  //   if (changedValues.phone) {
  //     dto['portalConfiguration.organizationInfo.contact.phone'] = changedValues.phone;
  //   }
  //   if (changedValues.website) {
  //     dto['portalConfiguration.organizationInfo.contact.website'] = changedValues.website;
  //   }
  //   if (changedValues.companyInfo) {
  //     dto['portalConfiguration.organizationInfo.companyInfo'] = changedValues.companyInfo;
  //   }
  //   if (changedValues.portalEndpoint) {
  //     dto['portalConfiguration.portalEndpoint'] = changedValues.portalEndpoint;
  //   }
  //   return dto;
  // }

  async uploadLogo() {
    console.log(this.fileControl.value);
    // await this.storageApi.uploadPortalLogo(this.file.value.name, this.file.value);
  }

  canSubmit() {
    return this.showSubmitButton && this.companyForm.valid;
  }

  togglePortal($event: Event) {
    this.organizationService.togglePortal(this.store.organizationId!, this.store.organizationName!, this.isPortalEnabled.value);
  }

  onFileChange($event: File | null) {
    console.log($event);
    this.fileControl.setValue($event);
  }

  // canDeactivate(): boolean | Observable<boolean> {
  //   return false;
  // }
  protected readonly environment = environment;
}
