import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, switchMap} from "rxjs";
import {IJob} from "../../../../../../../../model/IJob";
import {ActivatedRoute} from "@angular/router";
import {JobsApi} from "../../../../../api/jobs.api";
import {PipelineService} from "../../../../../services/pipeline.service";

@Component({
  selector: 'pipeline',
  templateUrl: './pipeline.component.html',
  styleUrls: ['./pipeline.component.css']
})
export class PipelineComponent implements OnInit, OnDestroy {

  job$: Observable<IJob|undefined> | undefined;


  constructor(private route: ActivatedRoute, private jobsApi: JobsApi, public pipelineService: PipelineService) {
  }

  ngOnInit() {
    this.job$ = this.route.paramMap.pipe(
        switchMap((params) => {
          let jobId = params.get("jobId");
          return jobId ? this.jobsApi.getOne(jobId) : of(undefined);
        }),
    );

  }

  ngOnDestroy() {
  }
}
