<ng-container *ngIf="applicant$ | async as applicant">

<div class="applicant-evaluation-scores gap-2 wrap" *ngIf="applicant.matchAnalysis as ma">


    <div class="scores column fill-space-equal gap-2 column-center-left">
      <div class="highlight">
        <applicant-evaluation-score [title]="'Overall'" [isHeaderTitle]="true" [score]="ma.overallScore" [averageScore]="averageOverallScore" [scoreJustification]="ma.overallScoreReasoning"></applicant-evaluation-score>
      </div>
      <applicant-evaluation-score [title]="'Experience'" [score]="ma.experienceScore" [averageScore]="averageExperienceScore" [scoreJustification]="ma.experienceScoreReasoning"></applicant-evaluation-score>
      <applicant-evaluation-score [title]="'Education'" [score]="ma.educationScore" [averageScore]="averageEducationScore" [scoreJustification]="ma.educationScoreReasoning"></applicant-evaluation-score>
      <applicant-evaluation-score [title]="'Skills'" [score]="ma.skillsScore" [averageScore]="averageSkillsScore" [scoreJustification]="ma.skillsScoreReasoning"></applicant-evaluation-score>
      <applicant-evaluation-score [title]="'Languages'" [score]="ma.languagesScore" [averageScore]="averageLanguagesScore" [scoreJustification]="ma.languagesScoreReasoning"></applicant-evaluation-score>
      <applicant-evaluation-score [title]="'Other'" [score]="ma.otherScore" [averageScore]="averageOtherScore" [scoreJustification]="ma.otherScoreReasoning"></applicant-evaluation-score>
    </div>

    <div class="column fill-space-equal center">
      <applicant-evaluation-radar [applicant$]="applicant$" [candidateScores]="candidateScores" [averageScores]="averageScores"></applicant-evaluation-radar>
    </div>

</div>


</ng-container>