import { Injectable } from "@angular/core";
import {
  Auth,
  User,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  UserCredential,
  signInWithRedirect,
  user,
  sendPasswordResetEmail,
} from "@angular/fire/auth";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private firebaseAuth: Auth) {}

  firebaseUser$: Observable<User | null> = user(this.firebaseAuth);

  signUpWithEmail(email: string, password: string): Promise<UserCredential> {
    if (!email || !password) {
      return Promise.reject(new Error("Invalid email or password"));
    }
    return createUserWithEmailAndPassword(this.firebaseAuth, email, password);
  }

  signInWithEmail(email: string, password: string): Promise<UserCredential> {
    if (!email || !password) {
      return Promise.reject(new Error("Invalid email or password"));
    }
    return signInWithEmailAndPassword(this.firebaseAuth, email, password);
  }

  signInWithGoogle(): Promise<UserCredential> {
    return signInWithPopup(this.firebaseAuth, new GoogleAuthProvider());
  }

  sendPasswordResetEmail(email: string) {
    if (!email) {
      return Promise.reject(new Error("Not a valid user email"));
    }
    const options = {
      url: environment.passwordReset,
    };
    return sendPasswordResetEmail(this.firebaseAuth, email, options);
  }

  signOut() {
    return this.firebaseAuth.signOut();
  }
}
