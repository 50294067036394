export enum FirebaseCollectionNames {
  TRIGGERS = 'triggers',
  EVENTS = 'events',
  USERS = 'users',
  ORGANIZATIONS = 'organizations',
  JOBS = 'jobs',
  RESUMES = 'resumes',
  APPLICANTS = 'applicants',
  INVITATIONS = 'invitations',
  PROBLEM_REPORTS = 'problemReports',
  RESUME_TAGS = 'resumeTags',
  JOB_TAGS = 'jobTags',
  CREDITS = 'credits',
  CREDITS_USAGE = 'creditsUsage',
  EMAIL_MESSAGES = 'emailMessages',
  PORTAL_APPLICATIONS = 'portalApplications',
  PORTAL_USERS = 'portalUsers',
  PORTAL_ENDPOINTS = 'portalEndpoints',
  EMAILS = 'emails',
  PORTAL_ORGANIZATIONS  ='portalOrganizations'
}

export enum FirebaseStripeCollectionNames {
  CUSTOMERS = 'customers',
  UPDATE_SUBSCRIPTION = 'update_subscription_sessions',
  PRODUCTS = 'products', // This needs to match the collection name in the Stripe Firebase extension
  CONFIGURATION = 'configuration' // This needs to match the collection name in the Stripe Firebase extension
}

export enum FirebaseStorageFolderNames {
  ORGANIZATIONS = 'organizations',
  PORTAL_APPLICATIONS = 'portalApplications',
  RESUMES = 'resumes',
  JOBS = 'jobs',
  IMAGES = 'images',
  ASSETS = 'assets',
  LOGO = 'logo'
}
