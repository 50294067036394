import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { IResumeScore } from '../../../../../../../../model/IResume';

@Component({
  selector: 'resume-overview-radar',
  templateUrl: './resume-overview-radar.component.html',
  styleUrls: ['./resume-overview-radar.component.css'],
})
export class ResumeOverviewRadarComponent implements OnInit {
  @Input() resumeScore: IResumeScore | undefined;

  chart = {
    type: 'radar',
    options: {
      elements: {
        line: { borderWidth: 3 },
      },
      scales: {
        r: {
          angleLines: {
            display: false,
          },
          suggestedMin: 0,
          suggestedMax: 100,
        },
      },
    },
    data: {
      labels: ['Experience', 'Education', 'Soft Skills', 'Technical Skills', 'Languages', 'Projects'],
      datasets: [
        {
          label: 'Candidate',
          data: [65, 59, 90, 81, 56, 89],
          fill: true,
          backgroundColor: 'rgba(143, 207, 156, 0.2)',
          borderColor: 'rgb(143, 207, 156)',
          pointBackgroundColor: 'rgb(61, 161, 87)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(255, 99, 132)',
          pointRadius: 4,
          tension: 0.1,
        },
        {
          label: 'Average',
          data: [28, 48, 40, 19, 96, 60],
          fill: true,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          pointBackgroundColor: 'rgb(54, 162, 235)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(54, 162, 235)',
          pointRadius: 4,
          tension: 0.1,
        },
      ],
    },
  };

  averageScore: IResumeScore = {
    experience: 65,
    education: 75,
    softSkills: 75,
    technicalSkills: 65,
    languages: 65,
    projects: 65,
  };

  ngOnInit() {
    if (this.resumeScore) {
      // Resume Score
      this.chart.data.datasets[0].data = [
        this.resumeScore.experience,
        this.resumeScore.education,
        this.resumeScore.softSkills,
        this.resumeScore.technicalSkills,
        this.resumeScore.languages,
        this.resumeScore.projects,
      ];
      // Average Score
      this.chart.data.datasets[1].data = [
        this.averageScore.experience,
        this.averageScore.education,
        this.averageScore.softSkills,
        this.averageScore.technicalSkills,
        this.averageScore.languages,
        this.averageScore.projects,
      ];
    }
  }
}
