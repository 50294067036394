import { Component, EventEmitter, forwardRef, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleButtonComponent),
      multi: true
    }
  ]
})
export class ToggleButtonComponent {
  @Output() change = new EventEmitter<boolean>();
  isDisabled: boolean = false;
  value: boolean = false;
  onChange: any = () => {};
  onTouched: any = () => {};

  get buttonClass(): string {
    return this.value ? 'toggle-on' : 'toggle-off';
  }

  toggle() {
    if (!this.isDisabled) {
      this.value = !this.value;
      this.onChange(this.value);
      this.onTouched();
      this.change.emit(this.value);
    }
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
