import {Component, Input} from '@angular/core';
import {IJob} from "../../../../../../../model/IJob";
import {JobApplicantSelectorComponent} from "../job-applicants-selector/job-applicant-selector.component";
import {MatDialog} from "@angular/material/dialog";
import {EJobStatus} from "../../../../../../../model/enums/EJobStatus";
import {EStatus} from "../../../../../../../model/enums/EResumeStatuses";
import {UploadComponent} from "../../../../common/upload/upload.component";
import {ExistingOrUploadSelectorComponent} from "./existing-or-upload-selector/existing-or-upload-selector.component";
import {JobAiInstructionsComponent} from "../job-ai-instructions/job-ai-instructions.component";

@Component({
  selector: 'job-banner',
  templateUrl: './job-banner.component.html',
  styleUrls: ['./job-banner.component.css']
})
export class JobBannerComponent {

    @Input() job: IJob | undefined;

    constructor(private dialog: MatDialog) {
    }

    showAddApplicant() {
        let dialog = this.dialog.open(ExistingOrUploadSelectorComponent, {});
        dialog.componentInstance.job = this.job;
    }

    showScoringGuidelines() {
        let dialog = this.dialog.open(JobAiInstructionsComponent, {});
        dialog.componentInstance.job = this.job;
    }

    protected readonly EJobStatus = EJobStatus;
    protected readonly EStatus = EStatus;
}
