<div class="user row row-center-left" *ngIf="user">
    <div class="user-icon column">
        <img src="assets/images/user.svg" />
    </div>
    <div class="user-content column">
        <div class="user-name">{{ user.name }}</div>
        <div class="user-email">{{ user.email }}</div>
    </div>
    <div class="column center"> <div class="user-role"> {{ user.role }} </div> </div>
    <div class="fill-space"></div>
    <div class="user-verification row" *ngIf="user.status === EInvitationStatus.PENDING">
        <div class="user-verification-icon center"> <img src="assets/images/tick-gray.svg"/> </div>
        <div class="user-verification-text center"> Invitation Pending </div>
    </div>
    <div class="user-buttons" *ngIf="user.role != EUserRole.OWNER">
        <div class="column center space-right-1" (click)="showEditUser()">
            <div class="icon-button center">
                <img src="assets/images/edit.svg"/>
            </div>
        </div>
        <div class="column center" (click)="showDeleteUser()">
            <div class="icon-button center">
                <img src="assets/images/delete.svg"/>
            </div>
        </div>
    </div>
</div>
