<div class="column">
  <div class="column preview-title-container space-top-bottom-2">
    <div class="view-type">List View</div>
    <div class="view-info">This is how your job will look in the list of jobs in your portal</div>
  </div>
  <div class="fill-space column border">
    <job-portal-view [jobDetails]="jobDetails" [job]="job"></job-portal-view>
  </div>

  <div class="column preview-title-container space-top-bottom-2">
    <div class="view-type">Detailed View</div>
    <div class="view-info">This is how your job will look in the portal when a user clicks on it to get all the details.</div>
  </div>
  <div class="fill-space column detailed-view">
    <job-portal-view [jobDetails]="jobDetails" [showPostedDate]="false" [showJobStatus]="false" [showArrow]="false"></job-portal-view>

    <div class="actions">
      <div class="button apply-button">Apply</div>
      <div class="button copy-button">Copy Link To Job</div>
    </div>

    <div class="job-description">
      <h4>Job Description</h4>
      <job-portal-description-view [description]="jobDetails.description"></job-portal-description-view>
    </div>
  </div>
</div>
