import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IApplicant, IMatchAnalysis} from "../../../../../../../../../../../model/IApplicant";
import {UtilService} from "../../../../../../../../services/util.service";
import {IResumeScore} from "../../../../../../../../../../../model/IResume";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {ApplicantsService} from "../../../../../../../../services/applicants.service";

@Component({
  selector: 'applicant-evaluation-scores',
  templateUrl: './applicant-evaluation-scores.component.html',
  styleUrls: ['./applicant-evaluation-scores.component.css']
})
export class ApplicantEvaluationScoresComponent implements OnInit {

  @Input() applicant$: Observable<IApplicant|undefined> | undefined;
  averageOverallScore: number = 0;
  averageExperienceScore: number = 0;
  averageEducationScore: number = 0;
  averageSkillsScore: number = 0
  averageLanguagesScore: number = 0;
  averageOtherScore: number = 0;
  candidateScores: number[] = [0,0,0,0,0];
  averageScores: number[] = [0,0,0,0,0];



  constructor(private route: ActivatedRoute, private applicantsService: ApplicantsService) {
  }



  calculateAverageScores(applicants: IApplicant[]): number[] {
    if(!applicants.length) { return  [0,0,0,0,0]; }

    this.averageOverallScore = 0;
    this.averageExperienceScore = 0;
    this.averageEducationScore = 0;
    this.averageSkillsScore = 0
    this.averageLanguagesScore = 0;
    this.averageOtherScore = 0;

      let size = applicants.length;
      applicants.forEach(applicant => {
        if(applicant.matchAnalysis) {
          this.averageOverallScore += applicant.matchAnalysis.overallScore;
          this.averageExperienceScore += applicant.matchAnalysis.experienceScore;
          this.averageEducationScore += applicant.matchAnalysis.educationScore;
          this.averageSkillsScore += applicant.matchAnalysis.skillsScore;
          this.averageLanguagesScore += applicant.matchAnalysis.languagesScore;
          this.averageOtherScore += applicant.matchAnalysis.otherScore;
        }
      });
      this.averageOverallScore = Math.floor(this.averageOverallScore / size);
      this.averageExperienceScore = Math.floor(this.averageExperienceScore / size);
      this.averageEducationScore = Math.floor(this.averageEducationScore / size);
      this.averageSkillsScore = Math.floor(this.averageSkillsScore / size);
      this.averageLanguagesScore = Math.floor(this.averageLanguagesScore / size);
      this.averageOtherScore = Math.floor(this.averageOtherScore / size);


    return [this.averageExperienceScore, this.averageEducationScore, this.averageSkillsScore, this.averageLanguagesScore, this.averageOtherScore];
  }

  updateScores(matchAnalysis: IMatchAnalysis | undefined) {
    if (matchAnalysis) {
      this.candidateScores = [
          matchAnalysis.experienceScore,
          matchAnalysis.educationScore,
          matchAnalysis.skillsScore,
          matchAnalysis.languagesScore,
          matchAnalysis.otherScore
      ];
    }
  }

  ngOnInit() {

    this.applicant$?.subscribe(applicant => {
      if (applicant) {
        this.updateScores(applicant.matchAnalysis);
      }
    })

    this.applicantsService.applicants$?.subscribe( applicants => {
      if(applicants) {
        this.averageScores = this.calculateAverageScores(applicants);
      }
    })

  }

}
