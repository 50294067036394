<div class="resume-panel" [class]="{'resume-panel-selected': isSelected }" *ngIf="resume && resume.resumeAnalysis && resume.resumeAnalysis.info">

    <div class="resume-panel-checkbox center space-right-1"> <checkbox [isChecked]="isSelected"></checkbox> </div>
    <div class="resume-panel-image center">
        <resume-image [image]="resume.image"></resume-image>
    </div>
    <div class="resume-panel-content fill-space column"> <div class="resume-panel-content-name"> {{ resume.resumeAnalysis.info.name }} </div>
        <div class="resume-panel-content-job row">
            <div class="resume-panel-content-job-position empty-info center" *ngIf="!resume.resumeAnalysis.info.lastJobPosition"> No Previous Work Experience </div>
            <div class="resume-panel-content-job-position center" *ngIf="resume.resumeAnalysis.info.lastJobPosition"> {{ resume.resumeAnalysis.info.lastJobPosition.toLowerCase() }} </div>
            <div class="resume-panel-content-job-dot center"> <img src="assets/images/dot-gray.svg" /> </div>
            <div class="resume-panel-content-job-company empty-info center" *ngIf="!resume.resumeAnalysis.info.lastJobCompany"> No Company Info </div>
            <div class="resume-panel-content-job-company center" *ngIf="resume.resumeAnalysis.info.lastJobCompany"> {{ resume.resumeAnalysis.info.lastJobCompany.toLowerCase() }} </div>
        </div>
        <div class="resume-panel-content-education" *ngIf="resume.resumeAnalysis.info.educationInstitution"> {{ resume.resumeAnalysis.info.educationInstitution.toLowerCase() }} </div>
        <div class="resume-panel-content-skills">
            <div class="resume-panel-skill" *ngFor="let s of resume.resumeAnalysis?.content?.skills?.technical?.slice(0, 3)"> {{ s }} </div>
        </div>
    </div>

</div>
