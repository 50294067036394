<div class="applicant" [class]="getSelectedClass()" *ngIf="applicant && applicant.info">

  <div class="applicant-image center">
    <resume-image [image]="applicant.image"></resume-image>
  </div>
  <div class="applicant-content fill-space column">
    <div class="applicant-content-name">{{ applicant.info.name }}</div>
    <div class="applicant-content-job row">
      <div class="applicant-content-job-position center"> {{ applicant.info.lastJobPosition }} </div>
      <div class="applicant-content-job-position empty-info center" *ngIf="!applicant.info.lastJobPosition"> No Previous Work Experience </div>
      <div class="applicant-content-job-dot center"> <img src="assets/images/dot-gray.svg" /> </div>
      <div class="applicant-content-job-company center"> {{ applicant.info.lastJobCompany }} </div>
      <div class="applicant-content-job-company empty-info center" *ngIf="!applicant.info.lastJobCompany"> No Company Info </div>
    </div>
    <div class="applicant-content-education"> {{ applicant.info.educationInstitution }} </div>
    <div class="applicant-content-scores row">
      <div class="score row">
        <div class="score-icon center">
          <div class="circle" [class]="categoryDefinition?.styleClass + '-bg'"></div>
        </div>
        <div class="score-value center"> {{ applicant.matchAnalysis?.experienceScore }}% Exp </div>
      </div>
      <div class="score row">
        <div class="score-icon center">
          <div class="circle" [class]="categoryDefinition?.styleClass + '-bg'"></div>
        </div>
        <div class="score-value center">{{ applicant.matchAnalysis?.educationScore }}% Edu</div>
      </div>
      <div class="score row">
        <div class="score-icon center">
          <div class="circle" [class]="categoryDefinition?.styleClass + '-bg'"></div>
        </div>
        <div class="score-value center"> {{ applicant.matchAnalysis?.skillsScore }}% Skills</div>
      </div>
    </div>
    <div class="applicant-rejected-reason"  *ngIf="applicant.matchAnalysis?.isRejected">
        Rejection Justification: {{applicant.matchAnalysis?.isRejectedReasoning}}
    </div>
  </div>
  <div class="processing-status" *ngIf="applicant.status != EStatus.COMPLETED">
    <div class="processing-status-loader center" *ngIf="applicant.status != EStatus.ERROR"> <loader></loader> </div>
    <div class="processing-status-text center" [class]="{'error': applicant.status === EStatus.ERROR}"> {{applicant.status}} </div>
  </div>
  <div class="applicant-score column center" *ngIf="applicant.matchAnalysis && applicant.matchAnalysis.overallScore">
    <div class="row">
      <div class="applicant-score-icon center"> <img [src]="'assets/images/' + categoryDefinition?.icon" /></div>
      <div class="applicant-score-value center" [class]="categoryDefinition?.styleClass"> {{ applicant.matchAnalysis.overallScore }}% </div>
    </div>
    <div class="applicant-score-label row-center-right" [class]="categoryDefinition?.styleClass"> {{ categoryDefinition?.label }} </div>
  </div>
  <div class="applicant-arrow center"> <img src="assets/images/arrow-right.svg" /> </div>
</div>
