<div class="resume-content-section column fill-space">

  <div class="resume-content-section-header fill-space">
    <div class="resume-content-section-header-icon center"> <img src="assets/images/section-conferences.svg"/> </div>
    <div class="resume-content-section-header-title center"> Presentations & Conferences </div>
  </div>

  <div class="resume-content-section-data column fill-space">
    <empty-list text="No Presentations/Conferences" *ngIf="!presentationsAndConferences?.length"></empty-list>
    <ng-container *ngIf="presentationsAndConferences">
      <div class="resume-node column" *ngFor="let p of presentationsAndConferences">
        <div class="resume-node-header">
          <div class="resume-node-icon"> <img src="assets/images/resume-list-presentations.svg"/> </div>
          <div class="resume-node-header-content column">
            <div class="resume-node-title capitalize"> {{ p.title}} </div>
            <div class="capitalize" [class]="{'resume-node-subtitle': p.event, 'empty-info': !p.event}">
              {{ p.event ? p.event.toLowerCase() : 'No Event Info' }}
            </div>
          </div>
        </div>
        <div class="resume-node-content column">
          <div class="resume-node-date">
            <div class="resume-node-date-icon center"> <img src="assets/images/calendar.svg"/> </div>
            <div class="resume-node-date-text gap-1 row-center-left">
              <div class="resume-node-info-data" > Date: {{ p.date ? p.date : 'Unknown' }} </div>
            </div>
          </div>
          <div class="presentations-info column">
            <div class="resume-node-info capitalize">
              <div class="resume-node-info-label">  Location: </div>
              <div class="resume-node-info-data"> {{ p.location ? p.location : "Unknown" }} </div>
            </div>
            <div class="resume-node-info capitalize">
              <div class="resume-node-info-label">  Url: </div>
              <div class="resume-node-info-data"> {{ p.url ? p.url : "Unknown" }}  </div>
            </div>
          </div>
          <div class="resume-node-description" *ngIf="p.description">  {{ p.description }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
