import { Component, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map, merge, Observable, Subscription, tap } from 'rxjs';
import { IOrganization } from '../../../../../../model/IOrganization';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OrganizationsApi } from '../../../api/organizations.api';
import { StoreService } from '../../../services/store.service';
import { CanComponentDeactivate } from '../../../guards/unsaved-changes.guard';
import { SettingsPortalComponent } from '../settings-portal/settings-portal.component';
import { PortalSettingsService } from '../../../services/portal-settings.service';

interface NameChangeWithSource {
  name: string;
  source: 'server' | 'local';
}

@Component({
  selector: 'settings-organization',
  templateUrl: './settings-organization.component.html',
  styleUrls: ['./settings-organization.component.css', '../settings.component.css']
})
export class SettingsOrganizationComponent implements CanComponentDeactivate {
  @ViewChild(SettingsPortalComponent) settingsPortalComponent!: SettingsPortalComponent;
  private nameChangeSubscription?: Subscription = undefined;
  isPortalEnabled: boolean = false;
  organization$: Observable<IOrganization | undefined>;
  nameControl = new FormControl();
  organizationNameIsUpdating: boolean = false;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private organizationService: OrganizationsApi,
    private store: StoreService,
    private portalSettingsService: PortalSettingsService
  ) {
    this.organization$ = this.organizationService.getOne(this.store.organizationId!);
  }

  ngOnInit() {
    this.nameChangeSubscription = merge(
      this.organization$.pipe(
        map((organization) => organization?.name),
        filter((name) => !!name),
        tap((name) => this.store.organizationName = name),
        map(
          (name) =>
            ({
              name: name!,
              source: 'server'
            }) satisfies NameChangeWithSource
        )
      ),
      this.nameControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map(
          (name) =>
            ({
              name: name as string,
              source: 'local'
            }) satisfies NameChangeWithSource
        )
      )
    )
      .pipe(distinctUntilChanged((a, b) => a.name === b.name))
      .subscribe((nameChangeWithSource) => {
        if (nameChangeWithSource.source === 'server') {
          this.nameControl.setValue(nameChangeWithSource.name, {
            emitEvent: false
          });
        } else if (nameChangeWithSource.source === 'local' && !!nameChangeWithSource.name) {
          this.organizationNameIsUpdating = true;
          this.organizationService.updateName(this.store.organizationId!, nameChangeWithSource.name).finally(() => {
            this.organizationNameIsUpdating = false;
          });
        }
      });

    this.portalSettingsService.isPortalEnabled$.subscribe((isPortalEnabled) => {
      this.isPortalEnabled = isPortalEnabled;
    });
  }

  ngOnDestroy(): void {
    this.nameChangeSubscription?.unsubscribe();
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !this.settingsPortalComponent.canSubmit();
  }
}
