import { Component, Input } from '@angular/core';

@Component({
  selector: 'resume-content-about',
  templateUrl: './resume-content-about.component.html',
  styleUrls: ['./resume-content-about.component.css'],
})
export class ResumeContentAboutComponent {
  @Input() about: string | null | undefined;
}
