<div class="resume-content-section column fill-space">

  <div class="resume-content-section-header fill-space">
    <div class="resume-content-section-header-icon center"> <img src="assets/images/section-publications.svg"/> </div>
    <div class="resume-content-section-header-title center"> Publications </div>
  </div>

  <div class="resume-content-section-data column fill-space">
    <empty-list text="No Projects" *ngIf="!publications?.length"></empty-list>
    <ng-container *ngIf="publications">
      <div class="resume-node column" *ngFor="let p of publications">
        <div class="resume-node-header">
          <div class="resume-node-icon"> <img src="assets/images/resume-list-publications.svg"/> </div>
          <div class="resume-node-header-content column">
            <div class="resume-node-title capitalize"> {{ p.title}} </div>
            <div class="capitalize" [class]="{'resume-node-subtitle': p.publishedIn, 'empty-info': !p.publishedIn}">
              {{ p.publishedIn ? p.publishedIn.toLowerCase() : 'No Publisher Info' }}
            </div>
          </div>
        </div>
        <div class="resume-node-content column">
          <div class="resume-node-date">
            <div class="resume-node-date-icon center"> <img src="assets/images/calendar.svg"/> </div>
            <div class="resume-node-date-text gap-1 row-center-left">
              <div class="resume-node-info-data" > Publication Date: {{ p.publicationDate ? p.publicationDate : 'Unknown' }} </div>
            </div>
          </div>
          <div class="publications-info column">
            <div class="resume-node-info capitalize column">
              <div class="resume-node-info-label">  Authors: </div>
              <div class="wrap gap-1">
                <div class="resume-node-info-data author center" *ngFor="let author of p.authors">  {{author}} </div>
              </div>
            </div>
          </div>
          <div class="resume-node-description" *ngIf="p.description">  {{ p.description }}</div>
        </div>
      </div>
    </ng-container>
  </div>

</div>
