import { Component, Input } from '@angular/core';
import { IJob, IJobDetails, IJobLocation } from '../../../../../../../../../../model/IJob';
import { EWorkArrangement } from '../../../../../../../../../../model/enums/EWorkArrangement';
import { EJobStatus } from '../../../../../../../../../../model/enums/EJobStatus';
import { DeepPartial } from '../../../../../../../../../../model/utils';
import { EPayFrequency } from '../../../../../../../../../../model/enums/EPayFrequency';

@Component({
  selector: 'job-portal-view',
  templateUrl: './job-portal-view.component.html',
  styleUrls: ['./job-portal-view.component.css']
})
export class JobPortalViewComponent {
  @Input() jobDetails: DeepPartial<IJobDetails> | undefined;
  @Input() showPostedDate?: boolean = true;
  @Input() showJobStatus?: boolean = true;
  @Input() showArrow?: boolean = true;
  @Input() job!: IJob | undefined;
  protected readonly EWorkArrangement = EWorkArrangement;

  serializeLocation(location: DeepPartial<IJobLocation> | undefined) {
    if (!location) return '';
    const { country, state, city } = location;
    return [city, state, country].filter(Boolean).join(', ');
  }

  protected readonly EJobStatus = EJobStatus;
  protected readonly EPayFrequency = EPayFrequency;
  protected readonly Date = Date;
}
