import { Injectable } from "@angular/core";
import {
  addDoc,
  collection,
  collectionData,
  doc,
  Firestore,
  query,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch,
  runTransaction,
  getDocs,
} from "@angular/fire/firestore";
import { FirebaseConverterService } from "../api/firebase-converter.service";
import { IOrganizationInvitation } from "../../../../model/IOrganizationInvitation";
import { FirebaseCollectionNames } from "../../../../model/FirebaseCollectionNames";
import { propertyOf } from "../../../../model/utils";
import { IUser, IUserInOrganization } from "../../../../model/IUser";
import { EInvitationStatus } from "../../../../model/enums/EInvitationStatus";

@Injectable({
  providedIn: "root",
})
export class OrganizationInvitationsService {
  constructor(
    private firestore: Firestore,
    private invitationConverterService: FirebaseConverterService<IOrganizationInvitation>,
    private userOrganizationConverterService: FirebaseConverterService<IUserInOrganization>,
  ) {}

  create(invitation: InvitationData) {
    // TODO check if this user is admin or owner of the organization
    const batch = writeBatch(this.firestore);

    batch.set(
      doc(collection(this.firestore, FirebaseCollectionNames.INVITATIONS)),
      {
        ...invitation,
        creationTime: serverTimestamp(),
        status: EInvitationStatus.PENDING,
      } as IOrganizationInvitation,
    );

    batch.set(
      doc(
        collection(
          this.firestore,
          FirebaseCollectionNames.ORGANIZATIONS,
          invitation.organizationId,
          FirebaseCollectionNames.USERS,
        ),
      ),
      {
        role: invitation.role,
        emailVerified: false,
        email: invitation.inviteForUserEmail,
        status: EInvitationStatus.PENDING,
      } satisfies Omit<IUserInOrganization, "id" | "name">,
    );

    return batch.commit();
  }

  accept(userAcceptingId: string, invitationId: string) {
    // TODO this should become a Firebase Function for security reasons
    return runTransaction(this.firestore, async (transaction) => {
      const invitationRef = doc(
        this.firestore,
        FirebaseCollectionNames.INVITATIONS,
        invitationId,
      );
      const invitationDoc = await transaction.get(invitationRef);

      if (!invitationDoc.exists()) {
        throw new Error("Invitation does not exist");
      }

      const invitationData = invitationDoc.data()! as IOrganizationInvitation;

      if (invitationData.status !== EInvitationStatus.PENDING) {
        throw new Error("Invitation is not pending");
      }

      // Get ready to update the user
      const userRef = doc(
        this.firestore,
        FirebaseCollectionNames.USERS,
        userAcceptingId,
      );
      const userRefSnapshot = await transaction.get(userRef);
      const userData = userRefSnapshot.data()! as IUser;

      transaction.update(invitationRef, {
        status: EInvitationStatus.ACCEPTED,
        statusChangeTime: serverTimestamp(),
      } as Pick<IOrganizationInvitation, "status" | "statusChangeTime">);

      // This is not part of the transaction. It is a separate query. Transactions can not contain queries.
      const queryData = await getDocs(
        query(
          collection(
            this.firestore,
            FirebaseCollectionNames.ORGANIZATIONS,
            invitationData.organizationId,
            FirebaseCollectionNames.USERS,
          ).withConverter(this.userOrganizationConverterService.converter),
          where(
            propertyOf<IUserInOrganization>("email"),
            "==",
            invitationData.inviteForUserEmail,
          ),
        ),
      );

      // Delete anywhere the user email is found
      queryData.forEach((doc) => {
        transaction.delete(doc.ref);
      });

      // Add the user to the organization
      const userInOrganizationRef = doc(
        this.firestore,
        FirebaseCollectionNames.ORGANIZATIONS,
        invitationData.organizationId,
        FirebaseCollectionNames.USERS,
        userAcceptingId,
      );
      transaction.set(
        userInOrganizationRef,
        {
          role: invitationData.role,
          email: invitationData.inviteForUserEmail,
          status: EInvitationStatus.ACCEPTED,
        } as IUserInOrganization,
        { merge: true },
      );

      // Update the user with the new organization
      const newOrganizations =
        userData.organizations?.length > 1
          ? [...userData.organizations, invitationData.organizationId]
          : [invitationData.organizationId];

      transaction.update(userRef, {
        organizations: newOrganizations,
      } as Pick<IUser, "organizations">);
    });
  }

  getByStatus(forEmail: string, status: EInvitationStatus) {
    return collectionData(
      query(
        collection(
          this.firestore,
          FirebaseCollectionNames.INVITATIONS,
        ).withConverter(this.invitationConverterService.converter),
        where(
          propertyOf<IOrganizationInvitation>("inviteForUserEmail"),
          "==",
          forEmail,
        ),
        where(propertyOf<IOrganizationInvitation>("status"), "==", status),
      ),
    );
  }
}

export interface InvitationData
  extends Omit<
    IOrganizationInvitation,
    "status" | "statusChangeTime" | "creationTime" | "id"
  > {}
