import { Component, Input, OnInit } from "@angular/core";
import { filter, map, Observable, of, Subscription, switchMap } from "rxjs";
import {
  EApplicantGroupType,
  ICategoryDefinition,
} from "../../../../model/IApplicantGroup";
import {
  IMatchAnalysis,
  IApplicant,
} from "../../../../../../../model/IApplicant";
import { UtilService } from "../../../../services/util.service";
import { ActivatedRoute } from "@angular/router";
import { ApplicantsApi } from "../../../../api/applicants.api";
import { StoreService } from "../../../../services/store.service";
import { IJob } from "../../../../../../../model/IJob";
import { JobsApi } from "../../../../api/jobs.api";
import { StorageApi } from "../../../../api/storage.api";
import { FirebaseStorageFolderNames } from "../../../../../../../model/FirebaseCollectionNames";

@Component({
  selector: "job-applicant",
  templateUrl: "./job-applicant.component.html",
  styleUrls: ["./job-applicant.component.css"],
})
export class JobApplicantComponent implements OnInit {
  job$: Observable<IJob | undefined> | undefined;
  applicant$: Observable<IApplicant | undefined> | undefined;
  category$: Observable<ICategoryDefinition | null> | undefined;
  jobMatchAnalysis$: Observable<IMatchAnalysis | null> | undefined;

  private applicantMatchIdParam$: Observable<string>;

  constructor(
    public utilService: UtilService,
    private route: ActivatedRoute,
    private applicantsService: ApplicantsApi,
    public store: StoreService,
    private jobsApi: JobsApi,
    private storageApi: StorageApi,
  ) {
    this.applicantMatchIdParam$ = this.route.params.pipe(
      map((params) => params["applicantId"] as string),
      filter((applicantMatchId) => !!applicantMatchId),
    );
  }



  openFile(path: string) {
    this.storageApi
      .getFileUrl(path)
      .then((fileUrl) => {
        window.open(fileUrl, "_blank");
      })
      .catch((error) => {
        console.log("Error Getting File Url: " + error);
      })
      .finally(() => {});
  }

  ngOnInit() {
    this.job$ = this.route.paramMap.pipe(
      switchMap((params) => {
        let jobId = params.get("jobId");
        return jobId ? this.jobsApi.getOne(jobId) : of(undefined);
      }),
    );

    this.applicant$ = this.applicantMatchIdParam$.pipe(
      switchMap((applicantMatchId) => {
        return this.applicantsService.getOne(applicantMatchId);
      }),
      filter((applicant) => !!applicant),
      map((applicant) => applicant),
    );


    this.category$ = this.applicant$?.pipe(
      filter((applicant) => !!applicant),
      map((applicant) => this.utilService.getCategoryForApplicant(applicant!)),
    );
    this.jobMatchAnalysis$ = this.applicant$?.pipe(
      filter((applicant) => !!applicant && !!applicant!.matchAnalysis),
      map((applicant) => applicant!.matchAnalysis!),
    );
  }
}
