<div id="account-settings" class="column">


  <div id="account-settings-content" class="column space-top-bottom-1" *ngIf="user$ | async as user">
    <div class="form-field column">
      <div class="row">
        <div class="form-field-label">Full Name</div>
        <mat-spinner *ngIf="userNameIsUpdating" style="margin-left: auto" diameter="16"></mat-spinner>
      </div>
      <div class="form-field-input">
        <input class="fill-space" type="text" [formControl]="nameControl" />
      </div>
    </div>
    <div class="form-field column space-top-bottom-1">
      <div class="form-field-label">Email</div>
      <div class="form-field-input">
        <input class="fill-space text-disabled" type="text" readonly [(ngModel)]="user.email"/>
      </div>
    </div>
  </div>
</div>
