import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Observable, of, switchMap} from "rxjs";
import {ApplicantsApi} from "../../../../../../api/applicants.api";
import {MatDialog} from "@angular/material/dialog";
import {UserDeleteComponent} from "../../../../../users/user-delete/user-delete.component";
import {ApplicantDeleteComponent} from "./applicant-delete/applicant-delete.component";
import {IApplicant} from "../../../../../../../../../model/IApplicant";

@Component({
  selector: 'applicant',
  templateUrl: './applicant.component.html',
  styleUrls: ['./applicant.component.css']
})
export class ApplicantComponent implements OnInit {

  applicant$: Observable<IApplicant|undefined> | undefined;

  constructor(private route: ActivatedRoute, private dialog: MatDialog, private applicantsApi: ApplicantsApi) {
  }

  showDeleteApplicant(applicant: IApplicant) {
      let dialogRef = this.dialog.open(ApplicantDeleteComponent, {});
      dialogRef.componentInstance.applicant = applicant;
  }

  ngOnInit() {
      this.applicant$ = this.route.paramMap.pipe(
          switchMap((params) => {
              let applicantId = params.get("applicantId");
              return applicantId ? this.applicantsApi.getOne(applicantId) : of(undefined);
          })
      );

  }
}
