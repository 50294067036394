export function propertyOf<TObj>(name: keyof TObj) {
  return name;
}

// export type DeepPartial<T> = T extends object ? {
//   [P in keyof T]?: DeepPartial<T[P]>;
// } : T;

export type DeepPartial<T> = T extends Function
  ? T
  : T extends Array<infer U>
    ? _DeepPartialArray<U>
    : T extends object
      ? _DeepPartialObject<T>
      : T | undefined;

type _DeepPartialArray<T> = Array<DeepPartial<T>>
type _DeepPartialObject<T> = { [P in keyof T]?: DeepPartial<T[P]> };