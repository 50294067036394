import { Component, Input, OnInit } from '@angular/core';
import { IResumeLink } from '../../../../../../../../model/IResume';

@Component({
  selector: 'resume-content-links',
  templateUrl: './resume-content-links.component.html',
  styleUrls: ['./resume-content-links.component.css'],
})
export class ResumeContentLinksComponent implements OnInit {
  @Input() links: IResumeLink[] | null | undefined = [];

  ngOnInit() {
    if (this.links) {
      this.links = this.links.map((link) => {
        if (link.url && !link.url.startsWith('http://') && !link.url.startsWith('https://')) {
          return { ...link, url: 'https://' + link.url }; // Corrected to modify the `url` property
        }
        return link;
      });
    }
  }
}
