import {Component, Input} from '@angular/core';
import {IJob} from "../../../../../../../../../../../model/IJob";
import {JobsApi} from "../../../../../../../../api/jobs.api";
import {MatDialogRef} from "@angular/material/dialog";
import {IApplicantNote} from "../../../../../../../../../../../model/IApplicant";
import {ApplicantNotesApi} from "../../../../../../../../api/applicant.notes.api";

@Component({
  selector: 'applicant-note-delete',
  templateUrl: './applicant-note-delete.component.html',
  styleUrls: ['./applicant-note-delete.component.css']
})
export class ApplicantNoteDeleteComponent {
  @Input() note: IApplicantNote | undefined;
  @Input() applicantId: string | null | undefined;
  isLoading: boolean = false;

  constructor(
      private applicantNotesApi: ApplicantNotesApi,
      private dialogRef: MatDialogRef<ApplicantNoteDeleteComponent>,
  ) {}

  delete(): void {
    if (!this.note || !this.applicantId) { return; }
      this.isLoading = true;
      this.applicantNotesApi
      .delete(this.applicantId, this.note.id)
      .then((response) => {
        this.dialogRef.close();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
