import { Component, Input } from "@angular/core";
import { IUserInOrganization } from "../../../../../../model/IUser";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UserDeleteComponent } from "../user-delete/user-delete.component";
import { UserFormComponent } from "../user-form/user-form.component";
import { EInvitationStatus } from "../../../../../../model/enums/EInvitationStatus";
import {EUserRole} from "../../../../../../model/enums/EUserRole";

@Component({
  selector: "user-panel",
  templateUrl: "./user-panel.component.html",
  styleUrls: ["./user-panel.component.css"],
})
export class UserPanelComponent {
  @Input() user: IUserInOrganization | undefined;

  constructor(private dialog: MatDialog) {}

  showEditUser(): void {
    let dialogRef = this.dialog.open(UserFormComponent, {});
    dialogRef.componentInstance.user = this.user;
  }

  showDeleteUser(): void {
    let dialogRef = this.dialog.open(UserDeleteComponent, {});
    dialogRef.componentInstance.user = this.user;
  }

  ngOnInit() {
  }

  protected readonly EInvitationStatus = EInvitationStatus;
  protected readonly EUserRole = EUserRole;
}
