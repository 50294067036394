<ng-container *ngIf="matchAnalysis as ma">
<div class="applicant-evaluation-concerns column">

  <div class="match-section-content column">

    <div class="justifications-section-title column column-center-left fill-space"> Potential Concerns </div>

    <div class="concerns-content column">
      <div class="empty center" *ngIf="!ma.concerns.length"> No Concerns. </div>
      <div class="concern" *ngFor="let c of ma.concerns; let index = index;">
        <div class="concern-icon center"> <img src="assets/images/indicator-negative.svg" /> </div>
        <div class="concern-text column center">
          <div class="justification-entry-content-description column column-center-left"> {{ c }} </div>
        </div>
      </div>
    </div>

  </div>

</div>
</ng-container>