import { Component, Input } from '@angular/core';
import { EResumeSectionType } from '../../../../model/enum/EResumeSectionType';
import { IResumeContent } from '../../../../../../../model/IResume';

@Component({
  selector: 'resume-content',
  templateUrl: './resume-content.component.html',
  styleUrls: ['./resume-content.component.css'],
})
export class ResumeContentComponent {
  @Input() content: IResumeContent | undefined;
  showContent: boolean = true;

  protected readonly EResumeSectionType = EResumeSectionType;
}
