<div id="links" class="resume-content-section column fill-space">

    <div class="resume-content-section-header fill-space">
        <div class="resume-content-section-header-icon center"> <img src="assets/images/section-links.svg"/> </div>
        <div class="resume-content-section-header-title center"> Links  </div>
    </div>

    <div class="resume-content-section-data column fill-space">

      <empty-list text="No Links" *ngIf="!links?.length"></empty-list>

      <ng-container *ngIf="links">
        <a target="_blank" *ngFor="let l of links" [href]="l.url">
          <div class="link">
            <div class="link-icon"> <img src="assets/images/resume-list-link.svg"/> </div>
            <div class="link-content column">
              <div class="link-title"> {{ l.description ? l.description : 'Untitled'}}</div>
              <div class="link-url">  {{l.url}} </div>
            </div>
          </div>
        </a>
      </ng-container>

    </div>


</div>
