<div class="rating column" *ngIf="rating" [class]="{'hire-panel': rating.isHire, 'reject-panel': !rating.isHire}">
   <div class="rating-header">
     <div class="rating-header-user fill-space"> Review By: {{ rating.userName }}</div>
     <div class="rating-header-date center" *ngIf="rating.creationTime"> Submitted On: {{ rating.creationTime.toDate() | date: "mediumDate"}}</div>
     <div class="rating-header-delete center" (click)="showDeleteRating()">
       <div class="icon-button center">
         <img src="assets/images/delete.svg"/>
       </div>
     </div>
   </div>
  <div class="rating-points">
    <div class="rating-points-block column fill-space-equal">
      <div class="rating-title"> Pros </div>
      <div class="points-block-list fill-space column">
        <div class="points-block-list-empty center" *ngIf="!rating.pros?.length"> No Pros! </div>
        <div class="points-block-list-item" *ngFor="let item of rating.pros; let i = index;">
          <div class="points-block-list-item-icon center"> <img src="assets/images/plus.svg"/> </div>
          <div class="points-block-list-item-text row-center-left fill-space"> {{ item }} </div>
        </div>
      </div>
    </div>
    <div class="rating-points-block column fill-space-equal">
      <div class="rating-title"> Cons </div>
      <div class="points-block-list fill-space column">
        <div class="points-block-list-empty center" *ngIf="!rating.cons?.length"> No Cons! </div>
        <div class="points-block-list-item" *ngFor="let item of rating.cons; let i = index;">
          <div class="points-block-list-item-icon center"> <img src="assets/images/minus.svg"/> </div>
          <div class="points-block-list-item-text row-center-left fill-space"> {{ item }} </div>
        </div>
      </div>
    </div>
  </div>

  <div>

    <div class="rating-score fill-space-equal column">
      <div class="row-center-left gap-1">
        <div class="rating-title center"> Rating: </div>
        <div class="rating-score-value center"> {{rating.score}} / 10 </div>
      </div>
      <div class="rating-score-stars">
        <div class="rating-score-star center"
             *ngFor="let star of [].constructor(10); let i = index">
          <img [src]=" (i < rating.score) ? 'assets/images/star-green.svg' : 'assets/images/star-gray.svg'"/>
        </div>
      </div>
    </div>

    <div class="decision fill-space-equal column">
      <div class="rating-title row-center-left"> Suggestion: </div>
      <div class="decision-item center" [class]="{'hire': rating.isHire, 'reject': !rating.isHire }">
        <div class="decision-item-icon center">
          <img [src]=" rating.isHire ? 'assets/images/decision-hire.svg' : 'assets/images/decision-reject.svg'"/>
        </div>
        <div class="decision-item-text row-center-left"> {{ rating.isHire ? 'Hire' : 'Reject' }} </div>
      </div>
    </div>
  </div>

  <div class="comments column">
    <div class="rating-title"> Comments </div>
    <div class="comments-text" *ngIf="rating"> {{ rating.comments && rating.comments.trim() != '' ? rating.comments : 'No Comments' }}</div>
  </div>
</div>