import { Component } from '@angular/core';

@Component({
  selector: 'checkouts-cancel',
  templateUrl: './checkouts-cancel.component.html',
  styleUrls: ['./checkouts-cancel.component.css']
})
export class CheckoutsCancelComponent {

}
