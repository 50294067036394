<div id="company-settings" class="column">
  <div class="header row">
    <div class="fill-space column">
      <div class="title">Available Companies</div>
    </div>
    <div class="header-icon" (click)="showCreateCompany()">
      <div class="button button-action">+ Create Company</div>
    </div>
  </div>
  <div class="block overflow-auto">
    <!-- Check if observable has emitted values and is not empty -->
    <ng-container  *ngIf="userWithOrganizationsWithRole$ | async as user; else loading">
      <a *ngFor="let organization of user.resolvedOrganizationsWithRole" [routerLink]="['/organization', organization?.id]">
        <company-display [organizationWithRole]="organization"></company-display>
      </a>
    </ng-container>

    <!-- Loading message -->
    <ng-template #loading>
      <div>Loading companies...</div>
    </ng-template>
  </div>
</div>
