import { Component, Input } from '@angular/core';
import { EResumeSectionType } from '../../model/enum/EResumeInfoSection';
import { EResumeExperienceLevel } from '../../../../../model/enums/EResumeExperienceLevel';
import { EJobTenureLevel } from '../../../../../model/enums/EJobTenureLevel';
import { EResponsibilityLevel } from '../../../../../model/enums/EResponsibilityLevel';
import { EEducationLevel } from '../../../../../model/enums/EEducationLevel';
import { EResumeCareerProgression } from '../../../../../model/enums/EResumeCareerProgression';
import { EResumeCareerFocus } from '../../../../../model/enums/EResumeCareerFocus';

interface ILevelValue {
  title: EResumeExperienceLevel | EJobTenureLevel | EResponsibilityLevel | EEducationLevel | EResumeCareerProgression | EResumeCareerFocus;
  description: string;
}

@Component({
  selector: 'level-info',
  templateUrl: './level-info.component.html',
  styleUrls: ['./level-info.component.css'],
})
export class LevelInfoComponent {
  @Input() type: EResumeSectionType | undefined;

  descriptions: { [key in EResumeSectionType]: string } = {
    [EResumeSectionType.EXPERIENCE]:
      "Work experience levels are about categorizing an individual's professional background based on the duration of their employment and the complexity and leadership roles they have held.",
    [EResumeSectionType.TENURE]:
      'Job tenure is about the length of time an individual has spent in a particular job or with a single employer, indicating their stability and experience in that position.',
    [EResumeSectionType.RESPONSIBILITY]:
      'Job responsibility levels are about the hierarchy of roles within an organization, classified by the complexity, scope, and leadership requirements of the work involved.',
    [EResumeSectionType.EDUCATION]:
      'Education levels are about the highest degree or certification an individual has attained, reflecting their formal training and academic qualifications.',
    [EResumeSectionType.CAREER_PROGRESSION]:
      'Career progression is about the direction and rate at which an individual advances through different roles and responsibilities within their career.',
    [EResumeSectionType.CAREER_FOCUS]:
      "Career focus is about the specificity or breadth of one's professional expertise and the degree to which their experiences are concentrated in a particular area or spread across various domains.",
  };

  levels: { [key in EResumeSectionType]: ILevelValue[] } = {
    [EResumeSectionType.EXPERIENCE]: [
      {
        title: EResumeExperienceLevel.ENTRY_LEVEL,
        description: "Typically recent graduates or individuals with minimal work experience. They're starting their careers and are very trainable.",
      },
      {
        title: EResumeExperienceLevel.JUNIOR,
        description: 'Individuals with 1-3 years of experience, often still requiring some supervision but are developing their professional skills.',
      },
      {
        title: EResumeExperienceLevel.MID_LEVEL,
        description: 'Employees that have 3-7 years of experience and are expected to perform tasks independently and may start to supervise others.',
      },
      {
        title: EResumeExperienceLevel.SENIOR,
        description: 'Employees with 7-12 years of experience. These employees have a track record of leadership and are experts in their field. They often take on strategic roles.',
      },
      {
        title: EResumeExperienceLevel.VETERAN,
        description: 'Professionals with 12+ years of experience, providing mentorship, deep industry insights, and leadership in complex scenarios.',
      },
    ],
    [EResumeSectionType.TENURE]: [
      {
        title: EJobTenureLevel.SHORT_TERM,
        description:
          '[Less than a year] This tenure indicates a very brief period of employment, typical for contract work, temporary assignments, or may reflect a probationary period in a new role.',
      },
      {
        title: EJobTenureLevel.BRIEF,
        description:
          '[1-2 years] Reflects a relatively quick turnover and is often seen in industries with high churn rates or when an individual is exploring different career paths early in their professional life.',
      },
      {
        title: EJobTenureLevel.INTERMEDIATE,
        description:
          '[3-5 years] A span that suggests stability and the opportunity for an individual to have developed and contributed significantly to their role and organization.',
      },
      {
        title: EJobTenureLevel.LONG,
        description:
          '[6-10 years] Demonstrates a solid commitment to a role or organization, where an employee has likely taken on increased responsibilities and potentially moved up within the company hierarchy.',
      },
      {
        title: EJobTenureLevel.EXTENDED,
        description:
          '[More than 10 years] Indicates a substantial career investment in a particular company or field, suggesting deep expertise, significant achievements, and possibly a leadership or expert status within the field.',
      },
    ],
    [EResumeSectionType.RESPONSIBILITY]: [
      {
        title: EResponsibilityLevel.ENTRY_LEVEL,
        description: 'These roles typically require little to no professional experience. Titles may include "Junior," "Assistant," or "Associate."',
      },
      {
        title: EResponsibilityLevel.INTERMEDIATE,
        description:
          ' These positions require a moderate level of experience and skills. They may involve some supervisory duties or oversight of specific projects. Titles often lack any prefix or might include terms like "Specialist" or "Analyst."',
      },
      {
        title: EResponsibilityLevel.SENIOR,
        description:
          'Senior roles require significant experience and advanced skills. They usually involve managing a team or overseeing major projects. Titles often include the prefix "Senior," "Lead," or "Principal."',
      },
      {
        title: EResponsibilityLevel.MANAGERIAL,
        description:
          'These positions involve the direct management of a team or a department. They may also be responsible for strategic planning in their area of expertise. Titles include "Manager," "Team Lead," or "Head of."',
      },
      {
        title: EResponsibilityLevel.EXECUTIVE,
        description: 'This category represents the pinnacle of management within a company, focused on strategic and operational leadership.',
      },
    ],
    [EResumeSectionType.EDUCATION]: [
      {
        title: EEducationLevel.PRE_HIGH_SCHOOL_EDUCATION,
        description: 'Individuals who have not completed high school or an equivalent level of education (No High School Diploma).',
      },
      { title: EEducationLevel.HIGH_SCHOOL_DIPLOMA_GED, description: ' Individuals who have completed high school or an equivalent level of education.' },
      {
        title: EEducationLevel.PROFESSIONAL_CERTIFICATION_TRAINING,
        description:
          'Individuals who have obtained professional skills and certifications outside of traditional academic paths. This could include commercial driving licenses, IT certifications, etc.',
      },
      { title: EEducationLevel.VOCATIONAL_TRADE_SCHOOL, description: 'Individuals who have undergone specific vocational training or education at a trade school.' },
      {
        title: EEducationLevel.ASSOCIATES_DEGREE,
        description:
          'Typically a two-year degree or diploma from a community college or similar institution. This could include Associate of Arts (AA), Associate of Science (AS), or similar.',
      },
      {
        title: EEducationLevel.BACHELORS_DEGREE,
        description: 'A four-year degree from a university or college. This could include Bachelor of Arts (BA), Bachelor of Science (BS), or similar.',
      },
      {
        title: EEducationLevel.POSTGRADUATE_CERTIFICATE_DIPLOMA,
        description: "Post-graduate level qualifications that are usually specialized and less extensive than a master's degree.",
      },
      {
        title: EEducationLevel.MASTERS_DEGREE,
        description:
          "An advanced degree that typically follows a bachelor's degree. This could include Master of Arts (MA), Master of Science (MS), Master of Business Administration (MBA), or similar.",
      },
      {
        title: EEducationLevel.DOCTORATE,
        description: 'The highest level of academic degree. This could also include professional doctorate degrees such as Juris Doctor (JD), Doctor of Medicine (MD), or similar.',
      },
      { title: EEducationLevel.POST_DOCTORATE, description: 'A level of academic research and study that goes beyond a doctoral degree.' },
    ],
    [EResumeSectionType.CAREER_PROGRESSION]: [
      {
        title: EResumeCareerProgression.ASCENDING,
        description: 'Shows a clear upward trajectory in career roles and responsibilities. Promotions and higher-level positions are evident.',
      },
      {
        title: EResumeCareerProgression.DESCENDING,
        description: 'Indicates a downward or reverse trend in roles or responsibilities. Might occur due to external factors like industry changes or personal choices.',
      },
      {
        title: EResumeCareerProgression.STATIC,
        description: 'Reflects little to no change in position or job title over an extended period. Can suggest contentment with current role or a plateau in career growth.',
      },
      {
        title: EResumeCareerProgression.FLUCTUATING,
        description: 'Characterized by a mix of promotions, lateral moves, and possibly some steps back. Can suggest a dynamic career with diverse experiences and adaptability.',
      },
    ],
    [EResumeSectionType.CAREER_FOCUS]: [
      {
        title: EResumeCareerFocus.SPECIALIZED,
        description:
          'Demonstrates deep expertise in a specific niche or technical area. Career path shows a commitment to mastering a particular skill set or knowledge area. Professional development is targeted, with advanced certifications or degrees often pursued.',
      },
      {
        title: EResumeCareerFocus.DIVERSIFIED,
        description:
          'Reflects a broad range of skills and experiences across different roles or industries. Career history shows flexibility and a willingness to cross-train and adapt to various job functions. Emphasizes versatility and the ability to handle multiple types of tasks or projects.',
      },
      {
        title: EResumeCareerFocus.MANAGERIAL,
        description:
          'Indicates progression into roles with increased oversight, people management, or strategic planning responsibilities. Career development has likely included leadership training and roles with a focus on team success and organizational impact. Often involves a shift from hands-on technical work to a broader focus on directing teams and operations.',
      },
    ],
  };

  constructor() {}
}
