// https://angular.dev/tools/cli/environments#

export const environment = {
  typesense: {
    api: 'https://f7ojwqcx3m8bk2plp-1.a1.typesense.net:443',
    searchKey: 'vz9sXYy08v9HgRRGGkNUt2zKFBHVXTIf',
    collections: {
      resumes: 'resumes-staging',
      jobs: 'jobs-staging'
    }
  },
  appUrl: 'https://staging.hirefox.ai',
  register: 'https://staging.hirefox.ai/register',
  emailVerification: 'https://staging.hirefox.ai/email-verification',
  passwordReset: 'https://staging.hirefox.ai/reset-password',
  firebase: {
    projectId: 'hirefox-staging',
    appId: '1:20815294380:web:91df3f39a65455b9a19409',
    storageBucket: 'hirefox-staging.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyCSs11BAmWDjD6ybIHQsJ55YaFNwZNDBXs',
    authDomain: 'hirefox-staging.firebaseapp.com',
    messagingSenderId: '20815294380'
  },
  localEmulators: false,
  sentry: {
    environment: 'staging',
    enabled: false, // enable or disable sentry
    dsn: 'https://39e3d95f13543bb62cc510aad95aaddf@o1180461.ingest.us.sentry.io/4507056522330112', // do not change this
    tracesSampleRate: 0.05, // Set tracesSampleRate to 0.05 to capture 5% of transactions
    tracePropagationTargets: [
      // "localhost",
      /^https:\/\/staging\.hirefox\.ai/
    ], // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    replaysSessionSampleRate: 1.0, // Capture Replay for 100% of all sessions
    replaysOnErrorSampleRate: 1.0 // Capture Replay for 100% of sessions with an error
  },
  stripe: {
    allInOneProductId: 'prod_RLP7qU1KBN5mM6',
    creditsProductPriceId: 'price_1QSiJYDkPgL45Xb7fda2qfU3'
  },
  hirefoxEmail: 'info@hirefox.ai',
  portalUrl: 'https://portal-hirefox-staging.web.app'
};
