<div class="loadingio-spinner-dual-ball-tx4zurbv7b" *ngIf="loaderType && loaderType === 'login'">
  <div class="ldio-ptaj8qjy9ac">
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>

<div class="circle-fill-loader" *ngIf="loaderType && loaderType === 'circle-fill'"></div>

<div *ngIf="loaderType && loaderType === 'badge'"> <img src="assets/images/logo-vertical.svg"/> </div>

<div class="loader-wrapper" *ngIf="!loaderType">
  <div class="inline-block">
    <div class="loader-small"></div>
  </div>
</div>
