<div id="email-verification">
  <div id="email-verification-content" class="column">
    <!-- <div id="email-verification-logo" class="fill-space center"> <img src="assets/images/logo-vertical.svg"/> </div> -->

    <div id="email-verification-icon" class="center"> <img src="assets/images/verify-email.svg"/> </div>
    <div id="email-verification-title" class="center"> Email Verification </div>
    <div id="email-verification-status" class="center" > Pending </div>
    <div id="email-verification-text">
      An email with a verification link has been sent to <!-- <span class="flavor"> {{ (user$ | async)?.email }} </span>. -->
      Access your email and click on the link to get verified.
    </div>

    <div id="email-verification-resend" class="column center">
      <div id="email-verification-label"> Didn't receive the verification email ? </div>
      <div id="email-verification-link" *ngIf="!isLoading && !isWaiting" (click)="sendVerificationEmail()"> <div class="button button-action"> Resend Verification Email </div> </div>
      <div id="email-verification-wait" class="column center" *ngIf="!isLoading && isWaiting">
        <div id="email-verification-wait-sent"> Email Sent. </div>
        <div id="email-verification-wait-timer"> Wait for {{timeLeft}} seconds before resending. </div>
      </div>
    </div>


  </div>
  <loader *ngIf="isLoading"></loader>
</div>
