<a class="row center-left flex" [routerLink]="'subscription'">
  <div id="credits" class="banner-button row row-center-left">
    <div id="credits-icon" class="banner-button-icon">
      <img src="assets/images/credits.svg" />
    </div>
    <div class="banner-button-content column column-center-left">
      <div id="credits-content" class="column">
        <div id="credits-label" class="">Credits</div>
        <ng-container *ngIf="this.creditsInitialized$ | async; else notInitialised">
          <div id="credits-value" *ngIf="availableCredits !== null && totalCredits !== null" class="">
            {{ availableCredits }} / {{ totalCredits }}
          </div>
        </ng-container>
        <ng-template #notInitialised>
          <mat-spinner class="center" diameter="15"></mat-spinner>
        </ng-template>
      </div>
    </div>
  </div>
</a>
