import {Component, OnInit} from '@angular/core';
import {ProblemReportsApi} from "../../api/problem.reports.api";
import {IProblemReport} from "../../../../../model/IProblemReport";
import {StoreService} from "../../services/store.service";
import {UserService} from "../../services/user.service";
import {IUser} from "../../../../../model/IUser";

@Component({
  selector: 'problem-report',
  templateUrl: './problem-report.component.html',
  styleUrls: ['./problem-report.component.css']
})
export class ProblemReportComponent implements OnInit {

  isLoading: boolean = false;
  isCreated: boolean = false;
  problemReport: Omit<IProblemReport, 'id'> = {
    description: '',
    userId: this.store.userId!,
    userName: undefined,
    userEmail: undefined,
    organizationId: this.store.organizationId!,
    creationTime: this.problemReportsApi.getServerTimestamp(),
    canContact: true
  };

  constructor(private problemReportsApi: ProblemReportsApi, private store: StoreService, public userService: UserService) {

  }

  createProblemReport(user: IUser){
    if(this.problemReport.description.trim() === '') { return; }
    this.problemReport.userName = user.name;
    this.problemReport.userEmail = user.email;
    this.isLoading = true;
    this.problemReportsApi.create(this.problemReport).then( response => {
      this.isCreated = true;
    }).catch( error => {

    }).finally( () => {
      this.isLoading = false;
    })
  }

  ngOnInit() {

  }


}
