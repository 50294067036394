import { Component, Input } from "@angular/core";
import { IJob } from "../../../../../../model/IJob";
import { MatDialog } from "@angular/material/dialog";
import { JobDeleteComponent } from "../job-delete/job-delete.component";
import { EStatus } from "../../../../../../model/enums/EResumeStatuses";

@Component({
  selector: "job-panel",
  templateUrl: "./job-panel.component.html",
  styleUrls: ["./job-panel.component.css"],
})
export class JobPanelComponent {
  @Input() job: IJob | undefined;

  constructor(private dialog: MatDialog) {}

  showDeleteJob() {
    let dialog = this.dialog.open(JobDeleteComponent, {});
    dialog.componentInstance.job = this.job;
  }

  protected readonly EStatus = EStatus;
}
