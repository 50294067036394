<div id="login">
  <div id="login-content" class="column" *ngIf="!isLoading">
    <div id="login-logo" class="fill-space center"><img src="assets/images/logo-vertical.svg" /></div>
    <div id="login-title" class="fill-space center">Sign In</div>
    <form>
      <div id="credentials" class="column fill-space">
        <div class="form-field column fill-space space-bottom-1">
          <div class="form-field-label">Email</div>
          <div class="form-field-input fill-space">
            <input
              name="username"
              class="fill-space"
              type="text"
              autocomplete="username"
              [(ngModel)]="credentials.email"
              (keydown.enter)="signInWithEmail()" />
          </div>
        </div>
        <div class="form-field column fill-space">
          <div class="form-field-label">Password</div>
          <div class="form-field-input fill-space">
            <input
              name="password"
              class="fill-space"
              type="password"
              autocomplete="current-password"
              [(ngModel)]="credentials.password"
              (keydown.enter)="signInWithEmail()" />
          </div>
        </div>
      </div>
    </form>
    <div id="login-error" class="form-error" *ngIf="error">{{ error }}</div>

    <div id="login-button" class="fill-space" (click)="signInWithEmail()">Sign In</div>

    <div class="register-link space-top-2 fill-space" routerLink="/register">
      Don't Have An Account ? &nbsp; <span class="semibold"> Sign Up Here </span>
    </div>
    <div class="register-link space-top-1 fill-space" routerLink="/forgot-password">
      Forgot Your Password ? &nbsp; <span class="semibold"> Request Password Reset </span>
    </div>

    <!-- TODO: Need to check the registration process when a user signs in for the first time using Google
    <div class="social-divider"></div>

    <div class="social-button fill-space" (click)="signInWithGoogle()">
      <div class="social-button-icon"> <img src="/assets/images/google.svg" /> </div>
      <div class="social-button-text"> Sign In With Google </div>
    </div>
    -->
  </div>
  <div class="fill-space column center" *ngIf="isLoading">
    <loader loaderType="badge" *ngIf="isLoading"></loader>
    <h4>Checking credentials...</h4>
  </div>
</div>
