import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { IOrganization } from "../../../../../model/IOrganization";
import { OrganizationsApi } from "../../api/organizations.api";
import { UserWithOrganizationsService } from "../../services/user-with-organizations.service";
import { IUserWithRoles } from "../../../../../model/IUser";

@Component({
  selector: "organizations",
  templateUrl: "./organizations.component.html",
  styleUrls: ["./organizations.component.css"],
})
export class OrganizationsComponent implements OnInit {
  isLoading: boolean = false;
  user$: Observable<IUserWithRoles> | undefined;

  constructor(
    private userWithOrganizationsService: UserWithOrganizationsService,
  ) {}

  ngOnInit() {
    this.user$ =
      this.userWithOrganizationsService.userWithResolvedOrganizationsWithRole$;
  }
}
