import { Component, Input } from '@angular/core';
import { IResumeLicenseAndCertification } from '../../../../../../../../model/IResume';

@Component({
  selector: 'resume-content-licenses-and-certifications',
  templateUrl: './resume-content-licenses-and-certifications.component.html',
  styleUrls: ['./resume-content-licenses-and-certifications.component.css'],
})
export class ResumeContentLicensesAndCertificationsComponent {
  @Input() licensesAndCertifications: IResumeLicenseAndCertification[] | null | undefined = [];
}
