<div class="popup column">

  <div class="popup-header column">
    <div class="row">
      <div class="popup-header-title fill-space"> AI Settings </div>
      <div class="popup-header-close" mat-dialog-close>
        <img src="assets/images/close.svg" />
      </div>
    </div>
  </div>

  <div class="popup-loader row center" *ngIf="isLoading">
    <loader></loader>
  </div>

  <div mat-dialog-close class="popup-message column center cursor" *ngIf="!isLoading && isSaved">
    <div class="popup-message-icon"> <img src="assets/images/tick-green.svg"/> </div>
    <div class="popup-message-title"> Changes Saved </div>
    <div class="popup-message-text">
      The new rules will apply to all resumes uploaded from this point forward. <br/>
      To apply these rules to resumes already submitted for this job, please upload them again.
    </div>
    <div class="popup-message-button space-top-1"> Ok </div>
  </div>

  <ng-container *ngIf="!isLoading && !isSaved">

    <div class="popup-content overflow-hidden column guidelines scrollbar">
      <div class="gap-2 space-right-1">
        <div class="guidelines-section column fill-space-equal">
          <div class="guidelines-header column">
            <div class="guidelines-section-title"> Scoring Guidelines </div>
            <div class="guidelines-section-subtitle">Define your own rules that the AI must take into consideration when evaluating candidates.</div>
          </div>
          <div class="guidelines-section-content column">
            <div class="form-field fill-space">
              <textarea class="fill-space" rows="10" [(ngModel)]="job!.userAiGuidelines!.scoringInstructions"></textarea>
            </div>
          </div>
        </div>
        <div class="guidelines-section column fill-space-equal">
          <div class="guidelines-header column">
            <div class="guidelines-section-title"> Rejection reasons </div>
            <div class="guidelines-section-subtitle">Establish criteria for automatic disqualification of candidates.</div>
          </div>
          <div class="guidelines-section-content column">
            <div class="form-field fill-space">
              <textarea class="fill-space" rows="10" [(ngModel)]="job!.userAiGuidelines!.rejectReasons"></textarea>
            </div>
          </div>
        </div>
      </div>
      <!--
      <div class="guidelines-section column">
        <div class="guidelines-header column">
          <div class="guidelines-section-title"> Acceptable Distance From Job Location  </div>
          <div class="guidelines-section-subtitle"> Define the maximum acceptable distance between a candidates address and the job location. If the distance between the candidates address and the job location is more that the defined value, the system will give you a warning.</div>
        </div>
        <div class="guidelines-section-content row">
          <div class="form-field fill-space">
            <div class="form-field-label fill-space"> Distance </div>
            <div class="form-field-input fill-space"> <input type="text" /> </div>
          </div>
          <div class="form-field fill-space">
            <div class="form-field-label fill-space"> Unit </div>
            <div class="form-field-input fill-space">
              <div class="radio"> Km </div>
              <div class="radio"> Miles </div>
            </div>
          </div>
        </div>
      </div>-->

      <div class="guidelines-section column space-right-1">
        <div class="guidelines-header column">
          <div class="guidelines-section-title"> Requirements Importance </div>
          <div class="guidelines-section-subtitle">
            Define the importance of each job requirement. The AI will take your guidelines into consideration when evaluating candidates so that it can give
          a  better score to candidates who fulfil the jobs most important requirements. </div>
        </div>
        <div class="guidelines-section-content requirements-section column gap-1">
          <div class="requirements-header-row">
            <div class="requirements-header fill-space"> Job Requirement </div>
            <div class="requirements-header"> Importance Level </div>
          </div>
           <div class="requirement gap-1" *ngFor="let jr of job?.jobAnalysis?.requirements">
              <div class="requirement-text fill-space row-center-left">  {{jr.description}}</div>
              <div class="requirement-selector nowrap">
                <div class="requirement-selector-option center"
                     [class]="{ 'selected': jr.importanceScore === index+1 }"
                     *ngFor="let o of importanceLevels; let index = index"
                     (click)="jr.importanceScore = index+1">
                  {{o}}
                </div>
              </div>
           </div>
        </div>
      </div>

    </div>

    <div class="popup-buttons row">
      <div class="button button-gray" mat-dialog-close> Cancel </div>
      <div class="fill-space"></div>
      <div class="button button-action" (click)="save()"> Save Changes </div>
    </div>
  </ng-container>
</div>


