<div class="job-panel" *ngIf="job">
  <div class="job-panel-icon center" [routerLink]="[job.id]">
    <img src="assets/images/suitcase.svg" />
  </div>
  <div class="job-panel-content column fill-space" [routerLink]="[job.id]">
    <div class="job-panel-title">{{ job.title }}</div>
    <div class="job-panel-description">{{ job.description }}</div>
    <div>
      <div class="job-panel-date center" *ngIf="job.creationTime"> <div> Created On: {{ job.creationTime.toDate() | date: "mediumDate" }} </div> </div>
      <div class="job-panel-resumes-dot center"> <img src="assets/images/dot-gray.svg"/> </div>
      <div class="job-panel-status">
        <div class="job-panel-status-label center"> Status: </div>
        <div class="job-panel-status-value center" [class]="job.jobStatus"> {{ job.jobStatus }} </div>
      </div>
    </div>
  </div>
  <div class="processing-status" *ngIf="job.status != EStatus.COMPLETED">
    <div class="processing-status-loader center" *ngIf="job.status != EStatus.ERROR"> <loader></loader> </div>
    <div class="processing-status-text center" [class]="{'error': job.status === EStatus.ERROR}"> {{job.status}} </div>
  </div>
  <div class="center" [routerLink]="[job.id]">
    <div class="job-panel-applicants">
      <div class="job-panel-applicants-number center"> {{ job.applicantsCount }} </div>
      <div class="job-panel-applicants-label center"> Applicants</div>
    </div>
  </div>
  <div class="center space-right-2" (click)="showDeleteJob()">
    <div class="icon-button center">
      <img src="assets/images/delete.svg"/>
    </div>
  </div>
  <div class="job-panel-arrow center" [routerLink]="[job.id]"><img src="assets/images/arrow-right.svg" /></div>
</div>
