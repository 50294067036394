<div class="column">
  <form name="myForm">
    <ng-container *ngFor="let role of rolesToDisplay">
      <label class="column">
        <input
          name="role"
          class="fill-space"
          type="radio"
          [checked]="role === initialValue"
          [value]="role"
          (change)="userSelectsRole(role)"
        />{{ role }}</label
      ><br />
    </ng-container>
  </form>
</div>
