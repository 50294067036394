import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IJob} from "../../../../../../../../../../../model/IJob";
import {IMatchAnalysis, ISkillsMatching} from "../../../../../../../../../../../model/IApplicant";

@Component({
  selector: 'applicant-evaluation-requirements',
  templateUrl: './applicant-evaluation-requirements.component.html',
  styleUrls: ['./applicant-evaluation-requirements.component.css']
})
export class ApplicantEvaluationRequirementsComponent implements OnInit, OnChanges{

  @Input() job: IJob | undefined;
  @Input() matchAnalysis: IMatchAnalysis | undefined;
  matched: number = 0;
  unMatched: number = 0;

  updateMatchCounts() {
    this.matched = 0;
    this.unMatched = 0;
    if(!this.matchAnalysis?.requirementsJustifications) { return; }
    for (let justification of this.matchAnalysis?.requirementsJustifications) {
      justification.isMatched ? this.matched++ : this.unMatched++;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
      this.updateMatchCounts();
  }

  ngOnInit() {
    this.updateMatchCounts();
  }

}
