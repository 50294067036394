import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilService } from '../../../../../services/util.service';
import { IApplicant, IMatchAnalysis } from '../../../../../../../../model/IApplicant';
import { IJob } from '../../../../../../../../model/IJob';

@Component({
  selector: 'job-applicant-match',
  templateUrl: './job-applicant-match.component.html',
  styleUrls: ['./job-applicant-match.component.css'],
})
export class JobApplicantMatchComponent implements OnInit {
  @Input() job: IJob | undefined;
  @Input() matchAnalysis: IMatchAnalysis | undefined;

  constructor(public utilService: UtilService) {}

  ngOnInit() {}
}
