<div id="job-description" class="column overflow-hidden scrollbar" *ngIf="job$ | async as job">
    <div class="row">
        <div class="section-header fill-space">Job Description [ AI Formatted ] </div>
    </div>



    <div class="job-analysis column fill-space-equal" *ngIf="job?.jobAnalysis as analysis">

        <job-description-requirements
                title="Job Requirements"
                [requirements]="analysis.requirements">
        </job-description-requirements>

        <div class="analysis-section column" *ngIf="analysis.skills as skills">
            <div class="analysis-section-title"> List Of Skills </div>
            <div class="analysis-section-content wrap">
                <div class="skill" *ngFor="let skill of skills"> {{ skill }}</div>
            </div>
        </div>

      <div class="analysis-section column">
        <div class="analysis-section-title"> Job Location </div>
        <div class="analysis-section-content wrap">
          <div class="row gap-05">
            <div class="fill-space"> {{ serializeLocation(analysis.location) ?? "No Location Defined"}}</div>
            <div> {{ analysis.workLocationType | titlecase }} </div>
          </div>
        </div>
      </div>

    </div>

    <div id="job-description-original" class="analysis-section column">
        <div class="analysis-section-title"> Original Job Description Text </div>
        <div class="analysis-section-content wrap">
            <div class="original-description">
                <div class="original-description-content" [innerHTML] = "job?.description | newlineToBr"></div>
            </div>
        </div>
    </div>

</div>
