import { Component, Input } from '@angular/core';
import { EResumeSectionType } from '../../../../../model/enum/EResumeInfoSection';
import { MatDialog } from '@angular/material/dialog';
import { LevelInfoComponent } from '../../../../../common/level-info/level-info.component';
import { IResumeOverviewExperience } from '../../../../../../../../model/IResume';
import { EResumeExperienceLevel } from '../../../../../../../../model/enums/EResumeExperienceLevel';
import { EJobTenureLevel } from '../../../../../../../../model/enums/EJobTenureLevel';
import { EResponsibilityLevel } from '../../../../../../../../model/enums/EResponsibilityLevel';
import {UtilService} from "../../../../../services/util.service";

@Component({
  selector: 'resume-overview-experience',
  templateUrl: './resume-overview-experience.component.html',
  styleUrls: ['../resume-overview.component.css','./resume-overview-experience.component.css'],
})
export class ResumeOverviewExperienceComponent {
  @Input() experience: IResumeOverviewExperience | undefined;

  constructor(private dialog: MatDialog, public utilService: UtilService) {}

  showInfo(type: EResumeSectionType): void {
    let dialog = this.dialog.open(LevelInfoComponent, {});
    dialog.componentInstance.type = type;
  }

  protected readonly EResumeSectionType = EResumeSectionType;
  protected readonly EResumeExperienceLevel = EResumeExperienceLevel;
  protected readonly EJobTenureLevel = EJobTenureLevel;
  protected readonly EResponsibilityLevel = EResponsibilityLevel;
}
