import {Component, Input} from '@angular/core';
import {IMatchAnalysis} from "../../../../../../../../../../../model/IApplicant";

@Component({
  selector: 'applicant-evaluation-concerns',
  templateUrl: './applicant-evaluation-concerns.component.html',
  styleUrls: ['./applicant-evaluation-concerns.component.css']
})
export class ApplicantEvaluationConcernsComponent {

  @Input() matchAnalysis: IMatchAnalysis | undefined;

}
