<div class="column gap-3 organization-info-container" *ngIf="formValues$ | async as formValues">
  <div class="organization-logo row-center-left gap-1 wrap">
    <img [src]="(logoUrl ?? formValues?.file?.url) || 'assets/images/organization.svg'" alt="" />
    <h3 *ngIf="organization$ | async as organization">{{ organization.name | titlecase }}</h3>
  </div>
  <div class="column gap-2">
    <div class="organization-info column">
      <h4>Company Information</h4>
      <span>{{ formValues.companyInfo || '[Company Description]' }}</span>
    </div>
    <div class="organization-contact column">
      <h4>Contact Details</h4>
      <div class="column column gap-1">
        <div class="column-gap-05 row-center-left wrap">
          <div class="center gap-05">
            <img src="assets/images/email-dark-gray.svg" />
            <span>Email:</span>
          </div>
          <a href="mailto:{{ formValues.email }}">{{ formValues.email || '[Email]' }}</a>
        </div>
        <div class="column-gap-05 row-center-left wrap">
          <div class="center gap-05">
            <img src="assets/images/phone-dark-gray.svg" />
            <span>Phone:</span>
          </div>
          <a href="tel:{{ formValues.phone }}">{{ formValues.phone || '[Phone]' }}</a>
        </div>
        <div class="column-gap-05 row-center-left wrap">
          <div class="center gap-05">
            <img src="assets/images/web-dark-gray.svg" />
            <span>Website:</span>
          </div>
          <a [href]="formValues.website" target="_blank">{{ formValues.website || '[Website]' }}</a>
        </div>
      </div>
    </div>
    <div class="cv-drop-container column" *ngIf="formValues.cvDropOff">
      <h4>CV Drop Off</h4>
      <span>Submit your CV to be considered for future opportunities.</span>
      <div class="button apply-button">Drop Your CV</div>
    </div>
  </div>
</div>
