import {Component, Input} from '@angular/core';
import {IApplicantRating} from "../../../../../../../../../../../model/IApplicant";
import {ApplicantNoteDeleteComponent} from "../../applicant-notes/applicant-note-delete/applicant-note-delete.component";
import {MatDialog} from "@angular/material/dialog";
import {ApplicantRatingDeleteComponent} from "../applicant-rating-delete/applicant-rating-delete.component";

@Component({
  selector: 'applicant-rating-panel',
  templateUrl: './applicant-rating-panel.component.html',
  styleUrls: ['./applicant-rating-panel.component.css']
})
export class ApplicantRatingPanelComponent {

  @Input() rating: IApplicantRating | undefined;

  constructor(private dialog: MatDialog) {
  }

  showDeleteRating() {
    let dialog = this.dialog.open(ApplicantRatingDeleteComponent, {});
    dialog.componentInstance.rating = this.rating;
    dialog.componentInstance.applicantId = this.rating?.applicantId;
  }

}
