import { CanDeactivateFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { inject } from '@angular/core';
import { UnsavedChangesDialogComponent } from '../common/unsaved-changes-dialog/unsaved-changes-dialog.component';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

export const unsavedChangesGuard: CanDeactivateFn<CanComponentDeactivate> = (component) => {
  const dialog = inject(MatDialog);

  if (component.canDeactivate()) {
    // Allow navigation if the component has no unsaved changes
    return of(true);
  } else {
    // Show the Material dialog
    const dialogRef = dialog.open(UnsavedChangesDialogComponent, {
      width: '400px'
    });

    // Return an observable that resolves when the dialog is closed
    return dialogRef.afterClosed();
  }
};
