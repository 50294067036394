import { Component, Input } from '@angular/core';
import { IResumeProject } from '../../../../../../../../model/IResume';

@Component({
  selector: 'resume-content-projects',
  templateUrl: './resume-content-projects.component.html',
  styleUrls: ['./resume-content-projects.component.css'],
})
export class ResumeContentProjectsComponent {
  @Input() projects: IResumeProject[] | null | undefined = [];
}
