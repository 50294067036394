<div class="match-section column">
    <job-match-section
            title="Licences & Certifications"
            [score]="score"
            [scoreJustification]="scoreJustification"
            [justifications]="justifications"
            [matched]="matched"
            [unMatched]="unMatched">
    </job-match-section>
</div>
