import { Component } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { distinctUntilChanged, map, skip, Subscription, tap } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  authState$ = authState(this.auth);
  authStateSubscription: Subscription;

  constructor(
    private auth: Auth,
    private router: Router
  ) {
    this.authStateSubscription = this.authState$
      .pipe(
        tap((user) => console.log('AppComponent::authStateSubscription user', user)),
        map((user) => !!user),
        skip(1), // Only when this flips, we want to navigate
        distinctUntilChanged()
      )
      .subscribe((userIsLoggedIn) => {
        void this.router.navigate(['/']);
      });
  }

  ngOnDestroy(): void {
    this.authStateSubscription.unsubscribe();
  }
}
