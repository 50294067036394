import { Component, Input } from "@angular/core";
import { IOrganizationInvitation } from "../../../../../../model/IOrganizationInvitation";
import { Auth, user } from "@angular/fire/auth";
import { OrganizationInvitationsService } from "../../../services/organization-invitations.service";

@Component({
  selector: "invitation",
  templateUrl: "./invitation.component.html",
  styleUrls: ["./invitation.component.css"],
})
export class InvitationComponent {
  @Input() invitation: IOrganizationInvitation | undefined;
  protected readonly user = user;
  isLoading: boolean = false;

  constructor(
    private organizationInvitationsService: OrganizationInvitationsService,
    private auth: Auth,
  ) {}

  acceptInvitation() {
    this.isLoading = true;
    this.organizationInvitationsService
      .accept(this.auth.currentUser?.uid!, this.invitation!.id)
      .then(() => {
        console.log(
          "InvitationsComponent::acceptInvitation()",
          "Invitation accepted",
          this.invitation!.id,
        );
      })
      .catch((error) => {
        this.isLoading = false;
        console.error("InvitationsComponent::acceptInvitation()", error);
      });
  }

  declineInvitation() {}
}
