import { Component, Input } from '@angular/core';
import { IResumePresentationAndConference } from '../../../../../../../../model/IResume';

@Component({
  selector: 'resume-content-presentations-and-conferences',
  templateUrl: './resume-content-presentations-and-conferences.component.html',
  styleUrls: ['./resume-content-presentations-and-conferences.component.css'],
})
export class ResumeContentPresentationsAndConferencesComponent {
  @Input() presentationsAndConferences: IResumePresentationAndConference[] | null | undefined = [];
}
