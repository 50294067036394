<div class="popup column">
  <div class="popup-header row">
    <div class="popup-header-title fill-space">Create New Job</div>
    <div class="popup-header-close" mat-dialog-close>
      <img src="assets/images/close.svg" />
    </div>
  </div>

  <div class="popup-loader column center" *ngIf="isLoading">
    <div class="row center"> <loader></loader> </div>
    <div class="message-title"> Analyzing Your Job. Please wait... </div>
    <div class="message-description">
      Our AI analyzes and understands your job description. This process typically takes around 20 seconds.
    </div>
  </div>

  <div class="job-success column center" *ngIf="!isLoading && error">
    <div class="message-title"> An Error Has Occurred. </div>
    <div class="message-description">
      We are sorry for this. Try re-creating this job. If the error persists please contact us at support@hirefox.ai
    </div>
  </div>

  <div class="job-success column center" *ngIf="isJobCreated && !error">
    <div class="job-success-icon"> <img src="assets/images/job-success.svg" /> </div>
    <div class="message-title"> Job Created Successfully! </div>
    <div class="message-description"> Your job was successfully stored and analyzed by our AI. </div>
    <div class="next-steps column" *ngIf="jobId">
      <div class="next-steps-title"> Next steps: </div>
      <div class="next-steps-list">
        <ul>
          <li> Customize AI to reflect your hiring preferences.  </li>
          <li> Add CVs to find the best-matched applicants for your job. </li>
        </ul>
      </div>
      <div class="next-steps-button center cursor" (click)="goToJob(jobId)"> Go to job </div>
    </div>
  </div>

  <div id="job-create" class="popup-content column" *ngIf="!isLoading && !isJobCreated">
    <div class="form-field column space-bottom-1">
      <div class="form-field-label">Job Title</div>
      <div class="form-field-input">
        <input class="fill-space" type="text" [(ngModel)]="job.title" />
      </div>
    </div>
    <div class="form-field column space-bottom-1">
      <div class="form-field-label">Job Description</div>
      <div class="form-field-input">
        <textarea rows="20" class="fill-space" type="text" [(ngModel)]="job.description"></textarea>
      </div>
    </div>
  </div>

  <div class="popup-buttons row" *ngIf="!isLoading && !isJobCreated">
    <div class="button button-gray">Cancel</div>
    <div class="fill-space"></div>
    <div class="button button-action" (click)="createJob()">Create Job</div>

  </div>
</div>
