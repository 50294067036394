import {Component, OnInit} from '@angular/core';
import {filter, map, Observable, of, switchMap} from "rxjs";
import {IJob} from "../../../../../../../../../../model/IJob";
import {IApplicant, IMatchAnalysis} from "../../../../../../../../../../model/IApplicant";
import {ICategoryDefinition} from "../../../../../../../model/IApplicantGroup";
import {UtilService} from "../../../../../../../services/util.service";
import {ActivatedRoute} from "@angular/router";
import {ApplicantsApi} from "../../../../../../../api/applicants.api";
import {StoreService} from "../../../../../../../services/store.service";
import {JobsApi} from "../../../../../../../api/jobs.api";
import {StorageApi} from "../../../../../../../api/storage.api";

@Component({
  selector: 'applicant-evaluation',
  templateUrl: './applicant-evaluation.component.html',
  styleUrls: ['./applicant-evaluation.component.css']
})
export class ApplicantEvaluationComponent implements  OnInit {

  job$: Observable<IJob | undefined> | undefined;
  applicant$: Observable<IApplicant | undefined> | undefined;
  category$: Observable<ICategoryDefinition | null> | undefined;
  jobMatchAnalysis$: Observable<IMatchAnalysis | null> | undefined;

  private applicantMatchIdParam$: Observable<string>;

  constructor(
      public utilService: UtilService,
      private route: ActivatedRoute,
      private applicantsService: ApplicantsApi,
      public store: StoreService,
      private jobsApi: JobsApi,
      private storageApi: StorageApi,
  ) {
    this.applicantMatchIdParam$ = this.route.params.pipe(
        map((params) => params["applicantId"] as string),
        filter((applicantMatchId) => !!applicantMatchId),
    );
  }



  ngOnInit() {
    this.job$ = this.route.paramMap.pipe(
        switchMap((params) => {
          let jobId = params.get("jobId");
          return jobId ? this.jobsApi.getOne(jobId) : of(undefined);
        }),
    );

    this.applicant$ = this.applicantMatchIdParam$.pipe(
        switchMap((applicantMatchId) => {
          return this.applicantsService.getOne(applicantMatchId);
        }),
        filter((applicant) => !!applicant),
        map((applicant) => applicant),
    );


    this.category$ = this.applicant$?.pipe(
        filter((applicant) => !!applicant),
        map((applicant) => this.utilService.getCategoryForApplicant(applicant!)),
    );
    this.jobMatchAnalysis$ = this.applicant$?.pipe(
        filter((applicant) => !!applicant && !!applicant!.matchAnalysis),
        map((applicant) => applicant!.matchAnalysis!),
    );
  }
}
