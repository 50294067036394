<div class="editor">
<ng-container *ngIf="editorFormControl; else noFromControl">
    <angular-editor [config]="editorConfig" [formControl]="editorFormControl"></angular-editor>
  </ng-container>

  <ng-template #noFromControl>
    <angular-editor [config]="editorConfig" [formControl]="localFormControl"></angular-editor>
  </ng-template>
</div>

