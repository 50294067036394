import { Injectable } from "@angular/core";
import {
  addDoc, collection, collectionData, doc, docData, Firestore, getDocs, orderBy, query, setDoc, where, writeBatch
} from "@angular/fire/firestore";
import { FirebaseCollectionNames } from "../../../../model/FirebaseCollectionNames";
import { IJob } from "../../../../model/IJob";
import { StoreService } from "../services/store.service";
import { Api } from "./api";
import {FirestoreFilter} from "../model/firestore.filter";
import {FirestoreSorting} from "../model/firestore.sorting";
import {QueryConstraint} from "@firebase/firestore";
import {IResume} from "../../../../model/IResume";
import {IUser} from "../../../../model/IUser";

@Injectable({
  providedIn: "root",
})
export class JobsApi extends Api<IJob> {
  constructor(
    private firestore: Firestore,
    private store: StoreService,
  ) {
    super();
  }

  getOne(id: string) {
    const reference = doc(this.firestore, this.getPath(), id);
    return docData<IJob>(reference as any, this.options);
  }

  getAll(filters: FirestoreFilter[] = [], sorting: FirestoreSorting[] = []) {
    const constraints: QueryConstraint[] = [
      ...filters.map( (filter) => where(filter.field, filter.condition, filter.value) ),
      ...sorting.map( (sortCondition) => orderBy(sortCondition.field, sortCondition.order) ),
    ];
    const reference = query(collection(this.firestore, this.getPath()), ...constraints);
    return collectionData<IJob>(reference as any, this.options);
  }

  create(job: Omit<IJob, "id">) {
    let reference = collection(this.firestore, this.getPath());
    return addDoc(reference, job);
  }

  set(id: string, item: Partial<IJob>): Promise<void> {
    const reference = doc(this.firestore, this.getPath(), id);
    return setDoc(reference, item, { merge: true });
  }

  async delete(id: string) {
    const jobDocRef = doc(
      this.firestore,
      FirebaseCollectionNames.ORGANIZATIONS,
      this.store.organizationId!,
      FirebaseCollectionNames.JOBS,
      id,
    );
    const matchesCollectionRef = collection(
      this.firestore,
      FirebaseCollectionNames.ORGANIZATIONS,
      this.store.organizationId!,
      FirebaseCollectionNames.APPLICANTS,
    );
    const matchesQuery = query(matchesCollectionRef, where("jobId", "==", id));

    // Start a batch
    const batch = writeBatch(this.firestore);

    // Delete job document
    batch.delete(jobDocRef);

    // Delete every record in the matches collection with jobId = id
    const matchesSnapshot = await getDocs(matchesQuery);
    matchesSnapshot.forEach((doc) => {
      batch.delete(doc.ref); // Schedule deletion of each match
    });

    // Commit the batch
    await batch.commit();
  }

  private getPath() {
    return (
      FirebaseCollectionNames.ORGANIZATIONS +
      "/" +
      this.store.organizationId! +
      "/" +
      FirebaseCollectionNames.JOBS
    );
  }
}
