import { Injectable } from "@angular/core";
import {collection, collectionData, deleteDoc, doc, docData, Firestore, orderBy, query, setDoc, where} from "@angular/fire/firestore";
import { FirebaseCollectionNames } from "../../../../model/FirebaseCollectionNames";
import { StoreService } from "../services/store.service";
import {IUser, IUserInOrganization} from "../../../../model/IUser";
import { Api } from "./api";
import {Observable} from "rxjs";
import {FirestoreFilter} from "../model/firestore.filter";
import {FirestoreSorting} from "../model/firestore.sorting";
import {QueryConstraint} from "@firebase/firestore";
import {IResume} from "../../../../model/IResume";
import {IOrganizationUser} from "../../../../model/IOrganizationUser";

@Injectable({
  providedIn: "root",
})
export class OrganizationUsersApi extends Api<IUserInOrganization> {
  constructor(
    private firestore: Firestore,
    private store: StoreService,
  ) {
    super();
  }

  getAll(filters: FirestoreFilter[] = [], sorting: FirestoreSorting[] = []) {
    const constraints: QueryConstraint[] = [
      ...filters.map( (filter) => where(filter.field, filter.condition, filter.value) ),
      ...sorting.map( (sortCondition) => orderBy(sortCondition.field, sortCondition.order) ),
    ];
    const reference = query(collection(this.firestore, this.getPath()), ...constraints);
    return collectionData<IOrganizationUser>(reference as any, this.options);
  }

  getOne(id: string) {
    const reference = doc(this.firestore, this.getPath(), id);
    return docData<IOrganizationUser>(reference as any, this.options);
  }

  delete(id: string): Promise<void> {
    const reference = doc(this.firestore, this.getPath(), id);
    return deleteDoc(reference);
  }

  set(id: string, item: Partial<IUserInOrganization>): Promise<void> {
    const reference = doc(this.firestore, this.getPath(), id);
    return setDoc(reference, item, { merge: true });
  }

  private getPath() {
    return (FirebaseCollectionNames.ORGANIZATIONS + "/" + this.store.organizationId! + "/" + FirebaseCollectionNames.USERS);
  }
}
