<div class="subscription column">
  <div>
    <div class="subscription-name fill-space">Subscription Plan</div>
    <div class="subscription-plan">Monthly</div>
  </div>
  <div class="row gap-2 space-top-1">
    <div class="subscription-usage row row-center-left">
      <div class="subscription-usage-icon">
        <img src="assets/images/user-profile.svg" />
      </div>
      <div class="subscription-usage-label">Users:</div>
      <div class="subscription-usage-value">5</div>
    </div>
    <div class="subscription-usage row row-center-left">
      <div class="subscription-usage-icon">
        <img src="assets/images/credits.svg" />
      </div>
      <div class="subscription-usage-label">Credits Per Month:</div>
      <div class="subscription-usage-value">300</div>
    </div>
  </div>
  <div class="subscription-usage-footer">Credit balance will renew on 01/04/2024</div>
</div>
