<div class="score column">
  <div class="score-title" [class]="{'big': isHeaderTitle}"> {{ title }} </div>
  <div class="score-progress row row-center-left">
    <div class="score-progress-bar column center">
      <progress-bar class="fill-space column center"
                    [progress]="score"
                    [progressClass]="'gray'">
      </progress-bar>
    </div>
    <div class="score-progress-percentage column center" [class]="'gray'"> {{ score }}% </div>
  </div>
  <div class="score-justification"> {{ scoreJustification }} </div>
  <div class="score-average" *ngIf="scoreDiff">
    {{ Math.abs(scoreDiff) }}% {{ scoreDiff > 0 ? 'Higher' : 'Lower' }} than the average ({{averageScore}}%).
  </div>
</div>