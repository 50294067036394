<div class="invitation column center" *ngIf="invitation">
  <div class="invitation-title"> Pending Invitation </div>
  <div class="invitation-icon"> <img src="assets/images/invitation.svg"/> </div>
  <div class="column center">
    <div class="invitation-text">You have been invited to join </div>
    <div class="invitation-organization">{{ invitation.organizationName }}</div>
  </div>
  <div class="buttons column center">
    <div class="button-accept" (click)="acceptInvitation(invitation)"> Accept Invitation </div>
    <div class="button-decline"> Decline</div>
  </div>
</div>