<div class="resume-content-section column fill-space">

  <div class="resume-content-section-header fill-space">
    <div class="resume-content-section-header-icon center"> <img src="assets/images/section-education.svg"/> </div>
    <div class="resume-content-section-header-title center"> Education </div>
  </div>

  <div class="resume-content-section-data column fill-space">

    <empty-list text="No Education Record" *ngIf="!education || !education.length"></empty-list>

    <ng-container *ngIf="education">
      <div class="resume-node column" *ngFor="let e of education">

        <div class="resume-node-header">
          <div class="resume-node-icon"> <img src="assets/images/resume-list-education.svg"/> </div>
          <div class="resume-node-header-content column">
            <div class="resume-node-title capitalize"> {{ e.degree }} </div>
            <div class="capitalize" [class]="{'resume-node-subtitle': e.institution, 'empty-info': !e.institution}">
              {{ e.institution ? e.institution.toLowerCase() : 'No Educational Institution info' }}
            </div>
          </div>
        </div>

        <div class="resume-node-content column gap-1">

          <div class="resume-node-date" *ngIf="e.startYear || e.graduationYear">
            <div class="resume-node-date-icon center"> <img src="assets/images/calendar.svg"/> </div>
            <div class="resume-node-date-text gap-1 row-center-left">
              <div class="resume-node-info-data"> Start Year: {{ e.startYear ? e.startYear : 'Unknown' }} </div>
              <div class="resume-node-info-data"> Graduation Year: {{ e.graduationYear ? e.graduationYear : 'Unknown' }} </div>
            </div>
          </div>

          <div class="education-info column">

            <div class="resume-node-info capitalize">
              <div class="resume-node-info-label">  Department: </div>
              <div class="resume-node-info-data"> {{ e.department ? e.department : "Unknown" }} </div>
            </div>
            <div class="resume-node-info capitalize">
              <div class="resume-node-info-label">  Field Of Studies: </div>
              <div class="resume-node-info-data"> {{ e.field ? e.field : "Unknown" }}  </div>
            </div>
            <div class="resume-node-info capitalize">
              <div class="resume-node-info-label">  GPA: </div>
              <div class="resume-node-info-data"> {{ e.gpa ? e.gpa : "Unknown" }} </div>
            </div>
            <div class="resume-node-info capitalize">
              <div class="resume-node-info-label column">  Thesis: </div>
              <div class="column">
                <div class="resume-node-info-data"> {{ e.thesis ? e.thesis : "No Thesis Info" }} </div>
                <div class="resume-node-description"> {{ e?.thesisDescription }} </div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </ng-container>

  </div>


</div>
