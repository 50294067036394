<div id="users" class="page-column row fill-space overflow-hidden">
    <div class="column fill-space overflow-hidden">
        <div class="header row">
            <div class="fill-space column">
                <div class="title">Users</div>
            </div>
            <div class="header-icon" (click)="showCreateUser()">
                <div class="button button-action">+ Invite User</div>
            </div>
        </div>

        <div class="search row space-bottom-1">
            <div class="search-icon">
                <img src="assets/images/search.svg"/>
            </div>
            <div class="search-input fill-space">
                <input type="text" class="fill-space" placeholder="Search Users" [formControl]="searchControl"/>
            </div>
        </div>

        <div class="block overflow-auto">
            <!-- Check if observable has emitted values and is not empty -->
            <ng-container *ngIf="filteredUsers$ | async as users; else loading">
                <!-- Display users if the array is not empty -->
                <ng-container *ngIf="users.length > 0; else empty">
                    <div class="block" *ngFor="let user of users">
                        <user-panel [user]="user"></user-panel>
                    </div>
                </ng-container>
            </ng-container>

            <!-- No users message -->
          <ng-template #empty>
            <div class="empty column center space-top-bottom-1">
              <div class="empty-icon"> <img src="assets/images/empty.svg"/> </div>
              <div class="empty-title"> No Users  </div>
              <div class="empty-text" (click)="showCreateUser()">  <div class="button button-action"> + Invite User </div>  </div>
            </div>
          </ng-template>

            <!-- Loading message -->
            <ng-template #loading>
              <div class="empty column center">
                <loader></loader>
              </div>
            </ng-template>
        </div>
    </div>
</div>
