import { Component, Input } from '@angular/core';
import { IResumeContentSkills } from '../../../../../../../../model/IResume';

@Component({
  selector: 'resume-content-skills',
  templateUrl: './resume-content-skills.component.html',
  styleUrls: ['./resume-content-skills.component.css'],
})
export class ResumeContentSkillsComponent {
  @Input() skills: IResumeContentSkills | null | undefined;
}
