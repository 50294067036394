import {Component, Input, OnInit} from '@angular/core';
import { IJob } from '../../../../../../../../model/IJob';
import {Observable} from "rxjs";
import {JobService} from "../../../../../services/job.service";

@Component({
  selector: 'job-description',
  templateUrl: './job-description.component.html',
  styleUrls: ['./job-description.component.css'],
})
export class JobDescriptionComponent implements OnInit {
  job$: Observable<IJob|undefined> | undefined;

  constructor(private jobService: JobService ) {
  }

  ngOnInit() {
    this.job$ = this.jobService.job$;
  }

}
