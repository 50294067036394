import { Pipe, PipeTransform } from '@angular/core';
import { IApplicant } from '../../../../model/IApplicant';
import { EApplicantGroupType, IApplicantGroup } from '../model/IApplicantGroup';
import { UtilService } from '../services/util.service';

@Pipe({
  name: 'filterApplicantsByGroup',
})
export class FilterApplicantsByGroupPipe implements PipeTransform {
  constructor(private utilService: UtilService) {}

  transform(applicants: IApplicant[] | undefined, group: IApplicantGroup): any[] {
    if (!applicants) return [];
    if (!group) return applicants;

    return applicants.filter((applicant) => {
      if (!applicant) {
        return false;
      }
      return this.utilService.getCategoryForApplicant(applicant).type === group.type;
    });
  }
}
