import { Injectable } from '@angular/core';
import { doc, docData, Firestore, setDoc } from '@angular/fire/firestore';
import { FirebaseCollectionNames } from '../../../../model/FirebaseCollectionNames';
import { IJobDetails } from '../../../../model/IJob';
import { StoreService } from '../services/store.service';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class PortalJobsApi extends Api<IJobDetails> {
  constructor(
    private firestore: Firestore,
    private store: StoreService
  ) {
    super();
  }

  set(id: string, details: IJobDetails) {
    return setDoc(doc(this.firestore, this.getPath(), id), details);
  }

  getOne(id: string) {
    const reference = doc(this.firestore, this.getPath(), id);
    return docData<IJobDetails>(reference as any, this.options);
  }

  private getPath() {
    return FirebaseCollectionNames.PORTAL_ORGANIZATIONS + '/' + this.store.organizationId! + '/' + FirebaseCollectionNames.JOBS;
  }
}
