<div id="resume-overview" class="column" *ngIf="overview">

  <div class="header fill-space" (click)="showOverview = !showOverview">
    <div class="header-icon  fill-space"> <img src="assets/images/hirefox-quick-overview.svg"/> </div>
    <div class="header-arrow center"> <img src="assets/images/arrow-down.svg"/> </div>
  </div>

  <div id="resume-overview-content" class="row wrap" *ngIf="showOverview">
      <div id="resume-overview-content-left" class="resume-overview-column column fill-space-equal">
        <resume-overview-experience [experience]="overview.experience"></resume-overview-experience>
        <resume-overview-gaps [gaps]="overview.gapsInEmployment"></resume-overview-gaps>
        <resume-overview-employment [isCurrentlyEmployed]="overview.experience.isCurrentlyEmployed"></resume-overview-employment>

        <!-- <resume-overview-indicators [indicators]="overview.indicators"></resume-overview-indicators> -->
      </div>
      <div id="resume-overview-content-sidebar" class="resume-overview-column column fill-space-equal">
        <resume-overview-education [education]="overview.education"></resume-overview-education>
       <!-- <resume-overview-radar [resumeScore]="overview.score"></resume-overview-radar> -->
        <resume-overview-top-skills [skills]="overview.topSkills"></resume-overview-top-skills>

        <resume-overview-career [career]="overview.career"></resume-overview-career>
      </div>
  </div>

</div>
