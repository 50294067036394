import { Component, Input } from '@angular/core';
import { IOrganization } from '../../../../../../model/IOrganization';

@Component({
  selector: 'organization-panel',
  templateUrl: './organization-panel.component.html',
  styleUrls: ['./organization-panel.component.css'],
})
export class OrganizationPanelComponent {
  @Input() organization: IOrganization | undefined;
  @Input() activeOrganizationId: string | undefined;

  setActiveOrganization(id: string) {
    console.log(id);
  }
}
