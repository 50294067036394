<div class="overview-section column" *ngIf="career">

  <div class="overview-section-title">Career</div>

  <div class="overview-section-content column">

    <div class="overview-subsection column">
      <div class="career-section-header">
        <div class="overview-subsection-title fill-space">  Career Progression   </div>
        <div class="career-section-header-info" (click)="showInfo(EResumeSectionType.CAREER_PROGRESSION)">
          <img src="assets/images/info.svg" />
        </div>
      </div>
      <div class="career-section-content">
        <div class="career-section-content-icon center">
          <img [src]="'assets/images/career-progression-' +career.progression.toLowerCase() +'.svg'" />
        </div>
        <div class="career-section-content-value center">
          {{ career.progression  }}
        </div>
      </div>
      <div class="career-section-justification"> {{ career.progressionJustification }}</div>
    </div>

    <div class="overview-subsection column">
      <div class="career-section-header">
        <div class="overview-subsection-title fill-space">Career Focus</div>
        <div class="career-section-header-info" (click)="showInfo(EResumeSectionType.CAREER_FOCUS)">
          <img src="assets/images/info.svg" />
        </div>
      </div>
      <div class="career-section-content">
        <div class="career-section-content-icon center">
          <img  [src]="'assets/images/career-focus-' + career.focus.toLowerCase() + '.svg'" />
        </div>
        <div class="career-section-content-value center">{{ career.focus }}</div>
      </div>
      <div class="career-section-justification"> {{ career.focusJustification }}</div>
    </div>

  </div>

</div>
