export enum EIndexField {
    // Resume Index Fields
    PROFESSION = 'resumeAnalysis.info.profession',
    TECHNICAL_SKILLS = 'resumeAnalysis.content.skills.technical',
    DOMAIN_EXPERTISE = 'resumeAnalysis.content.skills.domainExpertise',
    COMPANY = 'resumeAnalysis.content.experience.company',
    EDUCATION_INSTITUTION = 'resumeAnalysis.content.education.institution',
    LANGUAGE = 'resumeAnalysis.content.languages.language',
    EXPERIENCE_LEVEL = 'resumeAnalysis.overview.experience.experienceLevel',
    EDUCATION_LEVEL = 'resumeAnalysis.overview.education.educationLevel',

    // Job Index Fields
    JOB_STATUS = 'jobStatus'
}