import { Injectable } from '@angular/core';
import { collection, doc, docData, Firestore, getDoc, runTransaction, setDoc } from '@angular/fire/firestore';
import { FirebaseCollectionNames } from '../../../../model/FirebaseCollectionNames';
import { FirebaseConverterService } from './firebase-converter.service';
import { IOrganization, IOrganizationPortalInfo, IPortalConfiguration } from '../../../../model/IOrganization';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class PortalOrganizationsApi extends Api<IOrganizationPortalInfo> {
  constructor(
    private firestore: Firestore,
    private organizationPortalConverterService: FirebaseConverterService<IOrganizationPortalInfo>
  ) {
    super();
  }

  getPortalSettings(organizationId: string) {
    return docData(
      doc(
        collection(this.firestore, FirebaseCollectionNames.PORTAL_ORGANIZATIONS).withConverter(
          this.organizationPortalConverterService.converter
        ),
        organizationId
      )
    );
  }

  togglePortal(organizationId: string, name: string, isPortalEnabled: boolean) {
    return setDoc(
      doc(this.firestore, FirebaseCollectionNames.PORTAL_ORGANIZATIONS, organizationId),
      {
        isPortalEnabled,
        name
      },
      { merge: true }
    );
  }

  async isPortalEndpointAvailable(portalEndpoint: string): Promise<boolean> {
    const endpointRef = doc(this.firestore, FirebaseCollectionNames.PORTAL_ENDPOINTS, portalEndpoint);
    const endpointSnap = await getDoc(endpointRef);
    return !endpointSnap.exists();
  }

  async updatePortalConfiguration(organizationId: string, config: IPortalConfiguration) {
    const newPortalEndpoint = config.portalEndpoint;

    return runTransaction(this.firestore, async (transaction) => {
      const orgRef = doc(this.firestore, FirebaseCollectionNames.PORTAL_ORGANIZATIONS, organizationId);
      const orgSnap = await transaction.get(orgRef);

      const orgData = orgSnap.data() as IOrganizationPortalInfo;
      const currentPortalEndpoint = orgData?.portalConfiguration?.portalEndpoint;

      // If the portalEndpoint has changed, update the portalEndpoints collection
      if (currentPortalEndpoint !== newPortalEndpoint) {
        const newEndpointRef = doc(this.firestore, FirebaseCollectionNames.PORTAL_ENDPOINTS, newPortalEndpoint);
        const newEndpointSnap = await transaction.get(newEndpointRef);

        if (newEndpointSnap.exists() && newEndpointSnap.data().organizationId !== organizationId) {
          throw new Error('Portal endpoint is already taken');
        }

        // Remove the old portalEndpoint if it exists
        if (currentPortalEndpoint) {
          const oldEndpointRef = doc(this.firestore, FirebaseCollectionNames.PORTAL_ENDPOINTS, currentPortalEndpoint);
          console.log(oldEndpointRef);
          transaction.delete(oldEndpointRef);
        }
        // Add the new portalEndpoint to the portalEndpoints collection
        transaction.set(newEndpointRef, { organizationId });
      }

      // Update the organization's document with the portalConfiguration
      transaction.set(orgRef, { portalConfiguration: config }, { merge: true });
    });
  }
}
