import { Injectable } from "@angular/core";
import { serverTimestamp } from "@angular/fire/firestore";
import { Timestamp } from "firebase/firestore";

@Injectable({
  providedIn: "root",
})
export class Api<T> {
  options: object = { idField: "id" };

  constructor() {}


  getServerTimestamp() {
    return serverTimestamp() as Timestamp;
  }
}
