import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./pages/login/login.component";
import { ContentComponent } from "./common/content/content.component";
import { PageComponent } from "./common/page/page.component";
import { EmailVerificationComponent } from "./pages/email-verification/email-verification.component";
import { ResumesComponent } from "./pages/resumes/resumes.component";
import { JobsComponent } from "./pages/jobs/jobs.component";
import { SettingsComponent } from "./pages/settings/settings.component";
import { JobComponent } from "./pages/jobs/job/job.component";
import { JobApplicantComponent } from "./pages/jobs/job/job-applicant/job-applicant.component";
import { AppComponent } from "./app.component";
import { AuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo, canActivate, loggedIn } from "@angular/fire/auth-guard";
import { ResumeComponent } from "./pages/resumes/resume/resume.component";
import { map, pipe } from "rxjs";
import { EntryComponent } from "./pages/entry/entry.component";
import { NoAccessCompanyComponent } from "./pages/no-access-company/no-access-company.component";
import { UserOrganizationsComponent } from "./pages/user-organizations/user-organizations.component";
import { RegisterComponent } from "./pages/register/register.component";
import { organizationResolver } from "./resolvers/organization.resolver";
import { UsersComponent } from "./pages/users/users.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { JobDescriptionComponent } from "./pages/jobs/job/job-pages/job-description/job-description.component";
import { JobApplicantsComponent } from "./pages/jobs/job/job-pages/job-applicants/job-applicants.component";
import { ApplicantComponent } from "./pages/jobs/job/job-pages/job-applicants/applicant/applicant.component";
import { ApplicantNotesComponent } from "./pages/jobs/job/job-pages/job-applicants/applicant/applicant-notes/applicant-notes.component";
import { ApplicantResumeComponent } from "./pages/jobs/job/job-pages/job-applicants/applicant/applicant-resume/applicant-resume.component";
import { PipelineComponent } from "./pages/jobs/job/job-pages/pipeline/pipeline.component";
import { ApplicantEvaluationComponent } from "./pages/jobs/job/job-pages/job-applicants/applicant/applicant-evaluation/applicant-evaluation.component";
import { ApplicantRatingsComponent } from "./pages/jobs/job/job-pages/job-applicants/applicant/applicant-ratings/applicant-ratings.component";
import { CheckoutsSuccessComponent } from "./pages/checkouts/checkouts-success/checkouts-success.component";
import { CheckoutsCancelComponent } from "./pages/checkouts/checkouts-cancel/checkouts-cancel.component";
import { SubscriptionPlanComponent } from "./pages/subscription-plan/subscription-plan.component";

const hireFoxEntryPage = "/entry";

const redirectToProfileEditOrLogin = () => {
  return pipe(
    loggedIn,
    map((loggedIn) => (loggedIn ? [hireFoxEntryPage] : ["/login"])),
  );
};

const redirectLoggedInToMainPage = () => redirectLoggedInTo([hireFoxEntryPage]);
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["/login"]);

const routes: Routes = [
  {
    path: "",
    component: AppComponent,
    ...canActivate(redirectToProfileEditOrLogin),
  }, // TODO This can be potentially merged with the next line
  {
    path: "login",
    component: LoginComponent,
    ...canActivate(redirectLoggedInToMainPage),
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    ...canActivate(redirectLoggedInToMainPage),
  },
  {
    path: "register",
    component: RegisterComponent,
    ...canActivate(redirectLoggedInToMainPage),
  },
  {
    path: "",
    component: ContentComponent,
    ...canActivate(redirectUnauthorizedToLogin),
    children: [
      { path: "no-access-company", component: NoAccessCompanyComponent },
      { path: "entry", component: EntryComponent },
      { path: "email-verification", component: EmailVerificationComponent },

      { path: "user-organizations", component: UserOrganizationsComponent },
      {
        path: "organization/:organizationId",
        component: PageComponent,
        resolve: { organizationId: organizationResolver },
        children: [
          { path: "", redirectTo: "resumes", pathMatch: "full" },
          {
            path: "resumes",
            component: ResumesComponent,
            children: [
              { path: "", pathMatch: "full", component: ResumeComponent },
              { path: ":resumeId", component: ResumeComponent },
            ],
          },
          { path: "jobs", component: JobsComponent },
          {
            path: "jobs/:jobId",
            component: JobComponent,
            children: [
              { path: "", redirectTo: "applicants", pathMatch: "full" }, // redirect to applicants
              { path: "job-description", component: JobDescriptionComponent },
              {
                path: "pipeline",
                component: PipelineComponent,
                children: [
                  {
                    path: ":applicantId",
                    component: ApplicantComponent,
                    children: [
                      { path: "", redirectTo: "ai-evaluation", pathMatch: "full" },
                      { path: "ai-evaluation", component: ApplicantEvaluationComponent },
                      { path: "ai-resume", component: ApplicantResumeComponent },
                      { path: "notes", component: ApplicantNotesComponent },
                      { path: "ratings", component: ApplicantRatingsComponent },
                    ],
                  },
                ],
              },
              {
                path: "applicants",
                component: JobApplicantsComponent,
                children: [
                  {
                    path: ":applicantId",
                    component: ApplicantComponent,
                    children: [
                      { path: "", redirectTo: "ai-evaluation", pathMatch: "full" },
                      { path: "ai-evaluation", component: ApplicantEvaluationComponent },
                      { path: "ai-resume", component: ApplicantResumeComponent },
                      { path: "notes", component: ApplicantNotesComponent },
                      { path: "ratings", component: ApplicantRatingsComponent },
                    ],
                  },
                ],
              },
            ],
          },
          { path: "users", component: UsersComponent },
          { path: "settings", component: SettingsComponent },
          { path: "subscription", component: SubscriptionPlanComponent },
          {
            path: "checkout",
            children: [
              { path: "success/:sessionId", component: CheckoutsSuccessComponent },
              { path: "cancel/:sessionId", component: CheckoutsCancelComponent },
            ],
          },
        ],
      },
    ],
  },
];

// https://angular.io/api/router/RouterConfigOptions#paramsInheritanceStrategy
@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: "always" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
