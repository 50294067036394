import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { IResumeIndicator } from '../../../../../../../model/IResume';
import { IndicatorService } from '../../../../services/indicator.service';
import { IndicatorInfoComponent } from '../../../../common/indicator-info/indicator-info.component';
import { MatDialog } from '@angular/material/dialog';
import {IInsight} from "../../../../model/IInsight";


@Component({
  selector: 'resume-insights',
  templateUrl: './resume-insights.component.html',
  styleUrls: ['./resume-insights.component.css'],
})
export class ResumeInsightsComponent implements OnInit, OnChanges {
  @Input() indicators: any = {};
  insights: (IInsight|null)[] = [];
  showInsights: boolean = true;

  constructor(
    public indicatorService: IndicatorService,
    private dialog: MatDialog,
  ) {}

  showIndicatorInfo(indicator: IInsight): void {
    let dialog = this.dialog.open(IndicatorInfoComponent, {});
    dialog.componentInstance.indicator = indicator;
  }

  initializeIndicators() {
    if(this.indicators) {
      this.insights = Object.keys(this.indicators || {}).map((key: string) => {
        if (this.indicators[key]) {
          return { key: key, isMatch: this.indicators[key].isMatch, reasoning: this.indicators[key].reasoning };
        }
        return null;
      }).filter(item => item !== null && item.isMatch);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initializeIndicators();
  }

  ngOnInit() {

  }
}
