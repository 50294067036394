import { Injectable } from "@angular/core";
import { IUser } from "../../../../model/IUser";
import { catchError, Observable, of } from "rxjs";
import { User } from "@angular/fire/auth";
import { AuthService } from "./auth.service";
import { UsersApi } from "../api/users.api";
import { switchMap } from "rxjs/operators";
import { StoreService } from "./store.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private firebaseUser$: Observable<User | null> = this.auth.firebaseUser$;
  user$: Observable<IUser | null | undefined> = of(undefined);
  user: IUser | null | undefined;

  constructor(
    private auth: AuthService,
    private store: StoreService,
    private usersApi: UsersApi,
  ) {
    this.user$ = this.firebaseUser$.pipe(
      switchMap((firebaseUser) => {
        return firebaseUser ? this.usersApi.getOne(firebaseUser.uid) : of(undefined);
      }),
      catchError((error) => {
        console.error("Error fetching user:", error);
        return of(null);
      }),
    );
    this.user$.subscribe((user) => {
      this.store.userId = user ? user.id : null;
      this.user = user;
    });
  }
}
