import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserWithRoles } from '../../../../../../model/IUser';
import { MatDialog } from '@angular/material/dialog';
import { CompanyFormComponent } from './company-form-component/company-form.component';
import { UserWithOrganizationsService } from '../../../services/user-with-organizations.service';

@Component({
  selector: 'settings-company',
  templateUrl: './settings-companies.component.html',
  styleUrls: ['./settings-companies.component.css'],
})
export class SettingsCompaniesComponent {
  userWithOrganizationsWithRole$: Observable<IUserWithRoles | undefined>;

  constructor(
    public dialog: MatDialog,
    private userWithOrganizationService: UserWithOrganizationsService,
  ) {
    this.userWithOrganizationsWithRole$ = this.userWithOrganizationService.userWithResolvedOrganizationsWithRole$;
  }

  showCreateCompany() {
    let dialog = this.dialog.open(CompanyFormComponent);
  }
}
