<div class="popup column">
  <div class="popup-header row">
    <div class="popup-header-title fill-space">Indicator Info</div>
    <div class="popup-header-close" mat-dialog-close>
      <img src="assets/images/close.svg" />
    </div>
  </div>

  <div class="popup-content column">
    <div class="indicator-info column" *ngIf="indicator">
      <div class="center">
        <div class="indicator">
          <div class="indicator-icon center"> <img [src]="'assets/images/indicator-' + indicatorService.indicatorData[indicator.key].polarity.toLowerCase() + '.svg'"/> </div>
          <div class="indicator-text center"> {{ indicatorService.indicatorData[indicator.key].type }} </div>
        </div>
      </div>
      <div class="center">
        <div class="indicator-description center">
          {{ indicatorService.indicatorData[indicator.key].description }}
        </div>
      </div>
      <div class="indicator-reasoning column">
        <div class="indicator-reasoning-label"> Reasoning: </div>
        <div class="indicator-reasoning-text"> {{indicator.reasoning}} </div>
      </div>
    </div>
    <div class="error" *ngIf="!indicator"> Could Not Find Indicator Info! </div>
  </div>

  <div class="popup-buttons row">
    <div class="fill-space"></div>
    <div class="button button-gray" mat-dialog-close> Close </div>
    <div class="fill-space"></div>
  </div>
</div>
