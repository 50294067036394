<div class="popup column">
    <div class="popup-header column">
        <div class="row">
            <div class="popup-header-title fill-space"> {{ title }} </div>
            <div class="popup-header-close" mat-dialog-close>
                <img src="assets/images/close.svg" />
            </div>
        </div>
    </div>

    <div class="popup-content column overflow-hidden" *ngIf="resumes$ | async as resumes">

        <div class="search row space-bottom-1">
            <div class="search-icon"><img src="assets/images/search.svg" /></div>
            <div class="search-input fill-space">
                <input type="text" placeholder="Search" class="fill-space" [formControl]="searchControl"/>
            </div>
        </div>

        <div class="resumes scrollbar column">
            <resume-selector-panel
                    *ngFor="let resume of resumes"
                    [resume]="resume"
                    [isSelected]="isSelected(resume.id)"
                    (click)="selectResume(resume.id)">
            </resume-selector-panel>
        </div>

    </div>

    <div class="popup-buttons row">
        <div class="button button-gray" mat-dialog-close> Cancel </div>
        <div class="fill-space"></div>
        <div class="button button-action" (click)="returnSelectedResumes()"> {{ actionLabel }}</div>
    </div>
</div>
