import {Component, Input, OnInit} from '@angular/core';
import {ResumesApi} from "../../../../../../api/resumes.api";
import {IResume} from "../../../../../../../../../model/IResume";
import {merge, Observable} from "rxjs";
import {EStatus} from "../../../../../../../../../model/enums/EResumeStatuses";

@Component({
  selector: 'job-applicant-uploads',
  templateUrl: './job-applicant-uploads.component.html',
  styleUrls: ['./job-applicant-uploads.component.css']
})
export class JobApplicantUploadsComponent implements OnInit {

  @Input() jobId: string | null | undefined;
  uploading$: Observable<IResume[]> | undefined;
  stored$: Observable<IResume[]> | undefined;
  processing$: Observable<IResume[]> | undefined;
  uploads$: Observable<IResume[]> | undefined;

  constructor(private resumesApi: ResumesApi) {

  }


  ngOnInit() {
    this.uploading$ = this.resumesApi.getAll([
      { field: 'status', condition: '==', value: EStatus.UPLOADING },
      { field: 'jobId', condition: '==', value: this.jobId }
    ]);
    this.stored$ = this.resumesApi.getAll([
      { field: 'status', condition: '==', value: EStatus.STORED },
      { field: 'jobId', condition: '==', value: this.jobId }
    ]);
    this.processing$ = this.resumesApi.getAll([
      { field: 'status', condition: '==', value: EStatus.PROCESSING },
      { field: 'jobId', condition: '==', value: this.jobId }
    ]);

    this.uploads$ = merge(this.uploading$, this.stored$, this.processing$);

  }

}
