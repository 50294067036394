import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {IApplicant} from "../../../../model/IApplicant";

@Injectable({
  providedIn: 'root'
})
export class ApplicantsService {

  applicants$: Observable<IApplicant[]|undefined> | undefined;

  constructor() { }
}
