import { Injectable } from "@angular/core";
import {
  addDoc,
  collection,
  collectionData,
  doc,
  docData,
  Firestore,
  getDocs,
  query,
  where,
  writeBatch,
} from "@angular/fire/firestore";
import { FirebaseCollectionNames } from "../../../../model/FirebaseCollectionNames";
import { IJob } from "../../../../model/IJob";
import { StoreService } from "../services/store.service";
import { Api } from "./api";
import {ITrigger} from "../model/ITrigger";



@Injectable({
  providedIn: "root",
})
export class TriggersApi extends Api<ITrigger> {
  constructor(private firestore: Firestore, private store: StoreService) {
    super();
  }

  create(functionId: string, trigger: ITrigger) {
    let reference = collection(this.firestore, this.getPath() + '/' + functionId + '/events');
    return addDoc(reference, trigger);
  }


  private getPath() {
    return FirebaseCollectionNames.TRIGGERS;
  }
}
