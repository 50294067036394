import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, map, Observable, switchMap, take } from 'rxjs';
import { PaymentsService } from './payments.service';
import { StoreService } from './store.service';
import { Subscription } from '../../firestore-stripe-web-sdk/src';
import { environment } from '../../environments/environment';

export interface IPlan {
  name: string;
  users: number;
  credits: number;
}

export interface IPaidSubscription extends IPlan {
  subscriptionId: string;
}

export const planSuggestions: IPlan[] = [
  { users: 1, credits: 100, name: 'Individual' },
  { users: 10, credits: 200, name: 'Small Team' },
  { users: 30, credits: 400, name: 'Enterprise' }
];

@Injectable({
  providedIn: 'root'
})
export class PaidSubscriptionsService {
  public usersProductPriceInCents$: Observable<number | null | undefined> | undefined;
  public creditsProductPriceInCents$: Observable<number | null | undefined> | undefined;
  public selectedPlans$: Observable<IPaidSubscription[]> | undefined;
  private activeSubscriptions$: Observable<Subscription[]>;

  public refreshSubscriptions$ = new BehaviorSubject<void>(undefined);

  constructor(
    private paymentsService: PaymentsService,
    private store: StoreService
  ) {
    const products$ = from(this.paymentsService.getProducts());
    this.activeSubscriptions$ = this.refreshSubscriptions$.pipe(switchMap(() => from(this.paymentsService.getOrganizationSubscriptions())));

    // Get all prices in the "prices" array of all the products and see if any price id matches the user's product price id
    this.usersProductPriceInCents$ = products$.pipe(
      map((products) => {
        return products
          .map((product) => product.prices)
          .flat()
          .find((price) => price.id === environment.stripe.usersProductPriceId);
      }),
      map((price) => price!.unit_amount! / (price?.transform_quantity?.divide_by ?? 1) ?? 1)
    );

    // Get all prices in the "prices" array of all the products and see if any price id matches the credits product price id
    this.creditsProductPriceInCents$ = products$.pipe(
      map((products) => {
        return products
          .map((product) => product.prices)
          .flat()
          .find((price) => price.id === environment.stripe.creditsProductPriceId);
      }),
      map((price) => price!.unit_amount! / (price?.transform_quantity?.divide_by ?? 1) ?? 1)
    );

    // combine latest from the products and the user's subscription
    // to determine the user's selected plan
    this.selectedPlans$ = this.activeSubscriptions$.pipe(
      map((subscriptions) => {
        const plans: IPaidSubscription[] = [];

        // TODO check if subscription is active
        for (const subscription of subscriptions) {
          const usersInSubscription = subscription.items.find((item) => item.price.id === environment.stripe.usersProductPriceId);

          const creditsInSubscription = subscription.items.find((item) => item.price.id === environment.stripe.creditsProductPriceId);

          plans.push({
            name: 'CurrentActivePlan',
            subscriptionId: subscription.id,
            users: usersInSubscription?.quantity ?? 0,
            credits: creditsInSubscription?.quantity ?? 0
          } satisfies IPaidSubscription);
        }

        return plans;
      })
    );
  }

  goToOrganizationPortal() {
    console.log('Creating portal link for organization...');
    return this.store.organization$?.pipe(
      take(1),
      map((organization) => {
        if (!!organization && organization.id) {
          console.log('Creating portal link for organization with ID', organization.id);
          this.paymentsService.createPortalLinkForOrganization(organization!.id).then((portal) => {
            console.log('Portal link:', portal);
            if (portal?.url) window.location.assign(portal.url);
            else console.error('No portal URL returned');
          });
        } else {
          console.error('Organization not found or missing customerId');
        }
      })
    );
  }

  startCheckoutSession(usersSelected: number, creditsSelected: number): Observable<{ redirect: boolean }> {
    console.log('PaidSubscriptionsService::startCheckoutSession', 'Starting checkout session...');

    return this.store.organization$!.pipe(
      take(1),
      switchMap((organization) => {
        if (!!organization && organization.customerId && organization.id) {
          console.log(
            'PaidSubscriptionsService::startCheckoutSession',
            'Starting checkout session for organization with ID',
            organization.id
          );

          return from(
            this.paymentsService
              .startCheckout(
                organization!.customerId,
                {
                  line_items: [
                    { price: environment.stripe.usersProductPriceId, quantity: usersSelected },
                    { price: environment.stripe.creditsProductPriceId, quantity: creditsSelected }
                  ]
                },
                organization!.id
              )
              .then((session) => {
                console.log('PaidSubscriptionsService::startCheckoutSession', 'Checkout session started:', session);
                if (session?.url) {
                  window.location.assign(session.url);
                  return { redirect: true };
                } else throw new Error('No session URL returned');
              })
          );
        } else {
          throw new Error('Organization not found or missing customerId');
        }
      })
    );
  }

  updateSubscriptionSession(usersSelected: number, creditsSelected: number): Observable<{ redirect: boolean }> {
    console.log('PaidSubscriptionsService::updateSubscriptionSession', 'Updating subscription session...');
    return combineLatest([this.store.organization$!, this.activeSubscriptions$]).pipe(
      take(1),
      switchMap(([organization, subscriptions]) => {
        if (!!organization && organization.customerId && organization.id) {
          const activeSubscription = subscriptions.find((subscription) => subscription.status === 'active');
          if (activeSubscription) {
            return from(
              this.paymentsService
                .updateSubscription(
                  organization!.customerId,
                  activeSubscription.id,
                  [
                    { price: environment.stripe.usersProductPriceId, quantity: usersSelected },
                    { price: environment.stripe.creditsProductPriceId, quantity: creditsSelected }
                  ],
                  organization!.id
                )
                .then((session) => {
                  if (session) {
                    console.log('PaidSubscriptionsService::updateSubscriptionSession', 'Subscription updated:', session);
                    return { redirect: false };
                  } else {
                    throw new Error('Update subscription session failed');
                  }
                })
            );
          } else {
            throw new Error('No active subscription found');
          }
        } else {
          throw new Error('Organization not found or missing customerId');
        }
      })
    );
  }
}
