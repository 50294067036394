import {Component, Input, OnInit} from '@angular/core';
import {IApplicant, IApplicantRating} from "../../../../../../../../../../../model/IApplicant";
import {StoreService} from "../../../../../../../../services/store.service";
import {ApplicantRatingsApi} from "../../../../../../../../api/applicant.ratings.api";
import {UserService} from "../../../../../../../../services/user.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'applicant-rating-form',
  templateUrl: './applicant-rating-form.component.html',
  styleUrls: ['./applicant-rating-form.component.css']
})
export class ApplicantRatingFormComponent implements OnInit {

  @Input() applicant: IApplicant | undefined;
  isLoading: boolean = false;
  score: number = 0;
  comments: string = "";
  pros: string[] = [];
  cons: string[] = [];
  proText: string = "";
  conText: string = "";
  isHire: boolean = true;

  rating: Omit<IApplicantRating, 'id'> | undefined;

  constructor(private userService: UserService, private applicantRatingsApi: ApplicantRatingsApi, private dialogRef: MatDialogRef<ApplicantRatingFormComponent>) {
  }

  addPro(){
    if(!this.proText || this.proText.trim() === '') { return; }
    this.pros.push(this.proText);
    this.proText = "";
  }

  removePro(index: number) {
    this.pros.splice(index, 1);
  }

  addCon(){
    if(!this.conText || this.conText.trim() === '') { return; }
    this.cons.push(this.conText);
    this.conText = "";
  }

  removeCon(index: number) {
    this.cons.splice(index, 1);
  }

  setScore(newScore: number) {
    if(this.score === 1 && newScore === 0) {
      this.score = 0;
      return;
    }
    this.score = newScore+1;
  }

  save() {
    if(!this.applicant || !this.userService.user) { return; }

    this.rating = {
      creationTime: this.applicantRatingsApi.getServerTimestamp(),
      score: this.score,
      comments: this.comments,
      pros: this.pros,
      cons: this.cons,
      isHire: this.isHire,
      userId: this.userService.user.id,
      userName: this.userService.user.name,
      applicantId: this.applicant.id,
      pipelineStage: this.applicant.pipelineStage
    };

    this.isLoading = true;
    this.applicantRatingsApi.create(this.applicant.id, this.rating).then( response => {
      this.dialogRef.close();
    }).catch( error => {
      console.log(error);
    }).finally( ()=> {
      this.isLoading = false;
    })

    console.log(this.rating);

  }

  ngOnInit() {

  }

}
