import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css'],
})
export class EmailVerificationComponent implements OnInit, OnDestroy {
  //user$ = this.auth.user$;
  isLoading: boolean = false;
  isWaiting: boolean = false;
  timeLeft: number = 60;
  interval: any | undefined;

  private destroyed$ = new Subject<void>();

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  sendVerificationEmail(): void {
    /*
    this.isLoading = true;
    this.auth.sendVerificationEmail().then(()=>{
      this.waitForOneMinute();
    }).catch( error => {
      console.error(error);
    }).finally(()=>{
      this.isLoading = false;
    });*/
  }

  waitForOneMinute() {
    this.isWaiting = true;
    setTimeout(() => {
      this.isWaiting = false;
    }, 60000);
    this.startCountdown();
  }

  startCountdown() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  ngOnInit() {
    /*
    this.auth.user$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(user => {
      if (user && user.emailVerified) {
        this.router.navigate(['/ask']);
      }
    });*/
  }
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
