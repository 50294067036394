import {Component, Input, OnInit} from "@angular/core";
import { UtilService } from "../../../services/util.service";
import { UploadService } from "../../../services/upload.service";
import { IResume } from "../../../../../../model/IResume";
import { EStatus } from "../../../../../../model/enums/EResumeStatuses";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ResumeDeleteComponent} from "../../../pages/resumes/resume-delete/resume-delete.component";
import {StorageApi} from "../../../api/storage.api";

@Component({
  selector: "upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.css"],
})
export class UploadFileComponent implements OnInit {

  @Input() resume: IResume | undefined;

  constructor(public uploadService: UploadService, public util: UtilService, private dialog: MatDialog, private storageApi: StorageApi) {

  }

  showDeleteResume() {
    if(this.resume) {
      let dialogRef: MatDialogRef<ResumeDeleteComponent> | undefined;
      dialogRef = this.dialog.open(ResumeDeleteComponent, {});
      dialogRef.componentInstance.resume = this.resume;
    }
  }

  remove() {}

  ngOnInit() {

  }

  protected readonly EStatus = EStatus;
}
