import {Component, Input} from '@angular/core';

@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent {

  @Input() text: string | undefined;
  @Input() type: string | undefined;
  @Input() className: string = "default";

}
