<div id="company-settings" class="column">
    <div id="company-settings-content" class="column space-top-bottom-1" *ngIf="organization$ | async; else noActive">
        <div class="form-field column">
            <div class="row">
                <div class="form-field-label">Company Name</div>
                <mat-spinner
                        *ngIf="organizationNameIsUpdating"
                        style="margin-left: auto"
                        diameter="16"
                ></mat-spinner>
            </div>
            <div class="form-field-input">
                <input  type="text" [formControl]="nameControl" />
            </div>
        </div>
    </div>
    <ng-template #noActive>
        <div class="space-bottom-1">No active company</div>
    </ng-template>
</div>
