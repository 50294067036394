<div class="radar-chart center" *ngIf="isChartVisible">
  <!--<div class="radar-photo"> <img src="assets/images/user-profile.svg"/> </div>-->
  <canvas baseChart [data]="chart.data" [options]="chart.options" [type]="'radar'"> </canvas>
</div>
<div class="radar-chart-footer center">
  <div class="radar-data">
    <div class="radar-data-box"> <img src="assets/images/radar-point-candidate.svg"/> </div>
    <div class="radar-data-label center"> Candidate </div>
  </div>
  <div class="radar-data">
    <div class="radar-data-box"> <img src="assets/images/radar-point-average.svg"/> </div>
    <div class="radar-data-label center"> Average  </div>
  </div>
</div>