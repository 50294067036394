<div class="resume-content-section column fill-space">

  <div class="resume-content-section-header fill-space">
    <div class="resume-content-section-header-icon center"> <img src="assets/images/section-references.svg"/> </div>
    <div class="resume-content-section-header-title center"> References </div>
  </div>

  <div class="resume-content-section-data column fill-space">

    <empty-list text="No References" *ngIf="!references?.length"></empty-list>

    <ng-container *ngIf="references">
      <div class="resume-node column" *ngFor="let r of references">

        <div class="resume-node-header">
          <div class="resume-node-icon"> <img src="assets/images/resume-list-references.svg"/> </div>
          <div class="resume-node-header-content column">
            <div class="resume-node-title capitalize"> {{ r.name }} </div>
            <div class="capitalize" [class]="{'resume-node-subtitle': r.title, 'empty-info': !r.title}">
              {{ r.title ? r.title : 'No Details' }} {{ r.company }}
            </div>
          </div>
        </div>

        <div class="resume-node-content column gap-1">

            <div class="resume-node-info capitalize">
              <div class="resume-node-info-label">  Email: </div>
              <div class="resume-node-info-data"> {{ r.email ? r.email : "Unknown" }} </div>
            </div>
            <div class="resume-node-info capitalize">
              <div class="resume-node-info-label">  Phone: </div>
              <div class="resume-node-info-data"> {{ r.phone ? r.phone : "Unknown" }}  </div>
            </div>
            <div class="resume-node-info capitalize">
              <div class="resume-node-info-label">  Relationship: </div>
              <div class="resume-node-info-data"> {{ r.relationship ? r.relationship : "Unknown" }} </div>
            </div>

            <div class="resume-node-description"> {{ r.notes }} </div>

        </div>

      </div>
    </ng-container>

  </div>


</div>
