import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newlineToBr',
})
export class NewlineToBrPipe implements PipeTransform {
  transform(value: string | undefined | null): string {
    return value ? value.replace(/(?:\r\n|\r|\n)/g, '<br/>') : '';
  }
}
