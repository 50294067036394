<div class="match-section column">

  <job-match-section
          title="Skills"
          [score]="score"
          [scoreJustification]="scoreJustification"
          [justifications]="justifications"
          [matched]="matched"
          [unMatched]="unMatched">
  </job-match-section>

  <div class="skills-match">
    <div class="table">
      <div class="tr header-row">
        <div class="th">Job Requirement Skills</div>
        <div class="th">Candidate's Relevant Skills</div>
        <div class="th align-center">Match</div>
      </div>
      <div class="tr" *ngFor="let s of skills; let index = index">
        <div class="td">{{ s }}</div>
        <div class="td">
          <div class="candidate-skills row wrap" *ngIf="relevantSkills[index] as relevantSkillMatching">
            <div class="skill" *ngFor="let relevantSkill of relevantSkillMatching.relevantSkills">
              {{ relevantSkill }}
            </div>
          </div>
        </div>
        <div class="td" *ngIf="relevantSkills[index] as relevantSkillMatching">
          <div class="skill-match column center">
            <div class="skill-match-image center">
              <img *ngIf="relevantSkillMatching.isMatched" src="assets/images/tick-green.svg" />
              <img *ngIf="!relevantSkillMatching.isMatched" src="assets/images/x-gray.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>
