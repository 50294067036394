<div class="popup column">
    <div class="popup-header row">
        <div class="popup-header-title fill-space">Delete Resume</div>
        <div class="popup-header-close" mat-dialog-close>
            <img src="../../../../assets/images/close.svg" />
        </div>
    </div>

    <div class="popup-content row">
        <div class="column center fill-space space-top-bottom-1" *ngIf="isLoading">
            <loader></loader>
        </div>
        <div
                class="delete-confirmation fill-space column center"
                *ngIf="!isLoading"
        >
            <div class="delete-confirmation-icon center">
                <img src="../../../../assets/images/delete-circle-red.svg" />
            </div>
            <div class="delete-confirmation-text center">
                Are you sure you want to delete this Resume ?
            </div>

        </div>
    </div>

    <div class="popup-buttons row">
        <div class="button button-gray" mat-dialog-close>Cancel</div>
        <div class="fill-space"></div>
        <div class="button button-delete" (click)="deleteResume()">Yes, Delete The Resume</div>
    </div>
</div>

<ng-template #loadingState>
    <div class="column center fill-space space-top-bottom-1">
        <loader></loader>
    </div>
</ng-template>
