import { Component, Input, OnInit } from '@angular/core';
import { EResumeIndicator } from '../../../../../model/enums/EResumeIndicator';
import { IResumeIndicator } from '../../../../../model/IResume';
import { IndicatorService } from '../../services/indicator.service';
import {IInsight} from "../../model/IInsight";

@Component({
  selector: 'indicator-info',
  templateUrl: './indicator-info.component.html',
  styleUrls: ['./indicator-info.component.css'],
})
export class IndicatorInfoComponent implements OnInit {
  @Input() indicator: IInsight | undefined;

  constructor(public indicatorService: IndicatorService) {}

  ngOnInit() {}
}
