import { Component, Input } from "@angular/core";
import { UtilService } from "../../../../../../services/util.service";
import { IJustification } from "../../../../../../../../../model/IApplicant";

@Component({
  selector: "job-match-section",
  templateUrl: "./job-match-section.component.html",
  styleUrls: ["./job-match-section.component.css"],
})
export class JobMatchSectionComponent {
  @Input() title: string = "";
  @Input() score: number = 0;
  @Input() scoreJustification: string | undefined;
  @Input() justifications: IJustification[] = [];
  @Input() matched: number = 0;
  @Input() unMatched: number = 0;

  constructor(public utilService: UtilService) {}
}
