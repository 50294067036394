import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css'],
})
export class RadioComponent {
  @Input() isChecked: boolean = false;
}
