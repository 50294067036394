import { Injectable } from "@angular/core";
import { FirestoreDataConverter } from "@angular/fire/firestore";
import { QueryDocumentSnapshot } from "@firebase/firestore";
import { IUser } from "../../../../model/IUser";

@Injectable({
  providedIn: "root",
})
export class FirebaseConverterService<T> {
  public converter: FirestoreDataConverter<T>;

  constructor() {
    this.converter = {
      toFirestore: (item) => item,
      fromFirestore: (snapshot: QueryDocumentSnapshot<T>) => {
        const data = snapshot.data();
        return {
          ...data,
          id: snapshot.id,
        };
      },
    } as FirestoreDataConverter<T>;
  }
}
