import { Component, Input } from '@angular/core';

@Component({
  selector: 'resume-overview-gaps',
  templateUrl: './resume-overview-gaps.component.html',
  styleUrls: ['../resume-overview.component.css','./resume-overview-gaps.component.css'],
})
export class ResumeOverviewGapsComponent {
  @Input() gaps: string[] = [];
}
