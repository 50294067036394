import { Component, Input } from "@angular/core";
import { IResume } from "../../../../../../model/IResume";
import { FirebaseStorageFolderNames } from "../../../../../../model/FirebaseCollectionNames";
import { StoreService } from "../../../services/store.service";
import { StorageApi } from "../../../api/storage.api";

@Component({
  selector: "resume-selector-panel",
  templateUrl: "./resume-selector-panel.component.html",
  styleUrls: ["./resume-selector-panel.component.css"],
})
export class ResumeSelectorPanelComponent {
  @Input() resume: IResume | undefined;
  @Input() isSelected: boolean = false;

  constructor() {}

  ngOnInit() {

  }
}
