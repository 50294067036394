import { Component, EventEmitter, Input, Output } from "@angular/core";
import { EUserRole } from "../../../../../model/enums/EUserRole";

@Component({
  selector: "role-selector",
  templateUrl: "./role-selector.component.html",
  styleUrls: ["./role-selector.component.css"],
})
export class RoleSelectorComponent {
  rolesToDisplay: EUserRole[] = [EUserRole.ADMIN, EUserRole.MANAGER];
  @Input() initialValue: EUserRole = EUserRole.MANAGER;
  @Output() roleChanged = new EventEmitter<EUserRole>();

  constructor() {}

  userSelectsRole(role: EUserRole) {
    this.roleChanged.emit(role);
  }
}
