import { Injectable, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  combineLatest,
  map,
  mergeMap,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import { OrganizationsApi } from "../api/organizations.api";
import { UsersApi } from "../api/users.api";
import {
  IUser,
  IUserInOrganization,
  IUserInOrganizationFirebase,
} from "../../../../model/IUser";
import { docData } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class OrganizationUsersService {
  constructor(
    private organizationService: OrganizationsApi,
    private userService: UsersApi,
  ) {}

  getOrganizationUsers(organizationId: string) {
    return this.organizationService.getAll(organizationId);
  }

  getResolvedOrganizationUsers(organizationId: string) {
    return this.getOrganizationUsers(organizationId).pipe(
      map((users) => {
        // Get users for this organization

        const userSubscriptions = [];
        for (const user of users) {
          const userRef = this.userService.getOne(user.id);

          userSubscriptions.push({ forUser: user, userRef });
        }

        return userSubscriptions;
      }),
      mergeMap((userSubscriptions) => {
        // If there are no users, return an empty array
        if (userSubscriptions.length === 0) {
          return of([]);
        }

        // combineLatest user subscriptions into one and maintain the user object as well
        return combineLatest(
          userSubscriptions.map(({ forUser, userRef }) =>
            userRef.pipe(
              map((user) => {
                return {
                  ...forUser,
                  ...user!,
                } as IUserInOrganization;
              }),
            ),
          ),
        );
      }),
      //tap((users) => console.log('OrganizationUsersService::getResolvedOrganizationUsers users', users)), // debug
    );
  }

  filterResolvedOrganizationUsers(
    organizationUsers$: Observable<IUserInOrganization[]>,
    searchFilter$: Observable<string>,
  ) {
    return combineLatest([organizationUsers$, searchFilter$]).pipe(
      map(([users, searchFilter]) => {
        if (!searchFilter) {
          return users;
        }

        return users.filter((user) => {
          const searchFilterLower = searchFilter.toLowerCase();
          const firstNameLower = user.name.toLowerCase();
          const emailLower = user.email.toLowerCase();

          return (
            firstNameLower.includes(searchFilterLower) ||
            emailLower.includes(searchFilterLower)
          );
        });
      }),
      //tap((users) => console.log('OrganizationUsersService::filterResolvedOrganizationUsers users', users)), // debug
    );
  }
}
