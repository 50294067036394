<div class="company row row-center-left" *ngIf="organizationWithRole">
  <div class="company-icon column"><img src="assets/images/user.svg" /></div>
  <div class="company-content column">
    <div class="company-name">{{ organizationWithRole.name }}</div>
  </div>
  <div class="fill-space"></div>
  <div class="center space-right-2">{{ organizationWithRole.role }}</div>
  <div class="column center">
    <div class="button-small button-action">Open workspace</div>
  </div>
</div>
