import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {StoreService} from "../../services/store.service";
import {UserService} from "../../services/user.service";
import * as Sentry from "@sentry/angular-ivy";

@Component({
  selector: "content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.css"],
})
export class ContentComponent implements OnInit {
  constructor(
    public userService: UserService,
    private router: Router,
    private store: StoreService,
  ) {}

  ngOnInit() {
    this.userService.user$.subscribe((user) => {
      // Login Auth check
      if (!user) {
        this.router.navigate(["login"]);
      }
      // If user opens another window and changes the organization
      if (
        user &&
        user.favoriteOrganization &&
        window.location.pathname.startsWith("/organization")
      ) {
        if (user.favoriteOrganization != this.store.organizationId) {
          window.location.href = `/organization/${user.favoriteOrganization}`;
        }

        // TODO we should setUser(null) on logout
        Sentry.setUser({id: user.id, email: user.email, name: user.name});
      }
    });
  }
}
