<div id="login">
    <div id="login-content" class="column" *ngIf="!isLoading">
        <div id="login-logo" class="fill-space center">
            <img src="assets/images/logo-vertical.svg" />
        </div>
        <div id="register-title" class="fill-space center"> Request Password Reset </div>
        <form id="credentials" class="column fill-space" *ngIf="!isLoading && !isEmailSent">
            <div class="form-field column fill-space space-bottom-1">
                <div class="form-field-label">Enter your Email below to request a password reset</div>
                <div class="form-field-input fill-space">
                    <input name="username" class="fill-space" type="text" autocomplete="username" [(ngModel)]="email" (keydown.enter)="sendPasswordResetEmail()" />
                </div>
            </div>
        </form>
        <div class="success column center" *ngIf="!isLoading && isEmailSent">
            <div class="success-icon"> <img src="assets/images/tick-green.svg"/> </div>
            <div class="success-title">Password Reset Email Sent.</div>
            <div class="success-text">An email to reset your password has been sent to {{email}}</div>
        </div>
        <div id="login-error" class="form-error" *ngIf="error">{{ error }}</div>

        <div id="signup-button" *ngIf="!isLoading && !isEmailSent" class="fill-space" (click)="sendPasswordResetEmail()">
            Request Password Reset
        </div>

        <div id="login-link" class="center fill-space" routerLink="/login"> Back to Sign In Page </div>


    </div>
    <loader *ngIf="isLoading"></loader>
</div>
