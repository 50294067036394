import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Observable, startWith } from 'rxjs';
import { IUserInOrganization } from '../../../../../model/IUser';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationUsersService } from '../../services/organization-users.service';
import { ActivatedRoute } from '@angular/router';
import { StoreService } from '../../services/store.service';
import { UserFormComponent } from './user-form/user-form.component';
import {InvitationCreateComponent} from "./invitation-create/invitation-create.component";

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent {
  searchControl = new FormControl();
  filteredUsers$: Observable<IUserInOrganization[]>;

  constructor(
    public dialog: MatDialog,
    private organizationUsersService: OrganizationUsersService,
    private route: ActivatedRoute,
    private store: StoreService,
  ) {
    const searchFilter$: Observable<string> = this.searchControl.valueChanges.pipe(startWith(''), debounceTime(300), distinctUntilChanged());
    const allUsersInOrganization$ = this.organizationUsersService.getResolvedOrganizationUsers(this.store.organizationId!);

    this.filteredUsers$ = this.organizationUsersService.filterResolvedOrganizationUsers(allUsersInOrganization$, searchFilter$);
  }

  showCreateUser(): void {
    let dialog = this.dialog.open(InvitationCreateComponent, {});
  }
}
