<div id="upload-button" class="row center" (click)="showUpload()">
  <div id="upload-button-icon" class="column center">
    <img src="assets/images/upload-white.svg" />
  </div>
  <div id="upload-button-label" class="column center">Import CVs</div>

  <ng-container *ngIf="uploadService.resumes$ | async as resumes">
    <div class="upload-button-processing" *ngIf="resumes.length">
      <div class="upload-button-divider column center"></div>
      <div class="upload-button-count center">{{resumes.length}} Processing</div>
      <div class="upload-button-image center"> <img src="assets/images/gear-light.svg"/>  </div>
    </div>
  </ng-container>
</div>
