import { Component, Input } from '@angular/core';

@Component({
  selector: 'empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.css'],
})
export class EmptyListComponent {
  @Input() text: string = '';
}
