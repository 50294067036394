<div class="resume-content fill-space column" *ngIf="content">

  <div class="header fill-space" (click)="showContent = !showContent">
    <div class="header-icon  fill-space"> <img src="assets/images/hirefox-resume-content.svg"/> </div>
    <div class="header-arrow center"> <img src="assets/images/arrow-down.svg"/> </div>
  </div>

  <div class="resume-content-content row fill-space" *ngIf="showContent">

    <div class="left-side column fill-space-equal space-right-1">
      <resume-content-experience [experience]="content.experience"></resume-content-experience>
      <resume-content-education  [education]="content.education"></resume-content-education>
      <resume-content-languages  [languages]="content.languages"></resume-content-languages>
      <resume-content-licenses-and-certifications  [licensesAndCertifications]="content.licensesAndCertifications"></resume-content-licenses-and-certifications>
      <resume-content-awards-and-achievements  [awardsAndAchievements]="content.awardsAndAchievements"></resume-content-awards-and-achievements>
      <resume-content-projects  [projects]="content.projects"></resume-content-projects>
      <resume-content-publications  [publications]="content.publications"></resume-content-publications>
      <resume-content-presentations-and-conferences  [presentationsAndConferences]="content.presentationsAndConferences"></resume-content-presentations-and-conferences>
      <resume-content-references  [references]="content.references"></resume-content-references>
      <resume-content-hobbies-and-interests  [hobbiesAndInterests]="content.hobbiesAndInterests"></resume-content-hobbies-and-interests>
    </div>
    <div class="right-side column fill-space-equal">
      <resume-content-skills [skills]="content.skills"></resume-content-skills>
      <resume-content-links  [links]="content.links"></resume-content-links>
      <resume-content-about  [about]="content.about"></resume-content-about>
    </div>

  </div>

</div>
