import {Component, Input} from '@angular/core';
import {IApplicant} from "../../../../../../../../../../../model/IApplicant";
import {Observable} from "rxjs";
import {ICategoryDefinition} from "../../../../../../../../model/IApplicantGroup";
import {StorageApi} from "../../../../../../../../api/storage.api";

@Component({
  selector: 'applicant-evaluation-info',
  templateUrl: './applicant-evaluation-info.component.html',
  styleUrls: ['./applicant-evaluation-info.component.css']
})
export class ApplicantEvaluationInfoComponent {

  @Input() applicant$: Observable<IApplicant|undefined> | undefined;
  @Input() category$: Observable<ICategoryDefinition | null> | undefined;

  constructor(private storageApi: StorageApi) {
  }

  openFile(path: string) {
    this.storageApi
    .getFileUrl(path)
    .then((fileUrl) => {
      window.open(fileUrl, "_blank");
    })
    .catch((error) => {
      console.log("Error Getting File Url: " + error);
    })
    .finally(() => {});
  }

}
