<div class="analysis-section column">
    <div class="analysis-section-title"> {{ title }} </div>
    <div class="analysis-section-content column">
        <div class="analysis-section-empty" *ngIf="!requirements || !requirements.length"> No Requirements </div>
        <div class="analysis-list-item" *ngFor="let requirement of requirements">
            <div class="analysis-list-item-icon">
                <img  *ngIf="!requirement.isOptional" src="assets/images/dot-purple.svg"/>
                <img  *ngIf="requirement.isOptional"  src="assets/images/dot-green.svg"/>
            </div>
            <div class="analysis-list-item-text"> {{ requirement.description }} </div>
            <div class="analysis-list-item-optional" *ngIf="requirement.isOptional"> [Optional] </div>
        </div>
    </div>
</div>
