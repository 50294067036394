import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EPipelineStage} from "../../../../../../../../../model/enums/EPipelineStage";
import {ApplicantsApi} from "../../../../../../api/applicants.api";
import {Observable, take, tap} from "rxjs";
import {IApplicant} from "../../../../../../../../../model/IApplicant";
import {CdkDragDrop} from "@angular/cdk/drag-drop";
import {doc} from "@angular/fire/firestore";
import {PipelineService} from "../../../../../../services/pipeline.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'pipeline-stage',
  templateUrl: './pipeline-stage.component.html',
  styleUrls: ['./pipeline-stage.component.css']
})
export class PipelineStageComponent implements OnInit, OnDestroy {

  @Input() jobId: string | undefined;
  @Input() stage: EPipelineStage | undefined;
  applicants$: Observable<IApplicant[]> | undefined;
  applicants: IApplicant[] | undefined;
  placeholderHeight: string = "5em";
  isInitialized: boolean = false;

  constructor(private applicantsApi: ApplicantsApi, private pipelineService: PipelineService, private router: Router, private route: ActivatedRoute) {
  }

  showApplicantInfo(applicantId: string){
      this.pipelineService.isInfoVisible = true;
      this.router.navigate([applicantId], { relativeTo: this.route });
  }

  // Resized the dragNdrop placeholder height
  cdkDragStarted(event: any) {
      this.placeholderHeight = event.source.element.nativeElement.offsetHeight;
  }

  /*// Executed when an element is dropped somewhere
  async onApplicantDropped($event: CdkDragDrop<EPipelineStage>){
      console.log($event.previousIndex)
      console.log($event.currentIndex)
      let applicant: IApplicant = $event.item.data;
      let newPipelineStage: EPipelineStage = $event.container.data;
      let newPipelineStageIndex = $event.currentIndex;
        console.log(this.applicants);
      // If the pipeline stage from the event is different, update the applicants pipeline stage
      if(applicant && newPipelineStage && applicant.pipelineStage != newPipelineStage) {
          console.log("Moving "+applicant!.info!.name+" to pipeline: "+newPipelineStage);
          await this.changePipelineStage(applicant, newPipelineStage);
      }
      // Update the applicant's index
      console.log("Updating index for "+applicant!.info!.name+" to: "+newPipelineStageIndex);
      await this.applicantsApi.update(applicant.id, { pipelineStageIndex: newPipelineStageIndex });
      // Update all other applicants index below the applicant
      console.log("Updating the rest of the list below position "+ newPipelineStageIndex)
      await this.updateApplicantsIndex(applicant, this.applicants, newPipelineStageIndex);
  }*/

    // This will be executed on the component that the item is dropped on.
    // (this.applicants refers on the list the component is dropped on. Not the one it comes from)
    async onApplicantDropped($event: CdkDragDrop<EPipelineStage>) {

        let applicant: IApplicant = $event.item.data.applicant;
        let oldApplicantsList: IApplicant[] = $event.item.data.applicants;
        let newPipelineStage: EPipelineStage = $event.container.data;
        let oldPipelineStageIndex = $event.previousIndex;
        let newPipelineStageIndex = $event.currentIndex;

        // If it's dropped on the same position do nothing
        if( (oldPipelineStageIndex === newPipelineStageIndex) && (applicant.pipelineStage === newPipelineStage) ) { return; }

        // Remove the applicant from the old list and add them to the new one
        this.removeApplicant(oldApplicantsList, oldPipelineStageIndex);
        this.addApplicant(this.applicants, applicant, newPipelineStageIndex);

        //Case: 1. The item is dropped on the same list
        if(applicant.pipelineStage === newPipelineStage) {
            this.reIndexApplicantsList(this.applicants); // give the right index to each element in the array
            await this.updateApplicantsIndex(this.applicants); // Update the data model
        } else {
            applicant.pipelineStage = newPipelineStage;
            this.reIndexApplicantsList(oldApplicantsList);
            this.reIndexApplicantsList(this.applicants);
            await this.updateApplicantsIndex(oldApplicantsList);
            await this.updateApplicantsIndex(this.applicants);
            await this.updatePipelineStage(applicant, newPipelineStage);
        }

    }

  async updateApplicantsIndex(applicants: IApplicant[] | undefined) {
      if(!applicants || !applicants.length) { return; }
      const pipelineStageIndexes = applicants.map(applicant => ({ pipelineStageIndex: applicant.pipelineStageIndex }));
      try {
          await this.applicantsApi.updateBulkArray(applicants, pipelineStageIndexes);
      } catch (error) {
          console.log(error);
      }

  }

  async updatePipelineStage(applicant: IApplicant, newPipelineStage: EPipelineStage){
      try {
          await this.applicantsApi.update(applicant.id, { pipelineStage: newPipelineStage });
      } catch (error) {
          console.log(error);
      }
  }

  addApplicant(applicants: IApplicant[] | undefined, applicant: IApplicant, index: number) {
    if(!applicants) { return; }
    applicants.splice(index, 0, applicant);
  }

  removeApplicant(applicants: IApplicant[] | undefined, index: number) {
      if(!applicants || !applicants.length) { return; }
      const [removedElement] = applicants.splice(index, 1);
  }


  // Sorts the applicants list based on pipelineStageIndex ASC and places all null values at the end of the array.
  sortApplicantsList(applicants: IApplicant[] | undefined) {
      if(!applicants || !applicants.length) { return; }
      applicants.sort((a, b) => {
          // Check if either property is null, and order those with nulls at the end
          if (a.pipelineStageIndex === null) return 1; // a is considered greater if null
          if (b.pipelineStageIndex === null) return -1; // b is considered greater if null

          // Normal ascending sort for non-null values
          return a.pipelineStageIndex - b.pipelineStageIndex;
      });
  }
  // Considering that we have the list sorted the way we want, this will assign the correct pipelineStageIndex to each applicant
  reIndexApplicantsList(applicants: IApplicant[] | undefined) {
      if(!applicants) { return; }
      applicants.forEach( (applicant, index) => {
          applicant.pipelineStageIndex = index;
      })
  }

  isSameArray(arr1: IApplicant[], arr2: IApplicant[]): boolean {
    // Check if both arrays have the same length
    if (arr1.length !== arr2.length) {
        return false;
    }
    // Create a set of IDs for the first array
    const ids1 = new Set(arr1.map(applicant => applicant.id));
    // Check if every id from arr1 is present in arr2
    return arr2.every(applicant => ids1.has(applicant.id));
  }

  ngOnInit() {
    if(this.jobId && this.stage) {
       this.applicants$ =  this.applicantsApi.getAll([
           { field: 'jobId', condition: '==', value: this.jobId },
           { field: 'pipelineStage', condition: '==', value: this.stage }
       ], [
           { field: 'pipelineStageIndex', order: 'asc' }
       ]).pipe(
           tap(applicants => {
               if(!this.isInitialized) {
                   for (let i = 0; i < applicants.length; i++) {
                       if (applicants[i].pipelineStageIndex === 0) {
                           this.applicantsApi.update(applicants[i].id, {pipelineStageIndex: i});
                       }
                   }
                   this.isInitialized = true;
               }
           })
       );
       this.applicants$.subscribe( applicants => {
           if(!this.applicants || !this.isSameArray(this.applicants, applicants)) {
               this.applicants = applicants;
               this.sortApplicantsList(this.applicants);
           }
       })
    }
  }

  ngOnDestroy() {
  }
}
