import { Component, OnInit } from "@angular/core";
import { OrganizationsApi } from "../../../api/organizations.api";
import { filter, map, Observable, of, switchMap, takeUntil } from "rxjs";
import { IOrganization } from "../../../../../../model/IOrganization";
import { StoreService } from "../../../services/store.service";
import { MatDialog } from "@angular/material/dialog";
import { OrganizationsComponent } from "../../organizations/organizations.component";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "organization",
  templateUrl: "./organization.component.html",
  styleUrls: ["./organization.component.css", "../banner.component.css"],
})
export class OrganizationComponent implements OnInit {
  organization$: Observable<IOrganization | null | undefined> | undefined;

  constructor(
    private store: StoreService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private organizationApi: OrganizationsApi,
  ) {}

  showOrganizationsPopup(): void {
    let dialog = this.dialog.open(OrganizationsComponent, {});
  }

  ngOnInit() {
    this.organization$ = this.route.params.pipe(
      switchMap((params) => {
        const organizationId = params["organizationId"];
        return organizationId
          ? this.organizationApi.getOne(organizationId)
          : of(undefined);
      }),
    );
  }
}
