import {Component, Input, OnInit} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { StorageApi } from "../../../api/storage.api";
import { ResumesApi } from "../../../api/resumes.api";
import {IResume} from "../../../../../../model/IResume";
import {environment} from "../../../../environments/environment";
import {FiltersService} from "../../../services/filters/filters.service";

@Component({
  selector: "resume-delete",
  templateUrl: "./resume-delete.component.html",
  styleUrls: ["./resume-delete.component.css"],
})
export class ResumeDeleteComponent implements OnInit {
  @Input() resume: IResume | null | undefined;
  resumeFilePath: string | null | undefined;
  resumeImagePath: string | null | undefined;
  isLoading: boolean = false;

  constructor(
    private storageApi: StorageApi,
    private resumesApi: ResumesApi,
    private filtersService: FiltersService,
    private dialogRef: MatDialogRef<ResumeDeleteComponent>,
  ) {}

  async deleteResume() {
    if (!this.resume || !this.resume.id || !this.resumeFilePath) {
      return;
    }
    try {
      this.isLoading = true;
      await this.storageApi.deleteFile(this.resumeFilePath);
      if(this.resumeImagePath) {
        await this.storageApi.deleteFile(this.resumeImagePath);
      }
      await this.resumesApi.delete(this.resume.id);
      this.dialogRef.close();
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  }

  ngOnInit() {
    if(this.resume) {
      this.resumeFilePath = this.storageApi.getResumeStoragePath() + this.resume.id + '.' + this.resume.file.type;
      if(this.resume.image && this.resume.image.path) {
        this.resumeImagePath = this.storageApi.getImageStoragePath() + this.resume.id + '.png';
        }
    }
  }
}
