<div class="popup column" *ngIf="type">
  <div class="popup-header column">
    <div class="row">
      <div class="popup-header-title fill-space"> {{ type }} Levels</div>
      <div class="popup-header-close" mat-dialog-close>
        <img src="assets/images/close.svg" />
      </div>
    </div>
    <div class="levels-description"> {{ descriptions[type] }}</div>
  </div>

  <div class="popup-content column">
    <div class="levels column" >
      <div class="column level" *ngFor="let l of levels[type]; index as i">
        <div class="level-title"> {{i+1}}.{{l.title}}</div>
        <div class="level-description"> {{l.description}}</div>
      </div>
    </div>
    <div class="error" *ngIf="!type"> No Info Found! </div>
  </div>

  <div class="popup-buttons row">
    <div class="fill-space"></div>
    <div class="button button-gray" mat-dialog-close>Ok</div>
    <div class="fill-space"></div>
  </div>
</div>
