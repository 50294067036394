<div class="resume-content-section column fill-space">

  <div class="resume-content-section-header fill-space">
    <div class="resume-content-section-header-icon center"> <img src="assets/images/section-experience.svg"/> </div>
    <div class="resume-content-section-header-title center"> Experience </div>
  </div>

  <div class="column fill-space">

    <empty-list text="No Experience Record" *ngIf="!experience || !experience.length"></empty-list>

    <ng-container *ngIf="experience">
      <div class="resume-node column" *ngFor="let e of experience">
        <div class="resume-node-header">
          <div class="resume-node-icon"> <img src="assets/images/resume-list-experience.svg"/> </div>
          <div class="resume-node-header-content column">
            <div class="resume-node-title capitalize"> {{e.position ? e.position.toLowerCase() : 'Unknown Position' }}</div>
            <div class="capitalize" [class]="{'resume-node-subtitle': e.company, 'empty-info': !e.company}">
              {{ e.company ? e.company.toLowerCase() : 'No Company info' }}
            </div>
          </div>
        </div>
        <div class="resume-node-content column">
          <div class="resume-node-date">
            <div class="resume-node-date-icon center"> <img src="assets/images/calendar.svg"/> </div>
            <div class="resume-node-date-text gap-1 row-center-left">
              <div class="resume-node-info-data" > Period: {{ e.period ? e.period : 'Unknown' }} </div>
              <div class="resume-node-info-data" *ngIf="e.tenure"> ({{ e?.tenure }}) </div>
            </div>
          </div>

          <div class="resume-node-description" [innerHTML]="e?.description | newlineToBr"> </div>
        </div>
      </div>
    </ng-container>

  </div>


</div>
