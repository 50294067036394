<div class="resume-content-section column fill-space">

  <div class="resume-content-section-header fill-space">
    <div class="resume-content-section-header-icon center"> <img src="assets/images/section-awards.svg"/> </div>
    <div class="resume-content-section-header-title center"> Awards & Achievements </div>
  </div>

  <div class="resume-content-section-data column fill-space">

    <empty-list text="No Awards/Achievements" *ngIf="!awardsAndAchievements || !awardsAndAchievements.length"></empty-list>

    <ng-container *ngIf="awardsAndAchievements">
      <div class="resume-node column" *ngFor="let aw of awardsAndAchievements">

        <div class="resume-node-header">
          <div class="resume-node-icon"> <img src="assets/images/resume-list-awards.svg"/> </div>
          <div class="resume-node-header-content column">
            <div class="resume-node-title capitalize"> {{ aw.name }} </div>
            <div class="capitalize" [class]="{'resume-node-subtitle': aw.issuer, 'empty-info': !aw.issuer}">
              {{ aw.issuer ? aw.issuer.toLowerCase() : 'Unknown Issuer' }}
            </div>
          </div>
        </div>
        <div class="resume-node-content column">
          <div class="resume-node-date">
            <div class="resume-node-date-icon center"> <img src="assets/images/calendar.svg"/> </div>
            <div class="resume-node-date-text gap-1 row-center-left">
              <div class="resume-node-info-data" > Award Date: {{ aw.dateAwarded  ? aw.dateAwarded : 'Unknown' }} </div>
            </div>
          </div>
          <div class="resume-node-description" *ngIf="aw.description">  {{ aw.description }}</div>
        </div>

      </div>
    </ng-container>

  </div>

</div>
