<div class="resume-info" *ngIf="info">
  <div class="resume-info-photo center">
    <resume-image [image]="image"></resume-image>
  </div>
  <div class="resume-info-content column fill-space">
    <div class="resume-info-content-name"> {{ info.name }} </div>
    <div class="resume-info-content-role">
      <div class="resume-info-content-role-position"> {{ info.lastJobPosition?.toLowerCase() }} </div>
      <div class="resume-info-content-role-no-data" *ngIf="!info.lastJobPosition"> No Previous Work Experience </div>
      <div class="resume-info-content-role-dot center"> <img src="assets/images/dot-gray.svg" /> </div>
      <div class="resume-info-content-role-company"> {{ info.lastJobCompany?.toLowerCase() }} </div>
      <div class="resume-info-content-role-no-data" *ngIf="!info.lastJobCompany"> No Company Info</div>
    </div>
    <div class="resume-info-contact-info" *ngIf="info.contactInfo">
      <div class="resume-info-contact-info-item">
        <div class="resume-info-contact-info-item-icon center"> <img src="assets/images/map-pin.svg" /> </div>
        <div class="resume-info-contact-info-item-value center" *ngIf="info.contactInfo.address && info.contactInfo.address.trim() != ''"> {{ info.contactInfo.address }} </div>
        <div class="resume-info-contact-info-item-value center" *ngIf="!info.contactInfo.address || info.contactInfo.address.trim() == ''"> N/A </div>
      </div>
      <div class="resume-info-contact-info-item">
        <div class="resume-info-contact-info-item-icon center"> <img src="assets/images/email.svg" /> </div>
        <div class="resume-info-contact-info-item-value center">
          <ng-container *ngIf="!info.contactInfo.emails?.length"> N/A </ng-container>
          <ng-container *ngFor="let email of info.contactInfo.emails">
            {{ email }}
          </ng-container>
        </div>
      </div>
      <div class="resume-info-contact-info-item">
        <div class="resume-info-contact-info-item-icon center"> <img src="assets/images/phone.svg" /> </div>
        <div class="resume-info-contact-info-item-value center">
          <ng-container *ngIf="!info.contactInfo.phoneNumbers?.length"> N/A </ng-container>
          <ng-container *ngFor="let phoneNumber of info.contactInfo.phoneNumbers">
            {{ phoneNumber ?? "N/A" }}
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="resume-info-score"></div>
</div>
