import {Component, Input} from '@angular/core';
import {IApplicantNote} from "../../../../../../../../../../../model/IApplicant";
import {EApplicantNoteType} from "../../../../../../../../../../../model/enums/EApplicantNoteType";
import {MatDialog} from "@angular/material/dialog";
import {StoreService} from "../../../../../../../../services/store.service";
import {JobDeleteComponent} from "../../../../../../job-delete/job-delete.component";
import {ApplicantNoteDeleteComponent} from "../applicant-note-delete/applicant-note-delete.component";

@Component({
  selector: 'applicant-note',
  templateUrl: './applicant-note.component.html',
  styleUrls: ['./applicant-note.component.css']
})
export class ApplicantNoteComponent {

  @Input() note: IApplicantNote | undefined;
  @Input() applicantId: string | null | undefined;

  constructor(private dialog: MatDialog, public store: StoreService) {
  }

  showDeleteNote() {
    let dialog = this.dialog.open(ApplicantNoteDeleteComponent, {});
    dialog.componentInstance.note = this.note;
    dialog.componentInstance.applicantId = this.applicantId;
  }

  protected readonly EApplicantNoteType = EApplicantNoteType;
}
