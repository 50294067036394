import { Component } from "@angular/core";
import { UserWithOrganizationsService } from "../../services/user-with-organizations.service";
import {
  combineLatest,
  combineLatestAll,
  concat,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  take,
  tap,
} from "rxjs";
import { Router } from "@angular/router";
import { IUserWithRoles } from "../../../../../model/IUser";
import { OrganizationsApi } from "../../api/organizations.api";

@Component({
  selector: "entry",
  templateUrl: "./entry.component.html",
  styleUrls: ["./entry.component.css"],
})
export class EntryComponent {
  private userFavoriteOrganizations$: Observable<string | undefined>;
  private userWithResolvedOrganizationsWithRole$: Observable<IUserWithRoles>;
  userFavoriteOrganizationsSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private userWithOrganizationService: UserWithOrganizationsService,
  ) {
    this.userFavoriteOrganizations$ =
      this.userWithOrganizationService.userFavoriteOrganizations$;
    this.userWithResolvedOrganizationsWithRole$ =
      this.userWithOrganizationService.userWithResolvedOrganizationsWithRole$;
  }

  ngOnInit() {
    combineLatest([
      this.userFavoriteOrganizations$,
      this.userWithResolvedOrganizationsWithRole$,
    ])
      .pipe(
        take(1),
        map(([userFavoriteOrganization, user]) => {
          if (!!userFavoriteOrganization) {
            return `/organization/${userFavoriteOrganization}`;
          }
          if (user?.resolvedOrganizationsWithRole?.length === 1) {
            return `/organization/${
              user!.resolvedOrganizationsWithRole[0]!.id
            }`;
          }
          if (user?.resolvedOrganizationsWithRole?.length > 1) {
            return `user-organizations`;
          }
          return `user-organizations`;
        }),
      )
      .subscribe((path) => {
        void this.router.navigate([path]);
      });
  }

  noOnDestroy() {
    this.userFavoriteOrganizationsSubscription?.unsubscribe();
  }
}
