import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'job-portal-description-view',
  templateUrl: './job-portal-description-view.component.html',
  styleUrls: ['./job-portal-description-view.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class JobPortalDescriptionViewComponent {
  @Input() description: string | undefined;
}
