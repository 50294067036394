<div class="resume-content-section column fill-space">

  <div class="resume-content-section-header fill-space">
    <div class="resume-content-section-header-icon center"> <img src="assets/images/section-licences.svg"/> </div>
    <div class="resume-content-section-header-title center"> Licenses &amp; Certifications </div>
  </div>

  <div class="resume-content-section-data column fill-space">

    <empty-list text="No Licences/Certifications" *ngIf="!licensesAndCertifications?.length"></empty-list>

    <ng-container *ngIf="licensesAndCertifications">
      <div class="resume-node column" *ngFor="let lc of licensesAndCertifications">

        <div class="resume-node-header">
          <div class="resume-node-icon"> <img src="assets/images/resume-list-licences.svg"/> </div>
          <div class="resume-node-header-content column">
            <div class="resume-node-title capitalize"> {{ lc.name }}</div>
            <div class="capitalize" [class]="{'resume-node-subtitle': lc.issuingOrganization, 'empty-info': !lc.issuingOrganization}">
              {{ lc.issuingOrganization ? lc.issuingOrganization : 'Unknown Organization' }}
            </div>
          </div>
        </div>

        <div class="resume-node-content column">
          <div class="resume-node-date">
            <div class="resume-node-date-icon center"> <img src="assets/images/calendar.svg"/> </div>
            <div class="resume-node-date-text gap-1 row-center-left">
              <div class="resume-node-info-data"> Issue Date: {{ lc.issueDate ? lc.issueDate : 'Unknown' }} </div>
              <div class="resume-node-info-data"> Expiration Date: {{ lc.expirationDate ? lc.expirationDate : 'Unknown' }} </div>
            </div>
          </div>
        </div>

      </div>
    </ng-container>

  </div>


</div>
