<div class="resume-panel" *ngIf="resume" [class]="{'resume-panel-selected': selectedResumeId && selectedResumeId === resume.id }">
  <ng-container *ngIf="resume.status === EStatus.COMPLETED">
    <ng-container *ngIf="resume.resumeAnalysis?.info as info">
      <div class="resume-panel-image center">
        <resume-image [image]="resume.image"></resume-image>
      </div>
      <div class="resume-panel-content fill-space column">

        <div>
          <div class="column fill-space column-center-left">
            <div class="resume-panel-content-name row-center-left"> {{ info.name }}</div>
          </div>
          <div class="column center" *ngIf="isNew(resume.creationTime?.toDate())"> <div class="resume-panel-content-new"> New </div> </div>
        </div>
        <div class="resume-panel-content-job row">
          <div class="resume-panel-content-job-position empty-info center" *ngIf="!info.lastJobPosition"> No Previous Work Experience </div>
          <div class="resume-panel-content-job-position center" *ngIf="info.lastJobPosition"> {{ info.lastJobPosition.toLowerCase() }} </div>
          <div class="resume-panel-content-job-dot center"> <img src="assets/images/dot-gray.svg" /> </div>
          <div class="resume-panel-content-job-company empty-info center" *ngIf="!info.lastJobCompany"> No Company Info </div>
          <div class="resume-panel-content-job-company center" *ngIf="info.lastJobCompany"> {{ info.lastJobCompany.toLowerCase() }} </div>
        </div>
        <div class="resume-panel-content-education" *ngIf="info.educationInstitution"> {{ info.educationInstitution.toLowerCase() }} </div>
        <div class="resume-panel-content-skills">
          <div class="resume-panel-skill center" *ngFor="let s of resume.resumeAnalysis?.overview?.topSkills?.slice(0, 3)"> {{ s }}  </div>
        </div>
      </div>
      <div class="resume-panel-arrow center"> <img src="assets/images/arrow-right.svg" /> </div>
    </ng-container>
  </ng-container>

</div>
