<div class="match-section column">
  <job-match-section
          title="Education"
          [score]="score"
          [scoreJustification]="scoreJustification"
          [justifications]="justifications"
          [matched]="matched"
          [unMatched]="unMatched">
  </job-match-section>
</div>
