<div class="popup column">
  <div class="popup-header row">
    <div class="popup-header-title fill-space">Create New Company</div>
    <div class="popup-header-close" mat-dialog-close>
      <img src="assets/images/close.svg" />
    </div>
  </div>

  <div class="popup-loader row center" *ngIf="isLoading">
    <loader></loader>
  </div>

  <div class="popup-content column" *ngIf="!isLoading">
    <div class="form-field column space-bottom-1">
      <div class="form-field-label">Name</div>
      <div class="form-field-input">
        <input class="fill-space" type="text" [(ngModel)]="companyName" />
      </div>
    </div>
  </div>

  <div class="popup-buttons row" *ngIf="!isLoading">
    <div class="button button-gray" mat-dialog-close>Cancel</div>
    <div class="fill-space"></div>
    <div class="button button-action" (click)="createCompany()">
      Create Company
    </div>
  </div>
</div>
