<div id="settings" class="page-column column fill-space overflow-hidden">
  <div class="header row">
    <div class="fill-space column">
      <div class="title">Settings</div>
    </div>
  </div>

    <div class="settings-content column scrollbar">

        <div class="settings-section column">
            <div class="settings-section-title"> Account </div>
            <div class="settings-section-content">
              <settings-account></settings-account>
            </div>
        </div>
        <div class="settings-section column">
            <div class="settings-section-title"> Company </div>
            <settings-organization></settings-organization>
        </div>
       <!-- <div class="settings-section column">
            <div class="settings-section-title"> Subscription </div>
            <settings-subscription></settings-subscription>
        </div>-->

    <div>
      <div
        id="settings-menu-logout"
        class="row row-center-left"
        (click)="signOut()"
      >
        <div id="settings-menu-logout-icon" class="space-right-1">
          <img src="assets/images/logout.svg" />
        </div>
        <div id="settings-menu-logout-text">Logout</div>
      </div>
    </div>
  </div>
</div>
