import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { IResume } from "../../../../../model/IResume";
import { ResumesApi } from "../../api/resumes.api";
import { FormControl } from "@angular/forms";

@Component({
  selector: "resume-selector",
  templateUrl: "./resume-selector.component.html",
  styleUrls: ["./resume-selector.component.css"],
})
export class ResumeSelectorComponent implements OnInit, OnDestroy {
  @Input() title: string = "Add Applicants To Job";
  @Input() actionLabel: string = "Add Selected Applicants To Job";
  selectedIds: string[] = [];
  resumes$: Observable<IResume[]> | undefined;
  searchControl = new FormControl();

  constructor(
    private dialogRef: MatDialogRef<ResumeSelectorComponent>,
    private resumesApi: ResumesApi,
  ) {}

  returnSelectedResumes(): void {
    this.dialogRef.close(this.selectedIds);
  }

  selectResume(id: string) {
    const index = this.selectedIds.indexOf(id);
    if (index != -1) {
      this.selectedIds.splice(index, 1);
      return;
    }
    this.selectedIds.push(id);
  }

  isSelected(id: string) {
    return this.selectedIds.includes(id);
  }

  ngOnInit() {
    this.resumes$ = this.resumesApi.getAll();
  }
  ngOnDestroy() {}
}
