import { Injectable } from "@angular/core";
import {
  addDoc,
  collection,
  doc,
  docData,
  Firestore,
  setDoc,
} from "@angular/fire/firestore";
import { IUser } from "../../../../model/IUser";
import { FirebaseCollectionNames } from "../../../../model/FirebaseCollectionNames";
import { Api } from "./api";
import { IJob } from "../../../../model/IJob";

@Injectable({
  providedIn: "root",
})
export class UsersApi extends Api<IUser> {
  constructor(private firestore: Firestore) {
    super();
  }

  create(job: Omit<IJob, "id">) {
    let reference = collection(this.firestore, this.getPath());
    return addDoc(reference, job);
  }

  set(id: string, item: Partial<IUser>): Promise<void> {
    const reference = doc(this.firestore, this.getPath(), id);
    return setDoc(reference, item, { merge: true });
  }

  getOne(id: string) {
    const reference = doc(this.firestore, this.getPath(), id);
    return docData<IUser>(reference as any, this.options);
  }

  private getPath() {
    return FirebaseCollectionNames.USERS;
  }
}
