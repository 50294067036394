<div class="popup column">

    <div class="popup-header column">
        <div class="row">
            <div class="popup-header-title fill-space"> Add Applicants To Job </div>
            <div class="popup-header-close" mat-dialog-close>
                <img src="assets/images/close.svg" />
            </div>
        </div>
    </div>

    <div class="popup-loader row center" *ngIf="isLoading">
        <loader></loader>
    </div>

    <ng-container *ngIf="!isLoading">
    <div class="popup-content  overflow-hidden" >

      <div id="filters" class="page-column column overflow-hidden">
        <div class="header row">
          <div class="fill-space column">
            <div class="title">Filters</div>
          </div>
        </div>
        <div class="filters-content column scrollbar">
          <ng-container *ngFor="let f of filtersService.resumeFilters">
            <filter-panel
                [filter]="f"
                (updateSelectedOptions)="filtersService.updateSelectedOptions($event)">
            </filter-panel>
          </ng-container>
        </div>
      </div>

      <div id="applicants-content" class="column">
        <div class="space-top-bottom-1">
          <div class="search fill-space row space-bottom-1">
            <div class="search-icon"><img src="assets/images/search.svg" /></div>
            <div class="search-input fill-space">
              <input #searchInput type="text" placeholder="Enter search keyword and press enter..." (keydown.enter)="addSearchTag(searchInput)" class="fill-space" />
            </div>
          </div>
          <div class="refresh center"  (click)="refresh()"> <img src="assets/images/refresh.svg"/> </div>
        </div>

        <div class="filter-tags row row-center-left wrap">
          <ng-container *ngFor="let f of filtersService.resumeFilters">
            <tag *ngFor="let tag of f.selectedOptions" [text]="tag" [type]="f.name" (click)="filtersService.removeTag(f, tag)"></tag>
          </ng-container>
          <tag *ngFor="let tag of searchTags" [text]="tag" [type]="'Custom Search'" [className]="'custom'" (click)="removeSearchTag(tag)"></tag>
        </div>

          <div class="resumes scrollbar column" *ngIf="resumes$ | async as resumes; else loading">
              <resume-selector-panel
                      *ngFor="let resume of resumes"
                      [resume]="resume"
                      [isSelected]="isSelected(resume.id)"
                      (click)="selectResume(resume)">
              </resume-selector-panel>
          </div>

      </div>
    </div>


    <div class="popup-buttons row">
        <div class="button button-gray" mat-dialog-close> Cancel </div>
        <div class="fill-space"></div>
        <div class="button button-action" (click)="addResumesToJob()"> Add Selected Applicants To Job </div>
    </div>
    </ng-container>
</div>

<ng-template #loading>
  <div class="empty column center">
    <loader></loader>
  </div>
</ng-template>
