import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PipelineService {

  isInfoVisible: boolean = false;

  constructor() { }
}
