<div class="pipeline-stage column fill-space overflow-hidden" *ngIf="stage">
  <div class="pipeline-stage-title"> {{stage}} </div>

  <div class="pipeline-stage-content fill-space column scrollbar"
       *ngIf="applicants"
       cdkDropList
       [cdkDropListData]="stage"
       (cdkDropListDropped)="onApplicantDropped($event)">
    <pipeline-stage-applicant
        *ngFor="let a of applicants"
        [applicant]="a"
        (click)="showApplicantInfo(a.id)"
        cdkDrag
        [cdkDragData]="{ applicant: a, applicants: applicants }"
        (cdkDragStarted)="cdkDragStarted($event)" >
      <div [ngStyle]="{'height.px': placeholderHeight  }" class="placeholder" *cdkDragPlaceholder></div>
    </pipeline-stage-applicant>
  </div>

</div>


