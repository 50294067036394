import { Component, Input } from '@angular/core';
import { LevelInfoComponent } from '../../../../../common/level-info/level-info.component';
import { EResumeSectionType } from '../../../../../model/enum/EResumeInfoSection';
import { MatDialog } from '@angular/material/dialog';
import { IResumeOverviewCareer } from '../../../../../../../../model/IResume';
import { EResumeCareerProgression } from '../../../../../../../../model/enums/EResumeCareerProgression';
import { EResumeCareerFocus } from '../../../../../../../../model/enums/EResumeCareerFocus';

@Component({
  selector: 'resume-overview-career',
  templateUrl: './resume-overview-career.component.html',
  styleUrls: ['../resume-overview.component.css','./resume-overview-career.component.css'],
})
export class ResumeOverviewCareerComponent {
  @Input() career: IResumeOverviewCareer | undefined;

  constructor(private dialog: MatDialog) {}

  showInfo(type: EResumeSectionType): void {
    let dialog = this.dialog.open(LevelInfoComponent, {});
    dialog.componentInstance.type = type;
  }

  protected readonly EResumeSectionType = EResumeSectionType;
  protected readonly EResumeCareerProgression = EResumeCareerProgression;
  protected readonly EResumeCareerFocus = EResumeCareerFocus;
}
