import { Injectable } from "@angular/core";
import {
  collection, collectionData, deleteDoc, doc, docData, Firestore, orderBy, query, setDoc, updateDoc, where, writeBatch
} from "@angular/fire/firestore";
import { FirebaseCollectionNames } from "../../../../model/FirebaseCollectionNames";
import { StoreService } from "../services/store.service";
import { IApplicant } from "../../../../model/IApplicant";
import { Observable } from "rxjs";
import { Api } from "./api";
import {FirestoreFilter} from "../model/firestore.filter";
import {FirestoreSorting} from "../model/firestore.sorting";
import {QueryConstraint} from "@firebase/firestore";

@Injectable({
  providedIn: "root",
})
export class ApplicantsApi extends Api<IApplicant> {
  constructor(private firestore: Firestore, private store: StoreService) {
    super();
  }

  getAll(filters: FirestoreFilter[] = [], sorting: FirestoreSorting[] = []) {
    const constraints: QueryConstraint[] = [
      ...filters.map( (filter) => where(filter.field, filter.condition, filter.value) ),
      ...sorting.map( (sortCondition) => orderBy(sortCondition.field, sortCondition.order) ),
    ];
    const reference = query(collection(this.firestore, this.getPath()), ...constraints);
    return collectionData<IApplicant>(reference as any, this.options);
  }

  getOne(id: string): Observable<IApplicant | undefined> {
    const reference = doc(this.firestore, this.getPath(), id);
    return docData<IApplicant>(reference as any, this.options);
  }

  createBulk(applicants: IApplicant[]): Promise<void> {
    const batch = writeBatch(this.firestore);
    for (const applicant of applicants) {
      const reference = doc(collection(this.firestore, this.getPath()));
      batch.set(reference, applicant);
    }
    return batch.commit();
  }

  update(id: string, item: Partial<IApplicant>): Promise<void> {
    const reference = doc(this.firestore, this.getPath(), id);
    return updateDoc(reference, item);
  }

  updateBulk(applicants: IApplicant[], item: Partial<IApplicant>): Promise<void> {
    const batch = writeBatch(this.firestore);
    for (const applicant of applicants) {
      const reference = doc(this.firestore, this.getPath(), applicant.id);
      batch.update(reference, item);
    }
    return batch.commit();
  }

  // Updates each applicant with a different value in a Batch. Applicants and update values are 1-1
  updateBulkArray(applicants: IApplicant[], items: Partial<IApplicant>[]): Promise<void> {
    const batch = writeBatch(this.firestore);
    applicants.forEach((applicant, index) => {
      const reference = doc(this.firestore, this.getPath(), applicant.id);
      batch.update(reference, items[index]);
    });
    return batch.commit();
  }

  set(id: string, item: Partial<IApplicant>): Promise<void> {
    const reference = doc(this.firestore, this.getPath(), id);
    return setDoc(reference, item, { merge: true });
  }

  delete(id: string): Promise<void> {
    const reference = doc(this.firestore, this.getPath(), id);
    return deleteDoc(reference);
  }

  private getPath() {
    return (FirebaseCollectionNames.ORGANIZATIONS + "/" + this.store.organizationId! + "/" + FirebaseCollectionNames.APPLICANTS);
  }
}
