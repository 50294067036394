<div class="resume-content-section column fill-space">

    <div class="resume-content-section-header fill-space">
        <div class="resume-content-section-header-icon center"> <img src="assets/images/section-about.svg"/> </div>
        <div class="resume-content-section-header-title center"> About  </div>
    </div>

    <div class="resume-content-section-data column fill-space">

        <empty-list text="No About Section" *ngIf="!about || about.trim() === ''"></empty-list>

        <div class="about" [innerHTML]="about | newlineToBr"></div>

    </div>


</div>
