import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { JobsApi } from '../../../api/jobs.api';
import { StoreService } from '../../../services/store.service';
import { EJobStatus } from '../../../../../../model/enums/EJobStatus';
import { IJob, IJobDetails } from '../../../../../../model/IJob';
import { EStatus } from '../../../../../../model/enums/EResumeStatuses';
import { EPipelineStage } from '../../../../../../model/enums/EPipelineStage';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EEmploymentType } from '../../../../../../model/enums/EEmploymentType';
import { PortalJobsApi } from '../../../api/portalJobs.api';

@Component({
  selector: 'job-form',
  templateUrl: './job-form.component.html',
  styleUrls: ['./job-form.component.css']
})
export class JobFormComponent {
  job: Omit<IJob, 'id'> = {
    organizationId: this.store.organizationId!,
    title: '',
    description: '',
    jobStatus: EJobStatus.OPEN,
    applicantsCount: 0,
    creationTime: this.jobsApi.getServerTimestamp(),
    status: EStatus.STORED,
    pipeline: Object.values(EPipelineStage),
    userAiGuidelines: {
          scoringInstructions: "If the candidate doesn't match any of the technical skills give them a score below 50.",
          rejectReasons: "",
          isInitialized: false
    }
  };
  isLoading: boolean = false;
  isJobCreated: boolean = false; // Flag to denote a job has been created
  error: string | undefined; // Error Message
  jobId: string | null = null; // The id of the newly created job
  job$: Observable<IJob | undefined> | undefined;

  constructor(
    private dialogRef: MatDialogRef<JobFormComponent>,
    private jobsApi: JobsApi,
    private portalJobsApi: PortalJobsApi,
    private store: StoreService,
    private router: Router
  ) {}

  async createJob() {
    if (!this.job.title || !this.job.description) {
      return;
    }
    this.isLoading = true;

    try {
      const result = await this.jobsApi.create(this.job);
      this.jobId = result.id;

      this.job$ = this.jobsApi.getOne(this.jobId);
      this.job$.subscribe(async (job) => {
        if (job?.status === EStatus.COMPLETED) {
          await this.createJobPortalDetails(job);
          this.isLoading = false;
          this.isJobCreated = true;
        } else if (job?.status === EStatus.ERROR) {
          this.isLoading = false;
          this.error = job.errorMessage;
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  goToJob(jobId: string): void {
    if (!jobId) return;
    this.dialogRef.close();
    this.router.navigate(['/organization', this.store.organizationId, 'jobs', jobId]); // Cannot use relative path from within a popup in Angular Material
  }

  private async createJobPortalDetails(job: IJob) {
    const jobPortalDetails = {} as IJobDetails;

    jobPortalDetails.jobStatus = job.jobStatus;
    jobPortalDetails.isPublic = false;
    jobPortalDetails.title = job.title;
    if (job.jobAnalysis?.location) {
      jobPortalDetails.location = job.jobAnalysis.location;
    }
    if (job.jobAnalysis?.workLocationType) {
      jobPortalDetails.jobSetting = job.jobAnalysis.workLocationType;
    }
    jobPortalDetails.jobType = EEmploymentType.FULL_TIME;
    if (job.jobAnalysis?.salary) {
      jobPortalDetails.salary = job.jobAnalysis.salary;
    }
    jobPortalDetails.creationTime = this.jobsApi.getServerTimestamp();
    jobPortalDetails.description = this.job.description.replace(/(?:\r\n|\r|\n)/g, '<br/>');

    await this.portalJobsApi.set(job.id, jobPortalDetails);
  }
}
