import { Component, Input } from '@angular/core';
import {IJobRequirement} from "../../../../../../../../../model/IJob";

@Component({
  selector: 'job-description-requirements',
  templateUrl: './job-description-requirements.component.html',
  styleUrls: ['./job-description-requirements.component.css'],
})
export class JobDescriptionRequirementsComponent {
  @Input() title: string = '';
  @Input() requirements: IJobRequirement[] | null | undefined = [];
}
