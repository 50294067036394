import {Component, Input} from '@angular/core';
import {IUserInOrganization} from "../../../../../../../../../../model/IUser";
import {OrganizationUsersApi} from "../../../../../../../api/organization.users.api";
import {MatDialogRef} from "@angular/material/dialog";
import {EUserRole} from "../../../../../../../../../../model/enums/EUserRole";
import {ApplicantsApi} from "../../../../../../../api/applicants.api";
import {IApplicant} from "../../../../../../../../../../model/IApplicant";

@Component({
  selector: 'applicant-delete',
  templateUrl: './applicant-delete.component.html',
  styleUrls: ['./applicant-delete.component.css']
})
export class ApplicantDeleteComponent {

  @Input() applicant: IApplicant | undefined;
  isLoading: boolean = false;

  constructor(private applicantsApi: ApplicantsApi, private dialogRef: MatDialogRef<ApplicantDeleteComponent>) {

  }

  deleteApplicant(): void {
    if (!this.applicant || !this.applicant.id) { return; }
    this.isLoading = true;
    this.applicantsApi.delete(this.applicant.id).then(response => {
      this.dialogRef.close();
    }).catch(error=> {
      console.log(error);
    }).finally(()=>{
      this.isLoading = false;
    });
  }

}
