import {Component, OnInit} from '@angular/core';
import {Observable, of, Subject, switchMap, takeUntil} from "rxjs";
import {IResume} from "../../../../../../../../../../model/IResume";
import {ActivatedRoute} from "@angular/router";
import {ApplicantsApi} from "../../../../../../../api/applicants.api";
import {ResumesApi} from "../../../../../../../api/resumes.api";

@Component({
  selector: 'applicant-resume',
  templateUrl: './applicant-resume.component.html',
  styleUrls: ['./applicant-resume.component.css']
})
export class ApplicantResumeComponent implements OnInit {

  resume$: Observable<IResume|undefined> | undefined;
  unsubscribe$ = new Subject<void>();

  constructor(private route: ActivatedRoute, private applicantsApi: ApplicantsApi, private resumesApi: ResumesApi) {
  }

  ngOnInit() {
    this.resume$ = this.route.paramMap.pipe(
        switchMap((params) => {
          let applicantId = params.get("applicantId");
          return applicantId ? this.applicantsApi.getOne(applicantId) : of(undefined);
        }),
        switchMap((applicant) => {
          return applicant ? this.resumesApi.getOne(applicant.resumeId) : of(undefined);
        })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
