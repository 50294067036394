import { Component } from '@angular/core';
import {OrganizationsComponent} from "../../organizations/organizations.component";
import {StoreService} from "../../../services/store.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {OrganizationsApi} from "../../../api/organizations.api";
import {ProblemReportComponent} from "../../problem-report/problem-report.component";

@Component({
  selector: 'problem-report-button',
  templateUrl: './problem-report-button.component.html',
  styleUrls: ['../banner.component.css', './problem-report-button.component.css']
})
export class ProblemReportButtonComponent {

  constructor(private dialog: MatDialog) {

  }

  showProblemReport(): void {
    let dialog = this.dialog.open(ProblemReportComponent, {});
  }

}
