import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IApplicant, IMatchAnalysis} from "../../../../../../../../../../../../model/IApplicant";
import {Observable} from "rxjs";

@Component({
  selector: 'applicant-evaluation-radar',
  templateUrl: './applicant-evaluation-radar.component.html',
  styleUrls: ['./applicant-evaluation-radar.component.css']
})
export class ApplicantEvaluationRadarComponent implements OnInit, OnChanges {

  @Input() applicant$: Observable<IApplicant|undefined> | undefined;
  @Input() candidateScores: number[] = [0, 0, 0, 0, 0];
  @Input() averageScores: number[] = [0, 0, 0, 0, 0];

  isChartVisible: boolean = true;

  chart = {
    type: 'radar',
    options: {
      plugins: {
        legend: { display: false },
        tooltip: {
          intersect: false, // Displays the tooltip always. Don't need to hover the point
          padding: { top: 20, right: 20, bottom: 20, left: 20 },
          callbacks: { // This is to leave space between the square and the label
            label: function (context: any) {
              return '   ' + context.label + ":" + context.formattedValue;
            }
          }
        }
      },
      elements: {
        line: { borderWidth: 4 },
      },
      scales: {
        r: {
          angleLines: { color: '#cccccc' },
          grid: { color: '#cccccc' },
          pointLabels: {  color: '#555555',  padding: 20, font: { size: 12 }   },
          ticks: {
            stepSize: 25,
            suggestedMin: 0,
            suggestedMax: 100,
            backdropColor: 'transparent',
          },
        },
      },
      spanGaps: true
    },
    data: {
      labels: ['Experience', 'Education', 'Skills', 'Languages', 'Other'],
      datasets: [
        {
          label: 'Candidate',
          data: this.candidateScores,
          fill: true,
          backgroundColor: 'rgba(255, 118, 19, 0.7)',
          borderColor: '#FF7613',
          borderWidth: 4,

          pointRadius: 8,
          pointBackgroundColor: 'rgba(234,103,9,1)',
          pointBorderColor: 'rgba(255, 154, 82, 0.5)',
          pointBorderWidth: 8,
          pointHoverRadius: 12,
          pointHoverBackgroundColor: 'rgba(234,103,9,1)',
          pointHoverBorderColor: 'rgba(255, 154, 82, 0.5)',
          tension: 0.1,
        },
        {
          label: 'Average',
          data: this.averageScores,
          fill: true,
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderWidth: 4,
          borderColor: 'rgba(0, 0, 0, 0.12)',
          pointRadius: 6,
          pointBackgroundColor: '#cccccc',
          pointBorderColor: '#e0e0e0',
          pointHoverBackgroundColor: '#e0e0e0',
          pointHoverBorderColor: '#e0e0e0',
          tension: 0.1,
        },
      ],
    },
  };

  updateChart(candidateScores: number[], averageScores: number[]) {
    this.chart.data.datasets[0].data = candidateScores;
    this.chart.data.datasets[1].data = averageScores;
  }



  ngOnChanges(changes: SimpleChanges) {
    this.isChartVisible = false;
    this.updateChart(this.candidateScores, this.averageScores);
    setTimeout(() => this.isChartVisible = true, 0);
  }

  ngOnInit() {
    this.updateChart(this.candidateScores, this.averageScores);
  }


}
