import { Component, Input } from '@angular/core';
import { IResumeAwardAndAchievement } from '../../../../../../../../model/IResume';

@Component({
  selector: 'resume-content-awards-and-achievements',
  templateUrl: './resume-content-awards-and-achievements.component.html',
  styleUrls: ['./resume-content-awards-and-achievements.component.css'],
})
export class ResumeContentAwardsAndAchievementsComponent {
  @Input() awardsAndAchievements: IResumeAwardAndAchievement[] | null | undefined = [];
}
