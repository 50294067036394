import {Component, Input} from '@angular/core';
import {IApplicantNote, IApplicantRating} from "../../../../../../../../../../../model/IApplicant";
import {ApplicantNotesApi} from "../../../../../../../../api/applicant.notes.api";
import {MatDialogRef} from "@angular/material/dialog";
import {ApplicantRatingsApi} from "../../../../../../../../api/applicant.ratings.api";

@Component({
  selector: 'applicant-rating-delete',
  templateUrl: './applicant-rating-delete.component.html',
  styleUrls: ['./applicant-rating-delete.component.css']
})
export class ApplicantRatingDeleteComponent {

  @Input() rating: IApplicantRating | undefined;
  @Input() applicantId: string | null | undefined;
  isLoading: boolean = false;

  constructor(
      private applicantRatingsApi: ApplicantRatingsApi,
      private dialogRef: MatDialogRef<ApplicantRatingDeleteComponent>,
  ) {}

  delete(): void {
    if (!this.rating || !this.applicantId) { return; }
    this.isLoading = true;
    this.applicantRatingsApi
    .delete(this.applicantId, this.rating.id)
    .then((response) => {
      this.dialogRef.close();
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      this.isLoading = false;
    });
  }

}
