<div id="company-settings" class="column">
  <div class="header row">
    <div class="fill-space column">
      <div class="title">Pending Invitations</div>
    </div>
  </div>
  <div id="company-settings-content" class="column space-top-bottom-1"></div>

  <div class="block overflow-auto">
    <!-- Check if observable has emitted values and is not empty -->
    <ng-container
      *ngIf="pendingInvitations$ | async as invitations; else loading"
    >
      <!-- Display users if the array is not empty -->
      <ng-container *ngIf="invitations.length > 0; else noItems">
        <div class="block" *ngFor="let invitation of invitations">
          <invitation [invitation]="invitation"></invitation>
        </div>
      </ng-container>
    </ng-container>

    <!-- No users message -->
    <ng-template #noItems>
      <div>No pending invitations.</div>
    </ng-template>

    <!-- Loading message -->
    <ng-template #loading>
      <div>Loading invitations...</div>
    </ng-template>
  </div>
</div>
