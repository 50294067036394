import { Component, Input } from '@angular/core';
import { EResumeSectionType } from '../../../../../model/enum/EResumeInfoSection';
import { LevelInfoComponent } from '../../../../../common/level-info/level-info.component';
import { MatDialog } from '@angular/material/dialog';
import { IResumeOverviewEducation } from '../../../../../../../../model/IResume';
import { EEducationLevel } from '../../../../../../../../model/enums/EEducationLevel';
import {EResponsibilityLevel} from "../../../../../../../../model/enums/EResponsibilityLevel";
import {UtilService} from "../../../../../services/util.service";

@Component({
  selector: 'resume-overview-education',
  templateUrl: './resume-overview-education.component.html',
  styleUrls: ['../resume-overview.component.css','./resume-overview-education.component.css'],
})
export class ResumeOverviewEducationComponent {

  @Input() education: IResumeOverviewEducation | undefined;

  constructor(private dialog: MatDialog, public utilService: UtilService) {}

  showInfo(type: EResumeSectionType): void {
    let dialog = this.dialog.open(LevelInfoComponent, {});
    dialog.componentInstance.type = type;
  }

  protected readonly EResumeSectionType = EResumeSectionType;
  protected readonly EEducationLevel = EEducationLevel;
    protected readonly EResponsibilityLevel = EResponsibilityLevel;
}
