import { Injectable } from "@angular/core";
import {
  addDoc,
  collection,
  collectionData,
  doc,
  docData,
  Firestore,
  getDocs,
  query,
  where,
  writeBatch,
} from "@angular/fire/firestore";
import { FirebaseCollectionNames } from "../../../../model/FirebaseCollectionNames";
import { IJob } from "../../../../model/IJob";
import { StoreService } from "../services/store.service";
import { Api } from "./api";
import {IProblemReport} from "../../../../model/IProblemReport";

@Injectable({
  providedIn: "root",
})
export class ProblemReportsApi extends Api<IProblemReport> {
  constructor(private firestore: Firestore, private store: StoreService) {
    super();
  }

  create(problemReport: Omit<IProblemReport, "id">) {
    let reference = collection(this.firestore, this.getPath());
    return addDoc(reference, problemReport);
  }

  private getPath() {
    return FirebaseCollectionNames.PROBLEM_REPORTS;
  }
}
