import { Component, Input, OnInit } from "@angular/core";
import {IResumeImage, IResumeInfo} from "../../../../../../../model/IResume";
import { FirebaseStorageFolderNames } from "../../../../../../../model/FirebaseCollectionNames";
import { StoreService } from "../../../../services/store.service";
import { StorageApi } from "../../../../api/storage.api";
import { ActivatedRoute } from "@angular/router";
import { ESkeletonShape } from "../../../../model/enum/ESkeletonShape";

@Component({
  selector: "resume-info",
  templateUrl: "./resume-info.component.html",
  styleUrls: ["./resume-info.component.css"],
})
export class ResumeInfoComponent implements OnInit {
  @Input() info: IResumeInfo | undefined;
  @Input() image: IResumeImage | undefined;

  constructor() {}

  ngOnInit() {

  }

}
