import { ChangeDetectorRef, Injectable } from "@angular/core";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { IFilter, IFilterOption } from "../../model/IFilter";
import { EFilterType } from "../../model/enum/EFilterType";
import { IndexApi } from "../../api/index.api";
import { FirebaseCollectionNames } from "../../../../../model/FirebaseCollectionNames";
import {
  IFacetResponse,
  IFacets,
  ISearchMultipleRequest,
  ISearchQuery,
} from "../../model/ITypesense";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { EResumeFilterParams } from "../../model/enum/EResumeFilterParams";
import { StoreService } from "../store.service";
import { EJobFilterParams } from "../../model/enum/EJobFilterParams";
import { EIndexField } from "../../model/enum/EIndexField";
import { environment } from "../../../environments/environment";

interface IFiltersMap {
  resume: IResumeFilters;
  job: IJobFilters;
}

interface IResumeFilters {
  profession: IFilter;
  domainExpertise: IFilter;
  technicalSkills: IFilter;
  company: IFilter;
  educationInstitution: IFilter;
  language: IFilter;
  experienceLevel: IFilter;
  educationLevel: IFilter;
}

interface IJobFilters {
  jobStatus: IFilter;
}

@Injectable({
  providedIn: "root",
})
export class FiltersService {
  filters: IFiltersMap = {
    resume: {
      profession: {
        name: "Profession",
        indexField: EIndexField.PROFESSION,
        urlParam: EResumeFilterParams.PROFESSION,
        type: EFilterType.LIST,
        isMultiple: true,
        options: [],
        selectedOptions: [],
        selectedOptionsSubject: undefined,
        selectedOptions$: undefined,
      },
      domainExpertise: {
        name: "Domain Expertise",
        indexField: EIndexField.DOMAIN_EXPERTISE,
        urlParam: EResumeFilterParams.DOMAIN_EXPERTISE,
        type: EFilterType.LIST,
        isMultiple: true,
        options: [],
        selectedOptions: [],
        selectedOptionsSubject: undefined,
        selectedOptions$: undefined,
      },
      technicalSkills: {
        name: "Technical Skills",
        indexField: EIndexField.TECHNICAL_SKILLS,
        urlParam: EResumeFilterParams.TECHNICAL_SKILLS,
        type: EFilterType.LIST,
        isMultiple: true,
        options: [],
        selectedOptions: [],
        selectedOptionsSubject: undefined,
        selectedOptions$: undefined,
      },
      company: {
        name: "Employer",
        indexField: EIndexField.COMPANY,
        urlParam: EResumeFilterParams.COMPANY,
        type: EFilterType.LIST,
        isMultiple: true,
        options: [],
        selectedOptions: [],
        selectedOptionsSubject: undefined,
        selectedOptions$: undefined,
      },
      educationInstitution: {
        name: "Education Institution",
        indexField: EIndexField.EDUCATION_INSTITUTION,
        urlParam: EResumeFilterParams.EDUCATION_INSTITUTION,
        type: EFilterType.LIST,
        isMultiple: true,
        options: [],
        selectedOptions: [],
        selectedOptionsSubject: undefined,
        selectedOptions$: undefined,
      },
      language: {
        name: "Language",
        indexField: EIndexField.LANGUAGE,
        urlParam: EResumeFilterParams.LANGUAGE,
        type: EFilterType.LIST,
        isMultiple: true,
        options: [],
        selectedOptions: [],
        selectedOptionsSubject: undefined,
        selectedOptions$: undefined,
      },
      experienceLevel: {
        name: "Experience Level",
        indexField: EIndexField.EXPERIENCE_LEVEL,
        urlParam: EResumeFilterParams.EXPERIENCE_LEVEL,
        type: EFilterType.LIST,
        isMultiple: true,
        options: [],
        selectedOptions: [],
      },
      educationLevel: {
        name: "Education Level",
        indexField: EIndexField.EDUCATION_LEVEL,
        urlParam: EResumeFilterParams.EDUCATION_LEVEL,
        type: EFilterType.LIST,
        isMultiple: true,
        options: [],
        selectedOptions: [],
        selectedOptionsSubject: undefined,
        selectedOptions$: undefined,
      },
    },
    job: {
      jobStatus: {
        name: "Job Status",
        indexField: EIndexField.JOB_STATUS,
        urlParam: EJobFilterParams.JOB_STATUS,
        type: EFilterType.LIST,
        isMultiple: true,
        options: [],
        selectedOptions: [],
        selectedOptionsSubject: undefined,
        selectedOptions$: undefined,
      },
    },
  };

  resumeFilters: IFilter[] = [
    this.filters.resume.profession,
    this.filters.resume.domainExpertise,
    this.filters.resume.technicalSkills,
    this.filters.resume.company,
    this.filters.resume.educationInstitution,
    this.filters.resume.language,
    this.filters.resume.experienceLevel,
    this.filters.resume.educationLevel,
  ];

  jobFilters: IFilter[] = [this.filters.job.jobStatus];

  constructor(
    private indexApi: IndexApi,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: StoreService,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      // Initialize the selected options based on url params
      this.resumeFilters.forEach((filter) => {
        filter.selectedOptions = params[filter.urlParam!]
          ? params[filter.urlParam!].split(",")
          : [];
      });
    });

    this.updateFilters(this.resumeFilters, environment.typesense.collections.resumes);
    this.updateFilters(this.jobFilters, environment.typesense.collections.jobs);
  }

  updateFilters(filters: IFilter[], indexCollectionName: string) {
    filters.forEach((filter) => {
      // Watch the selected options collection for each filter
      filter.selectedOptionsSubject = new BehaviorSubject<string[]>(
          filter.selectedOptions!,
      );
      filter.selectedOptions$ = filter.selectedOptionsSubject.asObservable();

      // Populate the filter using the index facet
      let filterBy = "&filter_by=status:=completed&&organizationId:=" + this.store.organizationId! + "&max_facet_values=100";
      this.indexApi.getFacet(indexCollectionName, filter.indexField!, "", filterBy).subscribe((data: IFacetResponse) => {
        let facets = data?.facet_counts[0]?.counts;
        if (facets && facets.length) {
          // Make sure to remove any commas in a single value that can mess up the string[] values
          filter.options = facets.map(({ count, value }) => ({
            count,
            value: value.replace(/,/g, ""),
          }));
        }
      });
    });
  }

  initializeResumeFilters() {
    this.resumeFilters.forEach((filter) => {
      // Watch the selected options collection for each filter
      filter.selectedOptionsSubject = new BehaviorSubject<string[]>(
        filter.selectedOptions!,
      );
      filter.selectedOptions$ = filter.selectedOptionsSubject.asObservable();

      // Populate the filter using the index facet
      let filterBy = "&filter_by=status:=completed&&organizationId:=" + this.store.organizationId! + "&max_facet_values=100";
      this.indexApi.getFacet(FirebaseCollectionNames.RESUMES, filter.indexField!, "", filterBy).subscribe((data: IFacetResponse) => {
          let facets = data?.facet_counts[0]?.counts;
          if (facets && facets.length) {
            // Make sure to remove any commas in a single value that can mess up the string[] values
            filter.options = facets.map(({ count, value }) => ({
              count,
              value: value.replace(/,/g, ""),
            }));
          }
        });
    });
  }

  initializeJobFilters() {
    this.jobFilters.forEach((filter) => {
      // Watch the selected options collection for each filter
      filter.selectedOptionsSubject = new BehaviorSubject<string[]>(
        filter.selectedOptions!,
      );
      filter.selectedOptions$ = filter.selectedOptionsSubject.asObservable();

      // Populate the filter using the index facet
      let filterBy =
        "&filter_by=status:=completed&&organizationId:=" +
        this.store.organizationId! +
        "&max_facet_values=100";
      this.indexApi
        .getFacet(
          FirebaseCollectionNames.JOBS,
          filter.indexField!,
          "",
          filterBy,
        )
        .subscribe((data: IFacetResponse) => {
          let facets = data?.facet_counts[0]?.counts;
          if (facets && facets.length) {
            // Make sure to remove any commas in a single value that can mess up the string[] values
            filter.options = facets.map(({ count, value }) => ({
              count,
              value: value.replace(/,/g, ""),
            }));
          }
        });
    });
  }

  // Re-initializes each filter Count in the 'filters' array based on param changes
  // This should be called on param changes
  updateFilterCounts(filters: IFilter[], params: Params | undefined, indexCollectionName: string) {
    if (!params) {
      return;
    }
    filters.forEach((filter) => {
      // Calculate the query params for every facet excluding the facet values
      const queryParams = this.getFacetQueryParams(params, filter, filters);
      firstValueFrom(
        this.indexApi.getFacet(
          indexCollectionName,
          filter.indexField!,
          "",
          queryParams,
        ),
      ).then((data: IFacetResponse) => {
        const facets = data?.facet_counts[0]?.counts || [];
        // Create a map for quick lookup
        const facetMap = new Map(
          facets.map((facet) => [facet.value.replace(/,/g, ""), facet.count]),
        );
        filter.options?.forEach((option) => {
          option.count = facetMap.get(option.value.replace(/,/g, "")) || 0;
        });
      });
    });
  }

  // Builds the params string to get a facet based on filters.
  // It excludes filtering with the facet values even if they exist on the url.
  getFacetQueryParams(
    params: Params,
    facet: IFilter,
    filters: IFilter[],
  ): string {
    let filterBy = "&filter_by=";
    let filterByClauses: string[] = [];
    const unsafeCharactersRegex = /[\(\),]/g;

    filterByClauses.push("organizationId:=" + this.store.organizationId!); // Mandatory filter for every query to the index
    filterByClauses.push("status:=completed"); // Mandatory filter for every query to the index

    filters.forEach((filter) => {
      if (params[filter.urlParam!] && filter.urlParam != facet.urlParam) {
        // Replace unsafe characters before making the request to the index (e.g., parenthesis)
        let safeParams = params[filter.urlParam!]
          .split(",")
          .map((value: string) =>
            value.trim().replace(unsafeCharactersRegex, ""),
          );
        filterByClauses.push(filter.indexField + ":=" + "[" + safeParams + "]");
      }
    });
    for (let i = 0; i < filterByClauses.length; i++) {
      filterBy += filterByClauses[i];
      if (i != filterByClauses.length - 1) {
        filterBy += "&&";
      }
    }
    return filterBy;
  }

  removeTag(filter: IFilter, value: string) {
    if (!filter.selectedOptions || !filter.selectedOptions.length) {
      return;
    }
    let index = filter.selectedOptions.indexOf(value);
    if (index !== -1) {
      filter.selectedOptions.splice(index, 1);
    }
    this.updateSelectedOptions({
      filter: filter,
      options: filter.selectedOptions,
    });
  }

  updateSelectedOptions(event: { filter: IFilter; options: string[] }) {
    event.filter?.selectedOptionsSubject?.next(event.options);
    this.applyResumeFilters();
  }

  applyResumeFilters(): void {
    const queryParams: { [key: string]: string } = {};
    // Iterate over each filter and add its selected options to the queryParams object
    this.resumeFilters.forEach((filter) => {
      queryParams[filter.urlParam!] =
        filter.selectedOptions!.length > 0
          ? filter.selectedOptions!.join(",")
          : "";
    });

    // Update the URL with the new query parameters
    this.router
      .navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: "merge",
        replaceUrl: true,
      })
      .then();
  }

  // Returns the fiterBy param string for an index query using the url params argument
  getFilterByParamsFromUrl(params: Params, filters: IFilter[]): string {
    let filterBy = "";
    let filterByClauses: string[] = [];
    const unsafeCharactersRegex = /[\(\),]/g;

    filterByClauses.push("organizationId:=" + this.store.organizationId!); // Mandatory filter for every query to the index
    filterByClauses.push("status:=completed"); // Mandatory filter for every query to the index
    filters.forEach((filter) => {
      if (params[filter.urlParam!]) {
        // Replace unsafe characters before making the request to the index (e.g., parenthesis)
        let safeParams = params[filter.urlParam!]
          .split(",")
          .map((value: string) =>
            value.trim().replace(unsafeCharactersRegex, ""),
          );
        filterByClauses.push(filter.indexField + ":=" + "[" + safeParams + "]");
      }
    });
    for (let i = 0; i < filterByClauses.length; i++) {
      filterBy += filterByClauses[i];
      if (i != filterByClauses.length - 1) {
        filterBy += "&&";
      }
    }
    return filterBy;
  }

  // Returns the fiterBy param string for an index query using the params argument
  getSearchParamsFromUrl(params: Params): string[] {
    let searchTags: string[] = [];
    const unsafeCharactersRegex = /[\(\),]/g;

    if (params["search"]) {
      // Replace unsafe characters before making the request to the index (e.g., parenthesis)
      let safeParams = params["search"]
        .split(",")
        .map((value: string) =>
          value.trim().replace(unsafeCharactersRegex, ""),
        );
      searchTags = [...searchTags, ...safeParams];
    }

    return searchTags;
  }

  // Returns an object ready to sent to the index for querying
  buildQuery(params: Params, filters: IFilter[]) {
    let searches: ISearchQuery[] = [];

    const queryBy: string = "parsing.text";
    /*const includeFields: string = "id, status, creationTime, image, resumeAnalysis.info, resumeAnalysis.overview.topSkills, resumeAnalysis.content.skills.technical";*/
    const includeFields: string = "id";
    const sortBy: string = "creationTime:desc";
    const perPage: number = 200;
    const filterBy: string = this.getFilterByParamsFromUrl(params, filters);
    const searchTags: string[] = this.getSearchParamsFromUrl(params);

    if (!searchTags.length) {
      let searchQuery: ISearchQuery = {
        collection: environment.typesense.collections.resumes,
        q: "*",
        query_by: queryBy,
        include_fields: includeFields,
        sort_by: sortBy,
        filter_by: filterBy,
        per_page: perPage,
      };
      searches.push(searchQuery);
    } else {
      searchTags.forEach((q) => {
        let searchQuery: ISearchQuery = {
          collection: environment.typesense.collections.resumes,
          q: q,
          query_by: queryBy,
          include_fields: includeFields,
          filter_by: filterBy,
          per_page: perPage,
        };
        searches.push(searchQuery);
      });
    }
    let query: ISearchMultipleRequest = {
      searches: searches,
    };
    return query;
  }
}
