import {Component, Input} from '@angular/core';
import {IResumePublication, IResumeReference} from "../../../../../../../../model/IResume";

@Component({
  selector: 'resume-content-references',
  templateUrl: './resume-content-references.component.html',
  styleUrls: ['./resume-content-references.component.css']
})
export class ResumeContentReferencesComponent {
  @Input() references: IResumeReference[] | null | undefined = [];
}
