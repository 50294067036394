import { Injectable } from '@angular/core';
import {IJob} from "../../../../model/IJob";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class JobService {

  job$: Observable<IJob|undefined> | undefined;
  job: IJob | undefined;

  constructor() {
    this.job$?.subscribe( job => {
      this.job = job;
    })
  }
}
