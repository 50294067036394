import {Component, OnDestroy, OnInit} from '@angular/core';
import {EApplicantNoteType} from "../../../../../../../../../../model/enums/EApplicantNoteType";
import {IApplicantNote} from "../../../../../../../../../../model/IApplicant";
import {ApplicantNotesApi} from "../../../../../../../api/applicant.notes.api";
import {UserService} from "../../../../../../../services/user.service";
import {map, Observable, of, Subject, switchMap, takeUntil} from "rxjs";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'applicant-notes',
  templateUrl: './applicant-notes.component.html',
  styleUrls: ['./applicant-notes.component.css']
})
export class ApplicantNotesComponent implements OnInit, OnDestroy {

  notes$: Observable<IApplicantNote[]|undefined> | undefined;
  options: EApplicantNoteType[] | undefined;
  note: Omit<IApplicantNote, 'id'> = {
    creationTime: this.applicantNotesApi.getServerTimestamp(),
    creatorId: this.userService.user!.id,
    creatorName: this.userService.user!.name,
    text: "",
    type: EApplicantNoteType.INFO,
  };
  isLoading: boolean = false;
  applicantId: string | null | undefined;
  unsubscribe$ = new Subject<void>();

  constructor(private applicantNotesApi: ApplicantNotesApi, private userService: UserService, private route: ActivatedRoute) {
  }

  createNote() {
    if(!this.applicantId || !this.note || !this.note.text || this.note.text.trim() === '') { return; }
    this.isLoading = true;
    this.applicantNotesApi.create(this.applicantId, this.note).then( result =>{
    }).catch( error => {
      console.log(error);
    }).finally( () => {
      this.isLoading = false;
      this.resetNote();
    });
  }

  resetNote() {
    this.note = {
      creationTime: this.applicantNotesApi.getServerTimestamp(),
      creatorId: this.userService.user!.id,
      creatorName: this.userService.user!.name,
      text: "",
      type: EApplicantNoteType.INFO,
    };
  }

  ngOnInit() {
    this.route.paramMap.pipe(  takeUntil(this.unsubscribe$) ).subscribe( params => {
      this.applicantId =  params.get("applicantId");
    });

    this.notes$ = this.route.paramMap.pipe(
        switchMap((params) => {
          let applicantId = params.get("applicantId");
          return applicantId ? this.applicantNotesApi.getAll(applicantId) : of(undefined);
        }),
        map(notes => notes ? notes.sort((a, b) => b.creationTime?.toDate().getTime() - a.creationTime?.toDate().getTime()) : undefined)
    );
    this.options = Object.values(EApplicantNoteType);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
