import { Component, Input } from '@angular/core';

@Component({
  selector: 'resume-overview-top-skills',
  templateUrl: './resume-overview-top-skills.component.html',
  styleUrls: ['../resume-overview.component.css','./resume-overview-top-skills.component.css'],
})
export class ResumeOverviewTopSkillsComponent {
  @Input() skills: string[] = [];
}
