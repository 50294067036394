import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
  transform(enumObj: any): Array<{ key: string; value: any }> {
    return Object.keys(enumObj)
      .filter((key) => isNaN(+key))
      .map((key) => ({ key, value: enumObj[key] }));
  }
}
