import {Component, OnInit} from "@angular/core";
import {
  combineLatestWith,
  filter,
  map,
  Observable,
  tap,
  withLatestFrom,
} from "rxjs";
import { AuthService } from "../../../services/auth.service";
import { UserWithOrganizationsService } from "../../../services/user-with-organizations.service";
import {
  IOrganizationWithRole, IUser, IUserWithResolvedOrganizations, IUserWithRoles
} from "../../../../../../model/IUser";
import { ActivatedRoute } from "@angular/router";
import {UserService} from "../../../services/user.service";
import {OrganizationUsersApi} from "../../../api/organization.users.api";
import {IOrganizationUser} from "../../../../../../model/IOrganizationUser";

@Component({
  selector: "profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["../banner.component.css","./profile.component.css"],
})

export class ProfileComponent implements OnInit {

  profile$ : Observable<IOrganizationUser[]|undefined> | undefined;
  profile: IOrganizationUser | undefined;

  constructor(public userService: UserService, private organizationUsersApi: OrganizationUsersApi) {
  }

  ngOnInit() {

    this.profile$ = this.organizationUsersApi.getAll([ { field: 'email', condition: '==', value: this.userService.user!.email!} ]);
    this.profile$.subscribe( data => {
      if(data && data.length) {
        this.profile = data[0];
      }
    })
  }

  /*user$: Observable<IUserWithRoles>;
  orgIdParam$: Observable<string> | undefined;
  userFavoriteOrganizations$: Observable<string | undefined>;
  userWithSingleOrganization$:
    | Observable<
        Pick<IUserWithResolvedOrganizations, "name"> & {
          singleOrganization: IOrganizationWithRole | undefined;
        }
      >
    | undefined;

  constructor(
    private route: ActivatedRoute,
    private userWithOrganizationService: UserWithOrganizationsService,
  ) {
    this.user$ =
      this.userWithOrganizationService.userWithResolvedOrganizationsWithRole$;
    this.userFavoriteOrganizations$ =
      this.userWithOrganizationService.userFavoriteOrganizations$;
  }

  ngOnInit() {
    this.orgIdParam$ = this.route.data.pipe(
      map((data) => data["orgIdFromParentRoute"]),
    );
    this.userWithSingleOrganization$ = this.user$.pipe(
      combineLatestWith(
        this.orgIdParam$.pipe(
          combineLatestWith(this.userFavoriteOrganizations$),
        ),
      ),
      map(([user, [selectedOrg, favoriteOrg]]) => {
        return {
          name: user.name,
          singleOrganization: user.resolvedOrganizationsWithRole.find(
            (org) => org?.id === (selectedOrg ?? favoriteOrg),
          ),
        };
      }),
    );
  }*/
}
