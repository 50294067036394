<div class="overview-section column">

  <div class="overview-section-title"> Employment Status </div>

  <div class="column">

    <div class="employment-status">
      <div class="employment-status-icon center">
        <img [src]="isCurrentlyEmployed ? 'assets/images/employment-status-employed.svg' : 'assets/images/employment-status-unemployed.svg'"/>
      </div>
      <div class="employment-status-text column column-center-left"> {{ isCurrentlyEmployed ? 'Employed' : 'Unemployed' }}</div>
    </div>

  </div>

</div>
