import { Component, OnInit } from "@angular/core";
import { OrganizationsApi } from "../../../api/organizations.api";
import { ActivatedRoute, Router } from "@angular/router";
import { of, switchMap } from "rxjs";
import { StoreService } from "../../../services/store.service";

@Component({
  selector: "organization-page",
  templateUrl: "./organization-page.component.html",
  styleUrls: ["./organization-page.component.css"],
})
export class OrganizationPageComponent implements OnInit {
  userHasValidOrganization: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private organizationApi: OrganizationsApi,
    private store: StoreService,
  ) {}

  ngOnInit() {
    this.store.organization$ = this.route.paramMap.pipe(
      switchMap((params) => {
        let organizationId = params.get("organizationId");
        if (organizationId) {
          this.store.organizationId = organizationId;
          return this.organizationApi.getOne(organizationId);
        }
        return of(undefined);
      }),
    );

    this.route.paramMap.subscribe((params) => {
      let organizationId = params.get("organizationId");
      console.log("New Organization Id:", organizationId);
      this.store.updateOrganizationId(organizationId);
    });

    const orgIdParam = this.route.snapshot.data["orgIdFromParentRoute"];

    // in a next version, this will subscribe to the user's organizations and check if the user has access to this organization and redirect to /no-access-company if not
    this.organizationApi
      .getOneOnce(orgIdParam)
      .then((org) => org.exists())
      .then((exists) => {
        this.userHasValidOrganization = exists;
        if (!exists) {
          this.router.navigate(["/no-access-company"]);
        }
      });
  }
}
