import { Injectable } from '@angular/core';
import { UploadTask } from 'firebase/storage';
import { combineLatest, delay, map, Observable, startWith } from 'rxjs';
import { Timestamp } from '@angular/fire/firestore';
import { IResume } from '../../../../model/IResume';
import { ResumesApi } from '../api/resumes.api';
import { propertyOf } from '../../../../model/utils';
import { EStatus } from '../../../../model/enums/EResumeStatuses';
import { StoreService } from './store.service';

export enum EUploadStatus {
  PENDING = 'pending',
  UPLOADING = 'uploading',
  COMPLETED = 'completed'
}
export interface IUpload {
  id?: string;
  file: File;
  progress: number;
  status: EUploadStatus;
  uploadTask?: UploadTask;
}
interface IUploadIndex {
  [key: string]: IUpload;
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  // An index to store and handle all uploads
  uploads: IUploadIndex = {};

  // The documents in firestore. One document in firestore for each upload file
  resumes$: Observable<IResume[] | undefined> | undefined;
  resumesWithErrors$: Observable<IResume[] | undefined> | undefined;

  // Resumes in various states
  uploading$: Observable<IResume[] | undefined> | undefined;
  stored$: Observable<IResume[] | undefined> | undefined;
  processing$: Observable<IResume[] | undefined> | undefined;
  error$: Observable<IResume[] | undefined> | undefined;

  constructor(
    private resumesApi: ResumesApi,
    private store: StoreService
  ) {
    // Get uploads from localstorage (case where the popup was closed and re-opened)
    const uploads: string | null = localStorage.getItem('uploads');
    if (uploads && uploads !== 'null') {
      this.uploads = JSON.parse(uploads);
    }

    this.uploading$ = this.resumesApi.getAll([
      { field: propertyOf<IResume>('status'), condition: '==', value: EStatus.UPLOADING },
      { field: propertyOf<IResume>('createdBy'), condition: '==', value: this.store.userId }
    ]);
    this.stored$ = this.resumesApi.getAll([
      { field: propertyOf<IResume>('status'), condition: '==', value: EStatus.STORED },
      { field: propertyOf<IResume>('createdBy'), condition: '==', value: this.store.userId }
    ]);
    this.processing$ = this.resumesApi.getAll([
      { field: propertyOf<IResume>('status'), condition: '==', value: EStatus.PROCESSING },
      { field: propertyOf<IResume>('createdBy'), condition: '==', value: this.store.userId }
    ]);
    this.error$ = this.resumesApi.getAll([
      { field: propertyOf<IResume>('status'), condition: '==', value: EStatus.ERROR },
      { field: propertyOf<IResume>('createdBy'), condition: '==', value: this.store.userId }
    ]);

    this.resumes$ = combineLatest([this.uploading$, this.stored$, this.processing$]).pipe(
      startWith([]),
      map(([uploadingResumes, storedResumes, processingResumes]) => {
        return [...(uploadingResumes || []), ...(storedResumes || []), ...(processingResumes || [])];
      })
    );

    this.resumesWithErrors$ = combineLatest([this.error$, this.resumes$]).pipe(
      startWith([]),
      map(([errorResumes, restResumes]) => {
        return [...(errorResumes || []), ...(restResumes || [])];
      })
    );
  }
}
