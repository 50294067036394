import { Component, Input, OnInit } from "@angular/core";
import { IResume } from "../../../../../../model/IResume";
import { ESkeletonShape } from "../../../model/enum/ESkeletonShape";
import { EStatus } from "../../../../../../model/enums/EResumeStatuses";


@Component({
  selector: "resume-panel",
  templateUrl: "./resume-panel.component.html",
  styleUrls: ["./resume-panel.component.css"],
})
export class ResumePanelComponent implements OnInit {
  @Input() resume: IResume | undefined;
  @Input() selectedResumeId: string | undefined;


  constructor() {}


  isNew(date: Date | undefined): boolean {
    if (!date) { return false; }
    const oneDayAgo = new Date(Date.now() - (8 * 60 * 60 * 1000)); // Calculate the date for 8 hours ago
    return date > oneDayAgo;
  }




  ngOnInit() {

  }

  protected readonly ESkeletonShape = ESkeletonShape;
  protected readonly EStatus = EStatus;
}
