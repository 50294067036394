import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { IJustification } from "../../../../../../../../../model/IApplicant";
import { UtilService } from "../../../../../../services/util.service";

@Component({
  selector: "job-match-optional",
  templateUrl: "./job-match-optional.component.html",
  styleUrls: [
    "./job-match-optional.component.css",
    "./../job-applicant-match.component.css",
  ],
})
export class JobMatchOptionalComponent implements OnInit, OnChanges {
  @Input() justifications: IJustification[] = [];
  score: number = 0;
  @Input() scoreJustification: string | undefined;
  matched: number = 0;
  unMatched: number = 0;

  constructor(public utilService: UtilService) {}

  private updateMatchCountsAndScore() {
    this.matched = 0;
    this.unMatched = 0;
    for (let justification of this.justifications) {
      justification.isMatched ? this.matched++ : this.unMatched++;
    }
    this.score = this.justifications.length
      ? (this.matched / this.justifications.length) * 100
      : 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["justifications"]) {
      this.updateMatchCountsAndScore();
    }
  }

  ngOnInit() {
    this.updateMatchCountsAndScore();
  }
}
