<div class="resume-content-section column fill-space">

  <div class="resume-content-section-header fill-space">
    <div class="resume-content-section-header-icon center"> <img src="assets/images/section-skills.svg"/> </div>
    <div class="resume-content-section-header-title center"> Skills </div>
  </div>

  <div class="resume-content-section-data column fill-space">

    <empty-list text="No Skills" *ngIf="!skills"></empty-list>

    <ng-container *ngIf="skills">
      <div class="resume-node column">
        <div class="skills column">
          <div class="skills-title"> Domain Expertise</div>
          <div class="skills-content row wrap">
            <div class="center space-top-bottom-1 empty-info" *ngIf="!skills?.domainExpertise?.length"> No Domain Expertise Records </div>
            <div class="skill domain-expertise" *ngFor="let s of skills.domainExpertise"> {{ s }}</div>
          </div>
        </div>
        <div class="skills column">
          <div class="skills-title"> Technical Skills</div>
          <div class="skills-content row wrap">
            <div class="center space-top-bottom-1 empty-info" *ngIf="!skills?.technical?.length"> No Technical Skills </div>
            <div class="skill technical" *ngFor="let s of skills.technical"> {{ s }}</div>
          </div>
        </div>
        <div class="skills column">
          <div class="skills-title"> Soft Skills</div>
          <div class="skills-content row wrap">
            <div class="center space-top-bottom-1 empty-info" *ngIf="!skills?.soft?.length"> No Soft Skills </div>
            <div class="skill soft" *ngFor="let s of skills.soft"> {{ s }}</div>
          </div>
        </div>
      </div>
    </ng-container>

  </div>


</div>
