<div class="image-slider">

  <div class="image-slider-content">

  </div>
  <div class="images-slider-navigation">
    <div class="image-slider-arrow"></div>
    <div class="image-slider-rectangles">
      <div class="image-slider-rectangle" *ngFor="let r of images"> test </div>
    </div>
    <div class="image-slider-arrow"></div>
  </div>

</div>
