import { Component, Input } from '@angular/core';
import { IResumePublication } from '../../../../../../../../model/IResume';

@Component({
  selector: 'resume-content-publications',
  templateUrl: './resume-content-publications.component.html',
  styleUrls: ['./resume-content-publications.component.css'],
})
export class ResumeContentPublicationsComponent {
  @Input() publications: IResumePublication[] | null | undefined = [];
}
