import { Injectable } from "@angular/core";
import {
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  docData,
  Firestore,
  orderBy,
  query,
  setDoc,
  where,
} from "@angular/fire/firestore";
import { FirebaseCollectionNames } from "../../../../model/FirebaseCollectionNames";
import { IResume } from "../../../../model/IResume";
import { QueryConstraint } from "@firebase/firestore";
import { StoreService } from "../services/store.service";
import { Api } from "./api";
import { FirestoreSorting } from "../model/firestore.sorting";
import { FirestoreFilter } from "../model/firestore.filter";

@Injectable({
  providedIn: "root",
})
export class ResumesApi extends Api<IResume> {
  constructor(
    private firestore: Firestore,
    private store: StoreService,
  ) {
    super();
  }


  getAll(filters: FirestoreFilter[] = [], sorting: FirestoreSorting[] = []) {
    const constraints: QueryConstraint[] = [
      ...filters.map( (filter) => where(filter.field, filter.condition, filter.value) ),
      ...sorting.map( (sortCondition) => orderBy(sortCondition.field, sortCondition.order) ),
    ];
    const reference = query(collection(this.firestore, this.getPath()), ...constraints);
    return collectionData<IResume>(reference as any, this.options);
  }

  getOne(id: string) {
    const reference = doc(this.firestore, this.getPath(), id);
    return docData<IResume>(reference as any, this.options);
  }

  create(resume: Omit<IResume, "id">) {
    const reference = collection(this.firestore, this.getPath());
    return addDoc(reference, resume);
  }

  set(id: string, item: Partial<IResume>): Promise<void> {
    const reference = doc(this.firestore, this.getPath(), id);
    return setDoc(reference, item, { merge: true });
  }

  delete(id: string): Promise<void> {
    const reference = doc(this.firestore, this.getPath(), id);
    return deleteDoc(reference);
  }

  private getPath() {
    return FirebaseCollectionNames.ORGANIZATIONS + "/" + this.store.organizationId! + "/" + FirebaseCollectionNames.RESUMES;
  }
}
