import { Component, Input } from '@angular/core';
import { IResumeOverview } from '../../../../../../../model/IResume';

@Component({
  selector: 'resume-overview',
  templateUrl: './resume-overview.component.html',
  styleUrls: ['./resume-overview.component.css'],
})
export class ResumeOverviewComponent {
  showOverview: boolean = true;
  @Input() overview: IResumeOverview | undefined;
}
