<div class="invitation row row-center-left" *ngIf="invitation">
  <div class="user-content column">
    <div class="invitation-organization-name">
      {{ invitation.organizationId }}
    </div>
    <div class="invitation-organization-email">{{ invitation.role }}</div>
  </div>
  <div class="user-verification fill-space row">
    <div class="user-verification-text center space-left-1">
      {{ invitation.invitedByEmail }}
    </div>
  </div>
  <ng-container *ngIf="!isLoading; else loading">
    <div class="center space-right-2">
      Created On:
      {{ invitation.creationTime.toDate() | date: "mediumDate" }}
    </div>
    <div class="user-options column center">
      <div class="button-small button-subaction" (click)="acceptInvitation()">
        Accept Invitation
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <mat-spinner diameter="58"></mat-spinner>
  </ng-template>
</div>
