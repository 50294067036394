<div class="popup column">
  <div class="popup-header row">
    <div class="popup-header-title fill-space">Create New Job</div>
    <div class="popup-header-close" mat-dialog-close>
      <img src="assets/images/close.svg" />
    </div>
  </div>

  <div class="popup-loader row center" *ngIf="isLoading">
    <loader></loader>
  </div>

  <div id="job-create" class="popup-content column" *ngIf="!isLoading">
    <div class="form-field column space-bottom-1">
      <div class="form-field-label">Job Title</div>
      <div class="form-field-input">
        <input class="fill-space" type="text" [(ngModel)]="job.title" />
      </div>
    </div>
    <div class="form-field column space-bottom-1">
      <div class="form-field-label">Job Description</div>
      <div class="form-field-input">
        <textarea rows="20" class="fill-space" type="text" [(ngModel)]="job.description"></textarea>
      </div>
    </div>
  </div>

  <div class="popup-buttons row" *ngIf="!isLoading">
    <div class="fill-space"></div>
    <div class="button button-action" (click)="createJob()">Create Job</div>
  </div>
</div>
