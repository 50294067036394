<div class="overview-section column" *ngIf="experience">

  <div class="overview-section-title"> Experience </div>

  <div class="overview-section-content column">

    <div class="overview-subsection column">

      <div class="overview-subsection-title fill-space"> Last Job Position</div>

      <div class="last-job overview-section-data row">

        <div class="center empty-info" *ngIf="!experience.lastJob || !experience.lastJob.position"> No Previous Work Experience </div>

        <div class="last-job-content column fill-space" *ngIf="experience.lastJob">

          <div>
            <div class="row fill-space">
              <div class="last-job-title capitalize"> {{experience.lastJob.position.toLowerCase()}}</div>
            </div>
            <ng-container *ngIf="experience.lastJob.period">
              <div class="last-job-details row-center-left">
                <div> {{experience.lastJob.period }}</div>
              </div>
            </ng-container>
          </div>
          <div class="row fill-space column">
            <div class="last-job-subtitle fill-space capitalize"> {{experience.lastJob.company?.toLowerCase()}}</div>
          </div>

        </div>

      </div>

    </div>


    <div class="overview-subsection column space-bottom-1">
      <div class="overview-subsection-title fill-space"> Total Experience</div>
      <div class="overview-section-data row">
          <div class="overview-section-data-value fill-space"> {{experience.totalExperience ? experience.totalExperience : '0 years'}}</div>
          <div class="overview-section-data-level center"> {{ experience.experienceLevel }}</div>
          <div class="overview-section-data-dot center"> <img src="assets/images/dot-gray.svg"/> </div>
          <div class="overview-section-data-bar center">
            <progress-bar-segmented [segments]="5" [dynamicColor]="true" [activeSegments]="utilService.getEnumValueIndex(EResumeExperienceLevel, experience.experienceLevel)"></progress-bar-segmented>
          </div>
          <div class="overview-section-data-info center" (click)="showInfo(EResumeSectionType.EXPERIENCE)"> <img src="assets/images/info.svg" /> </div>
      </div>
    </div>

    <!--<div class="overview-subsection column space-bottom-1">
      <div class="overview-subsection-title  fill-space"> Average Job Tenure </div>
      <div class="overview-section-data row">
        <div class="overview-section-data-value fill-space"> {{experience.jobTenure ? experience.jobTenure : '-'}}</div>
        <div class="overview-section-data-level center"> {{ experience.jobTenureLevel }} </div>
        <div class="overview-section-data-dot center"> <img src="assets/images/dot-gray.svg"/> </div>
        <div class="overview-section-data-bar center">
          <progress-bar-segmented [segments]="5" [dynamicColor]="true" [activeSegments]="utilService.getEnumValueIndex(EJobTenureLevel, experience.jobTenureLevel)"></progress-bar-segmented>
        </div>
        <div class="overview-section-data-info  center" (click)="showInfo(EResumeSectionType.TENURE)"> <img src="assets/images/info.svg" /> </div>
      </div>
    </div>-->

    <div class="overview-subsection column space-bottom-1">
      <div class="overview-subsection-title  fill-space">  Average Job Tenure </div>
      <div class="overview-section-data row">
        <div class="overview-section-data-value fill-space"> {{experience.weightedAverageJobTenure ? experience.weightedAverageJobTenure : '-'}}</div>
        <div class="overview-section-data-level center"> {{ experience.weightedAverageJobTenureLevel }} </div>
        <div class="overview-section-data-dot center"> <img src="assets/images/dot-gray.svg"/> </div>
        <div class="overview-section-data-bar center">
          <progress-bar-segmented [segments]="5" [dynamicColor]="true" [activeSegments]="utilService.getEnumValueIndex(EJobTenureLevel, experience.weightedAverageJobTenureLevel)"></progress-bar-segmented>
        </div>
        <div class="overview-section-data-info  center" (click)="showInfo(EResumeSectionType.TENURE)"> <img src="assets/images/info.svg" /> </div>
      </div>
    </div>

    <div class="overview-subsection column space-bottom-1">
      <div class="overview-subsection-title  fill-space"> Longest Job Tenure </div>
      <div class="overview-section-data row">
        <div class="overview-section-data-value fill-space"> {{experience.longestJobTenure ? experience.longestJobTenure : '-'}}</div>
        <div class="overview-section-data-level center"> {{ experience.longestJobTenureLevel }} </div>
        <div class="overview-section-data-dot center"> <img src="assets/images/dot-gray.svg"/> </div>
        <div class="overview-section-data-bar center">
          <progress-bar-segmented [segments]="5" [dynamicColor]="true" [activeSegments]="utilService.getEnumValueIndex(EJobTenureLevel, experience.longestJobTenureLevel)"></progress-bar-segmented>
        </div>
        <div class="overview-section-data-info  center" (click)="showInfo(EResumeSectionType.TENURE)"> <img src="assets/images/info.svg" /> </div>
      </div>
    </div>

    <div class="overview-subsection column">
      <div class="overview-subsection-title fill-space"> Responsibility Level (Last Job)</div>

      <div class="overview-section-data row-top-center">
        <div class="overview-section-data-value fill-space capitalize">
          {{ experience.lastJob?.position ? experience.lastJob?.position?.toLowerCase() : "No Work Experience" }}
        </div>
      </div>
      <div class="row-center-right">
        <div class="overview-section-data-level center"> {{ experience.responsibilityLevel }} </div>
        <div class="overview-section-data-dot center"> <img src="assets/images/dot-gray.svg"/> </div>
        <div class="overview-section-data-bar center">
          <progress-bar-segmented [segments]="5" [dynamicColor]="true" [activeSegments]="utilService.getEnumValueIndex(EResponsibilityLevel, experience.responsibilityLevel)"></progress-bar-segmented>
        </div>
        <div class="overview-section-data-info center" (click)="showInfo(EResumeSectionType.RESPONSIBILITY)"> <img src="assets/images/info.svg" /> </div>
      </div>
    </div>

  </div>


</div>
