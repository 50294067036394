export enum EResumeIndicator {
  DIVERSE_BACKGROUND = 'Diverse Background',
  LACK_OF_PROGRESSION = 'Lack of Progression',
  JOB_HOPPING = 'Job Hopping',
  CAREER_SHIFTS = 'Career Shifts',
  GAPS_IN_EMPLOYMENT = 'Gaps In Employment',
  LACK_OF_PRACTICAL_EXPERIENCE = 'Lack of Practical Experience',
  UNFINISHED_DEGREES = 'Unfinished Degrees',
  EXTENDED_GRADUATION = 'Extended Graduation',
  SOLID_BACKGROUND = 'Solid Background',
  CAREER_PROGRESSION = 'Career Progression',
  QUANTIFIABLE_ACHIEVEMENTS = 'Quantifiable Achievements',
  CONSISTENT_EMPLOYMENT = 'Consistent Employment',
  LONG_TERM_COMMITMENT = 'Long-Term Commitment',
  INDUSTRY_RECOGNITION = 'Industry Recognition',
  TOP_TIER_COMPANY = 'Top-Tier Company',
  CONTINUOUS_LEARNING = 'Continuous Learning',
  RENOWNED_INSTITUTION = 'Renowned Institution',
  LEADERSHIP = 'Leadership',
  TEAM_PLAYER = 'Team Player',
  EDUCATION_EXCELLENCE = 'Education Excellence',
  POOR_RESUME_QUALITY = 'Poor Resume Quality',
}
