<div class="filter-panel column" *ngIf="filter && filter.options?.length">

    <div class="filter-panel-title">{{ filter.name }}</div>

    <ng-container *ngIf="filter.type === EFilterType.LIST">
        <div class="filter-panel-content column" *ngIf="filter.options?.length">
            <div class="filter-option row"
                 [class]="{ 'filter-option-empty': !o.count }"
                 *ngFor="let o of filter.options; let index = index"
                 (click)="select(o.value, o.count)">
                <div class="filter-option-checkbox">
                    <checkbox [isChecked]="isSelected(o.value)"></checkbox>
                </div>
                <div class="filter-option-label column column-center-left fill-space">{{ o.value }}</div>
                <div class="filter-option-count center"> {{ o.count ? o.count : 0 }} </div>
            </div>
        </div>

        <div class="filter-panel-more column" *ngIf="filter.options &&  filter.options.length >= 10">

          <div class="filter-panel-more-label"  (click)="showFullList = !showFullList">
            <div class="filter-panel-more-label-text"> {{ showFullList ? 'View Less' : 'View More' }} </div>
            <div class="filter-panel-more-label-icon center"> <img [src]="showFullList ? 'assets/images/arrow-up.svg' : 'assets/images/arrow-down.svg'" /></div>
          </div>

          <div class="filter-panel-more-content column" *ngIf="showFullList">
            <div class="filter-panel-search">
                <input type="text" placeholder="Search" class="fill-space"  [formControl]="searchControl" />
            </div>
            <ng-container *ngIf="filteredOptions$ | async as filteredOptions">
              <div class="filter-panel-more-list column" >
                <div class="filter-option row" *ngFor="let o of filteredOptions; let index = index" (click)="select(o.value, o.count)">
                  <div class="filter-option-checkbox">
                    <checkbox [isChecked]="isSelected(o.value)"></checkbox>
                  </div>
                  <div class="filter-option-label column column-center-left fill-space">{{ o.value }}</div>
                  <div class="filter-option-count center">{{ o.count ? o.count : 0 }}</div>
                </div>
              </div>
            </ng-container>
          </div>

        </div>
    </ng-container>

</div>
