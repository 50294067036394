<div class="applicant-resume overflow-hidden " *ngIf="resume$ | async as resume">
  <div class="column fill-space overflow-hidden">
    <div class="resume-content column scrollbar">
      <resume-info
          [info]="resume.resumeAnalysis?.info"
          [image]="resume.image">
      </resume-info>
      <ng-container *ngIf="resume.resumeAnalysis">
        <resume-overview
            *ngIf="resume.resumeAnalysis.overview"
            [overview]="resume.resumeAnalysis.overview">
        </resume-overview>
        <div>
          <resume-insights
              *ngIf="resume.resumeAnalysis.overview.indicators"
              [indicators]="resume.resumeAnalysis.overview.indicators">
          </resume-insights>
        </div>
        <div class="row">
          <resume-content [content]="resume.resumeAnalysis.content"></resume-content>
        </div>
      </ng-container>
    </div>
  </div>
</div>
