import { Component } from '@angular/core';
import {PipelineService} from "../../../../../../services/pipeline.service";

@Component({
  selector: 'pipeline-applicant',
  templateUrl: './pipeline-applicant.component.html',
  styleUrls: ['./pipeline-applicant.component.css']
})
export class PipelineApplicantComponent {

  constructor(private pipelineService: PipelineService) {
  }

  hideApplicantInfo(){
    this.pipelineService.isInfoVisible = false;
  }

}
