<div class="resume-content-section column fill-space">

  <div class="resume-content-section-header fill-space">
    <div class="resume-content-section-header-icon center"> <img src="assets/images/section-projects.svg"/> </div>
    <div class="resume-content-section-header-title center"> Projects </div>
  </div>

  <div class="resume-content-section-data column fill-space">

    <empty-list text="No Projects" *ngIf="!projects?.length"></empty-list>

    <ng-container *ngIf="projects">
      <div class="resume-node column" *ngFor="let p of projects">
        <div class="resume-node-header">
          <div class="resume-node-icon"> <img src="assets/images/resume-list-projects.svg"/> </div>
          <div class="resume-node-header-content column center">
            <div class="resume-node-title capitalize column-center-left"> {{ p.name}} </div>
          </div>
        </div>
        <div class="resume-node-content column">
          <div class="resume-node-date">
            <div class="resume-node-date-icon center"> <img src="assets/images/calendar.svg"/> </div>
            <div class="resume-node-date-text gap-1 row-center-left">
              <div class="resume-node-info-data" > Start Date: {{ p.startDate ? p.startDate : 'Unknown' }} </div>
              <div class="resume-node-info-data" > End Date: {{ p.endDate ? p.endDate : 'Unknown' }} </div>
            </div>
          </div>
          <div class="projects-info column">
            <div class="resume-node-info capitalize">
              <div class="resume-node-info-label">  Role: </div>
              <div class="resume-node-info-data"> {{ p.role ? p.role : "Unknown" }} </div>
            </div>
            <div class="resume-node-info capitalize">
              <div class="resume-node-info-label">  Project Url: </div>
              <div class="resume-node-info-data"> {{ p.url  ? p.url  : "Unknown" }}  </div>
            </div>
          </div>
          <div class="resume-node-description" *ngIf="p.description">  {{ p.description }}</div>
          <div class="resume-node-description wrap" *ngIf="p.technologiesUsed && p.technologiesUsed.length">
            <div class="technology" *ngFor="let t of p.technologiesUsed"> {{ t }}</div>
          </div>
        </div>
      </div>
    </ng-container>

  </div>

</div>
