import { Component, Input } from '@angular/core';
import { IOrganizationWithRole, IUserInOrganization } from '../../../../../../../model/IUser';

@Component({
  selector: 'company-display',
  templateUrl: './company-display.component.html',
  styleUrls: ['./company-display.component.css'],
})
export class CompanyDisplayComponent {
  @Input() organizationWithRole: IOrganizationWithRole | undefined;
}
