import { Component, Input } from '@angular/core';
import { IResumeContentExperience } from '../../../../../../../../model/IResume';

@Component({
  selector: 'resume-content-experience',
  templateUrl: './resume-content-experience.component.html',
  styleUrls: ['./resume-content-experience.component.css'],
})
export class ResumeContentExperienceComponent {
  @Input() experience: IResumeContentExperience[] | null | undefined = [];
}
