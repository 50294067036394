import {Component, OnDestroy, OnInit} from "@angular/core";
import {BehaviorSubject, combineLatest, delay, filter, firstValueFrom, map, Observable, Subject, switchMap, takeUntil, tap} from "rxjs";
import {FormControl} from "@angular/forms";
import {EFilterType} from "../../model/enum/EFilterType";
import {IResume} from "../../../../../model/IResume";
import {ResumesApi} from "../../api/resumes.api";
import {FiltersService} from "../../services/filters/filters.service";
import {UploadComponent} from "../../common/upload/upload.component";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, NavigationEnd, Params, Router} from "@angular/router";
import {IndexApi} from "../../api/index.api";
import {IHit, ISearchIndexResponse, ISearchMultipleRequest} from "../../model/ITypesense";
import {StoreService} from "../../services/store.service";
import {UploadService} from "../../services/upload.service";
import {EStatus} from "../../../../../model/enums/EResumeStatuses";
import {environment} from "../../../environments/environment";

@Component({
    selector: "resumes",
    templateUrl: "./resumes.component.html",
    styleUrls: ["./resumes.component.css"]
})
export class ResumesComponent implements OnInit, OnDestroy {
    allResumes$: Observable<IResume[]> | undefined;
    resumes$: Observable<IResume[]> | undefined;
    resumes: IResume[] = [];
    selectedResumeId: string | undefined;
    unsubscribe$ = new Subject<void>();

    searchControl = new FormControl();
    searchTags: string[] = [];

    resumeIds$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]); // The filtered resume ids retrieved from the index
    currentUrlParams: Params | undefined;

    protected readonly EFilterType = EFilterType;

    constructor(
        private resumesApi: ResumesApi,
        private dialog: MatDialog,
        public filtersService: FiltersService,
        private route: ActivatedRoute,
        private router: Router,
        private indexApi: IndexApi,
        private store: StoreService,
        private uploadService: UploadService
    ) {

    }


    resumeFiltersOptionsExist(): boolean {
        for(let i= 0; i<this.filtersService.resumeFilters.length; i++){
            if(this.filtersService.resumeFilters[i].options?.length) {
                return true;
            }
        }
        return false;
    }

    showUpload(): void {
        this.dialog.open(UploadComponent, {});
    }

    refresh(){
        if(this.currentUrlParams) {
            this.getResumes(this.currentUrlParams).then(response => {
                const resumeIds = response.map(resume => resume.id);
                this.updateResumeIds(resumeIds);
            });
            this.filtersService.updateFilterCounts(this.filtersService.resumeFilters, this.currentUrlParams, environment.typesense.collections.resumes);
        }
    }

    updateResumeIds(newIds: string[]): void {
        this.resumeIds$.next(newIds);
    }

    updateQueryParams(searchTags: string[]){
        const queryParams: { [key: string]: string } = {};
        queryParams['search'] = this.searchTags.length ? this.searchTags.join(',') : '';

        // Update the URL with the new query parameters
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
            replaceUrl: true
        }).then();
    }

    addSearchTag(element: HTMLInputElement): void {
        this.searchTags.push(element.value);
        element.value = "";
        this.updateQueryParams(this.searchTags);
    }

    removeSearchTag(value: string) {
        let index = this.searchTags.indexOf(value);
        if(index !== -1) {
            this.searchTags.splice(index,1);
        }
        this.updateQueryParams(this.searchTags);
    }


    async getResumes(params: Params): Promise<IResume[]> {
        // TODO: remove problem with local functions
        // return await firstValueFrom(this.allResumes$!);

        // Build the query using the provided params
        let query: ISearchMultipleRequest = this.filtersService.buildQuery(params, this.filtersService.resumeFilters);

        try {
            const response = await firstValueFrom(this.indexApi.searchMultiple(query));

            // Get the resumes from the index results object
            const hits = response.results.flatMap( (result: ISearchIndexResponse) => result.hits).map( (hit: IHit) => hit.document);

            // Remove duplicates
            const uniqueResumes = hits.filter((document: IResume, index: number, self: IResume[]) =>
                index === self.findIndex(t => t.id === document.id)
            );
            return uniqueResumes;
        } catch (error) {
            console.error("Error fetching resumes:", error);
            throw error; // or handle it as preferred
        }
    }



    ngOnInit() {

        this.allResumes$ = this.resumesApi.getAll([ { field: 'status', condition: '==', value: EStatus.COMPLETED } ]);
        /*this.resumes$ = this.allResumes$;*/
        this.resumes$ = combineLatest([
            this.allResumes$,
            this.resumeIds$,
        ]).pipe(
            map(([resumes, resumeIds]) =>
                resumes.filter(resume => resumeIds.includes(resume.id))
            ),
            map(resumes =>
                resumes.sort((a, b) => b.creationTime.toMillis() - a.creationTime.toMillis())
            )
        );

        this.route.queryParams.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe( params => {
            this.currentUrlParams = params;
            this.getResumes(params).then(response => {
                const resumeIds = response.map(resume => resume.id);
                this.updateResumeIds(resumeIds);
            });
            // Initialize search tags from the url upon refresh
            this.searchTags = this.filtersService.getSearchParamsFromUrl(params);
            this.filtersService.updateFilterCounts(this.filtersService.resumeFilters, params, environment.typesense.collections.resumes);
        });

        // TODO: Find a way to get rid of the delay
        this.uploadService.resumes$?.pipe(delay(2000)).subscribe( resumes => {
            this.refresh();
            this.filtersService.updateFilters(this.filtersService.resumeFilters, environment.typesense.collections.resumes);
        })

        // This defines the currently selected resume based on the url param.
        this.router.events
        .pipe(
            filter((event) => event instanceof NavigationEnd),
            takeUntil(this.unsubscribe$),
            map(() => this.route),
            map((route) => { while (route.firstChild) route = route.firstChild; return route; }),
            filter((route) => route.outlet === "primary"),
            switchMap((route) => route.paramMap))
        .subscribe((params) => {
            const resumeId = params.get("resumeId");
            this.selectedResumeId = resumeId ? resumeId : undefined;
        });


    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
