import { ChangeDetectorRef, Component, HostListener, Input } from "@angular/core";
import { EUploadStatus, UploadService } from "../../services/upload.service";
import { StoreService } from "../../services/store.service";
import { UtilService } from "../../services/util.service";
import { MatDialog } from "@angular/material/dialog";
import { StorageApi } from "../../api/storage.api";
import { IResume, IResumeFile } from "../../../../../model/IResume";
import { ResumesApi } from "../../api/resumes.api";
import { EStatus } from "../../../../../model/enums/EResumeStatuses";
import { ITrigger, ITriggerForImageExtraction } from "../../model/ITrigger";
import { TriggersApi } from "../../api/triggers.api";
import { EFirebaseFunctionNames } from "../../../../../model/enums/EFirebaseFunctionNames";
import {IJob} from "../../../../../model/IJob";

@Component({
  selector: "upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.css"],
})
export class UploadComponent {
  @Input() job: IJob | undefined;

  isLoading: boolean = false;
  protected readonly EUploadStatus = EUploadStatus;


  constructor(
    private storageApi: StorageApi,
    public uploadService: UploadService,
    private cdRef: ChangeDetectorRef,
    private resumesApi: ResumesApi,
    private store: StoreService,
    private utilService: UtilService,
    private triggersApi: TriggersApi,
  ) {}

  // The files to be uploaded
  files: File[] = [];

  // Remove a file from the 'files' list
  removeFile(event: Event, index: number): void {
    event.preventDefault();
    event.stopPropagation();
    this.files.splice(index, 1);
  }

  @HostListener("drop", ["$event"])
  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer!.files;
    for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);
    }
  }

  @HostListener("dragover", ["$event"])
  public onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);
    }
  }

  // Creates a new Resume in firestore. Returns the id of the document or null if the process fails
  async createResume(file: File): Promise<IResume> {
    const resumeFile: IResumeFile = {
      originalName: this.utilService.getFilenameWithoutExtension(file.name),
      type: this.utilService.getFilenameExtension(file.name),
      size: file.size,
    };
    const resume: Omit<IResume, "id"> = {
      organizationId: this.store.organizationId!,
      createdBy: this.store.userId!,
      status: EStatus.UPLOADING,
      creationTime: this.resumesApi.getServerTimestamp(),
      file: resumeFile,
    };
    if(this.job?.id) {
      resume.jobId = this.job.id
    }
    const docRef = await this.resumesApi.create(resume);
    return { id: docRef.id, ...resume };
  }

  async completeUploadProcess(resume: IResume) {
    const filePath = `${this.storageApi.getResumeStoragePath()}/${resume.id}.${resume.file!.type}`;
    const fileUrl = await this.storageApi.getFileUrl(filePath);
    const readyResume: Partial<IResume> = {
      status: EStatus.STORED,
      file: { ...resume.file, path: filePath, url: fileUrl },
    };
    await this.resumesApi.set(resume.id, readyResume);
    const trigger: ITrigger = {
      organizationId: this.store.organizationId!,
      resumeId: resume.id,
    };

    const imageTrigger: ITriggerForImageExtraction = {
      ...trigger,
      imageStatus: "pending", // This is needed to filter the triggers in our image processing function
    };

    // Trigger Firebase Functions
    await this.triggersApi.create(EFirebaseFunctionNames.RESUME_PARSING, trigger);
    await this.triggersApi.create(EFirebaseFunctionNames.RESUME_IMAGE_EXTRACTION, imageTrigger);

    delete this.uploadService.uploads[resume.id];
  }

  async uploadFiles() {
    if (!this.files || !this.files.length) {
      return;
    }
    for (let i = 0; i < this.files.length; i++) {
      // The file we are uploading
      let file = this.files[i];

      // Create a new resume in firestore
      let resume = await this.createResume(file);

      // Upload the file. Creates a new upload Task that will handle the upload progress for each resume
      let resumeFileName = resume.id + "." + resume.file.type;
      const uploadTask = this.storageApi.uploadFile(this.storageApi.getResumeStoragePath(), resumeFileName, file);

      // Update the upload service (used to display progress)
      this.uploadService.uploads[resume.id] = {
        file: file,
        progress: 0,
        uploadTask: uploadTask,
        status: EUploadStatus.PENDING,
      };
      let upload = this.uploadService.uploads[resume.id];

      // Use the uploadTask events to handle changes in the upload process
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.uploadService.uploads[resume.id].progress = progress;
          this.uploadService.uploads[resume.id].status = EUploadStatus.UPLOADING;
          this.cdRef.detectChanges();
        },
        (error) => {
          console.error("Upload failed:", error);
        },
        () => {
          this.completeUploadProcess(resume);
        },
      );
    }
    this.files = [];
  }

  ngOnInit(): void {}
}
