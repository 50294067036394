import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
})
export class ProgressBarComponent implements OnInit {
  @Input() progress: number = 0;
  @Input() height: string = '5px';
  @Input() progressClass: string = 'progress-bar-default';

  constructor() {}

  ngOnInit(): void {}
}
