import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ISearchMultipleRequest} from "../model/ITypesense";

@Injectable({
  providedIn: 'root'
})
export class IndexApi {

  options = {
    headers: { 'X-TYPESENSE-API-KEY': environment.typesense.searchKey }
  };


  constructor(private http: HttpClient) {
  }

  searchMultiple(data: ISearchMultipleRequest): Observable<any> {
    return this.http.post(environment.typesense.api + '/multi_search', data, this.options);
  }

  search(collection: string, query: string = '*', params: string = ''): Observable<any> {
    return this.http.get(environment.typesense.api + '/collections/' + collection + '/documents/search?q=' + query + params, this.options);
  }

  //TODO max_facet_values: Consider the best option for this
  getFacet(collection: string, facetBy: string,  facetQuery: string = '', params: string = ''): Observable<any> {
    let query = 'q=*&facet_by=' + facetBy + '&facet_query=' + facetQuery + '&per_page=0' + '&max_facet_values=100' + params;
    return this.http.get(environment.typesense.api + '/collections/' + collection + '/documents/search?' + query, this.options);
  }


}
