import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'rate-bar',
  templateUrl: './rate-bar.component.html',
  styleUrls: ['./rate-bar.component.css'],
})
export class RateBarComponent implements OnChanges {
  @Input() classifications: string[] = [];
  @Input() rating: string = '';

  indicatorPosition: number = 0;
  barColor: string = '';

  ngOnChanges(): void {
    this.updateIndicator();
  }

  updateIndicator(): void {
    const index = this.classifications.indexOf(this.rating);
    const percentage = (index / (this.classifications.length - 1)) * 100;
    this.indicatorPosition = percentage;
    this.barColor = this.getBarColor(percentage);
  }

  getBarColor(percentage: number): string {
    if (percentage < 25) return 'red';
    else if (percentage < 50) return 'orange';
    else if (percentage < 70) return 'yellow';
    else if (percentage < 80) return 'purple';
    else if (percentage < 90) return 'blue';
    else return 'green';
  }
}
