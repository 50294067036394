<div class="resume-overview-top-skills column">

  <div class="resume-overview-title"> Radar </div>

  <div class="center">
    <canvas baseChart [data]="chart.data" [options]="chart.options" [type]="'radar'"> </canvas>
  </div>

</div>


