<div class="notes column fill-space overflow-hidden">

  <div class="notes-content column fill-space  scrollbar">
    <ng-container *ngIf="notes$ | async as notes">
      <applicant-note *ngFor="let n of notes" [note]="n" [applicantId]="applicantId"></applicant-note>
    </ng-container>
  </div>

  <div class="notes-form column">
    <ng-container *ngIf="!isLoading">
      <div class="notes-form-header">
        <div class="notes-form-title fill-space row-center-left"> Add New Note </div>
        <!--<div class="note-type-info center"> {{ note.type }}</div>
        <div class="note-options">
            <div class="note-option center" [class]="{ 'note-option-selected': note.type === o }" *ngFor="let o of options" (click)="note.type = o">
              <img [src]="'assets/images/note-' + o + '.svg'">
            </div>
        </div>-->
      </div>
      <div>
        <div class="form-field fill-space space-right-1">
          <textarea class="fill-space" rows="3" [(ngModel)]="note.text"></textarea>
        </div>
        <div class="add-note-button center gap-1" (click)="createNote()">
          <!--<div class="fill-space center" > Add Note </div>-->
          <div class="center"> <img src="assets/images/send-white.svg"/></div>
        </div>
      </div>
    </ng-container>
    <div class="fill-space space-top-bottom-1 center" *ngIf="isLoading"> <loader></loader> </div>
  </div>


</div>
