import { Component, Input } from '@angular/core';
import { IResumeLanguage } from '../../../../../../../../model/IResume';
import { EResumeLanguageProficiencyLevel } from '../../../../../../../../model/enums/EResumeLanguageProficiencyLevel';

@Component({
  selector: 'resume-content-languages',
  templateUrl: './resume-content-languages.component.html',
  styleUrls: ['./resume-content-languages.component.css'],
})
export class ResumeContentLanguagesComponent {
  @Input() languages: IResumeLanguage[] | null | undefined = [];
  protected readonly EResumeLanguageProficiencyLevel = EResumeLanguageProficiencyLevel;
}
