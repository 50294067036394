<ng-container *ngIf="job$ | async as job">
  <div class="job-applicant column scrollbar" *ngIf="applicant$ | async as applicant">
    <div class="job-applicant-header column fill-space" *ngIf="category$ | async as category">

      <div class="applicant fill-space">
        <div class="applicant-image center"> <resume-image [image]="applicant.image"></resume-image> </div>
        <div class="applicant-content fill-space column">
          <div class="applicant-content-name">{{ applicant.info?.name }}</div>
          <div class="applicant-content-job row">
            <div class="applicant-content-job-position center"> {{ applicant.info?.lastJobPosition }} </div>
            <div class="applicant-content-job-dot center"> <img src="assets/images/dot-gray.svg" /> </div>
            <div class="applicant-content-job-company center"> {{ applicant.info?.lastJobCompany }} </div>
          </div>
          <div class="applicant-content-education"> {{ applicant.info?.educationInstitution }} </div>
          <div class="applicant-content-link row">
            <div class="applicant-content-link-icon center"> <img src="assets/images/open-link.svg" /> </div>
            <div class="applicant-content-link-text" (click)="openFile('organizations/'+store.organizationId!+'/resumes/'+applicant.resumeId+'_original.pdf')">Open Resume</div>
          </div>
        </div>
        <div class="applicant-score column center">
          <div class="column">
            <div class="row">
              <div class="applicant-score-icon center"> <img [src]="'assets/images/' + category?.icon" /> </div>
              <div class="applicant-score-value center"  [class]="category?.styleClass"> {{ applicant.matchAnalysis?.overallScore }}% </div>
              <div class="applicant-score-label row-center-right" [class]="category?.styleClass"> {{ category?.label }} </div>
            </div>
            <div class="applicant-score-progress fill-space">
              <progress-bar class="fill-space"
                            *ngIf="applicant && applicant.matchAnalysis && applicant.matchAnalysis.overallScore"
                            [progress]="applicant.matchAnalysis.overallScore"
                            [height]="'8px'"
                            [progressClass]="category?.styleClass + '-bg'">
              </progress-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <job-applicant-match [job]="job" [matchAnalysis]="applicant.matchAnalysis"></job-applicant-match>
  </div>
</ng-container>
