import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { IJob, IJobDetails, IJobLocation, IJobSalary } from '../../../../../../../../model/IJob';
import { JobService } from '../../../../../services/job.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EEmploymentType } from '../../../../../../../../model/enums/EEmploymentType';
import { EPayFrequency } from '../../../../../../../../model/enums/EPayFrequency';
import currencies from '../../../../../../assets/currencies-mini.json';
import { JobsApi } from '../../../../../api/jobs.api';
import { CanComponentDeactivate } from '../../../../../guards/unsaved-changes.guard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { EJobWorkLocationType } from '../../../../../../../../model/enums/EJobWorkLocationType';
import { PortalJobsApi } from '../../../../../api/portalJobs.api';

@Component({
  selector: 'job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  jobDetails$: Observable<IJobDetails | undefined> | undefined;
  jobDetails: IJobDetails | undefined;
  job: IJob | undefined;

  workArrangements = Object.values(EJobWorkLocationType);
  employmentTypes = Object.values(EEmploymentType);
  payFrequencies = Object.values(EPayFrequency);
  currencies = currencies;

  jobForm: FormGroup = this.fb.group({
    id: [null],
    title: ['', Validators.required],
    location: this.fb.group({
      country: [''],
      state: [''],
      city: ['']
    }),
    jobSetting: [null, Validators.required],
    jobType: [null, Validators.required],
    salary: this.fb.group({
      min: [0, [Validators.min(0)]],
      max: [0, [Validators.min(0)]],
      currency: ['0'],
      frequency: [EPayFrequency.HOUR]
    }),
    department: [''],
    description: ['', Validators.required],
    isPublic: [false, Validators.required]
  });

  initialFormValues: any;
  showSubmitButton: boolean = false;
  isSubmitting: boolean = false;
  unsubscribe$ = new Subject<void>();
  jobId: string | null | undefined;
  constructor(
    private jobService: JobService,
    private jobApiService: JobsApi,
    private portalJobsApi: PortalJobsApi,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.jobService.job$?.subscribe((job) => {
      this.job = job;
    });

    this.jobDetails$ = this.route.paramMap.pipe(
      takeUntil(this.unsubscribe$),
      switchMap((params) => {
        this.jobId = params.get('jobId');
        return this.jobId ? this.portalJobsApi.getOne(this.jobId) : of(undefined);
      })
    );

    this.jobDetails$.subscribe((jobDetails) => {
      this.jobDetails = jobDetails;
      this.initializeForm(jobDetails);
    });

    this.jobForm.valueChanges.subscribe((values) => {
      this.showSubmitButton = JSON.stringify(this.initialFormValues) !== JSON.stringify(this.jobForm.getRawValue());
    });
  }

  get titleControl() {
    return this.jobForm.get('title');
  }
  get jobIdControl() {
    return this.jobForm.get('id');
  }
  get currencyControl() {
    return this.jobForm.get('salary')?.get('currency');
  }
  get descriptionControl() {
    return this.jobForm.get('description') as FormControl;
  }
  get jobSettingControl() {
    return this.jobForm.get('jobSetting') as FormControl;
  }
  get jobTypeControl() {
    return this.jobForm.get('jobType') as FormControl;
  }

  getJobDetails() {
    return this.makeDto();
  }

  onSubmit(): void {
    if (this.jobForm.invalid) return;
    if (!this.jobIdControl?.value) return;

    this.jobForm.disable();
    this.isSubmitting = true;

    const dto = this.makeDto();
    const id = this.jobIdControl.value as string;
    this.portalJobsApi
      .set(id, dto)
      .then(() => {
        console.log('Successfully updated job details.');
        this.snackBar.open('Job Details Updated Successfully!', undefined, {
          duration: 3000
        });
      })
      .catch((error) => {
        console.error(error);
        this.snackBar.open('An error occurred while updating job details. Please try again.', undefined, {
          duration: 3000
        });
      })
      .finally(() => {
        this.isSubmitting = false;
        this.jobForm.enable();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeForm(jobDetails: IJobDetails | undefined) {
    const jobCurrency = this.currencies.findIndex((cur) => cur.code === jobDetails?.salary?.currency);

    this.jobForm.patchValue({
      id: this.jobId ?? null,
      title: jobDetails?.title ?? '',
      description: jobDetails?.description ?? '',
      jobSetting: jobDetails?.jobSetting ?? '',
      jobType: jobDetails?.jobType ?? '',
      department: jobDetails?.department ?? '',
      salary: {
        min: jobDetails?.salary?.min ?? '',
        max: jobDetails?.salary?.max ?? '',
        frequency: jobDetails?.salary?.frequency ?? EPayFrequency.HOUR,
        currency: jobCurrency === -1 ? '0' : jobCurrency.toString()
        // if there's no server data for currency initialize with euro (45 on currencies, 0 on currencies-mini)
      },
      location: {
        country: jobDetails?.location?.country ?? '',
        state: jobDetails?.location?.state ?? '',
        city: jobDetails?.location?.city ?? ''
      },

      isPublic: jobDetails?.isPublic ?? false
    });

    this.initialFormValues = this.jobForm.getRawValue();

    this.showSubmitButton = JSON.stringify(this.initialFormValues) !== JSON.stringify(this.jobForm.getRawValue());
  }

  canSubmit() {
    return this.showSubmitButton && this.jobForm.valid;
  }

  private makeDto() {
    const formValues = this.jobForm.getRawValue();

    const jobLocation = {} as IJobLocation;

    if (formValues.location.country) {
      jobLocation.country = formValues.location.country;
    }
    if (formValues.location.state) {
      jobLocation.state = formValues.location.state;
    }
    if (formValues.location.city) {
      jobLocation.city = formValues.location.city;
    }

    const currency = this.currencies[Number(formValues.salary.currency)];

    const jobSalary = {
      currency: currency.code,
      frequency: formValues.salary.frequency
    } as IJobSalary;

    if (formValues.salary.min) {
      jobSalary.min = formValues.salary.min;
    }
    if (formValues.salary.max) {
      jobSalary.max = formValues.salary.max;
    }

    const isPublic = this.jobForm.get('isPublic')?.value as boolean;
    const publicListingChanged = this.initialFormValues?.isPublic !== isPublic;

    const jobDetails = {
      title: formValues.title,
      creationTime: this.jobDetails?.creationTime ? this.jobDetails?.creationTime : this.jobApiService.getServerTimestamp(),
      description: formValues.description,
      department: formValues.department,
      jobType: formValues.jobType,
      jobSetting: formValues.jobSetting,
      isPublic: isPublic,
      jobStatus: this.job!.jobStatus
    } as IJobDetails;

    if (Object.keys(jobLocation).length) {
      jobDetails.location = jobLocation;
    }

    if (formValues.salary.min || formValues.salary.max) {
      jobDetails.salary = jobSalary;
    }

    if (publicListingChanged) {
      if (isPublic) {
        jobDetails.datePosted = this.jobApiService.getServerTimestamp();
      } else {
        jobDetails.datePosted = this.jobDetails?.datePosted;
      }
    }

    if (!publicListingChanged && this.jobDetails?.datePosted) {
      jobDetails.datePosted = this.jobDetails?.datePosted;
    }
    return jobDetails;
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !this.showSubmitButton;
  }
}
