import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UploadComponent } from "../../upload/upload.component";
import { ActivatedRoute } from "@angular/router";
import {
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  startWith,
  switchMap,
} from "rxjs";
import { UploadService } from "../../../services/upload.service";
import { IResume } from "../../../../../../model/IResume";
import { ResumesApi } from "../../../api/resumes.api";
import { propertyOf } from "../../../../../../model/utils";
import { EStatus } from "../../../../../../model/enums/EResumeStatuses";

@Component({
  selector: "upload-button",
  templateUrl: "./upload-button.component.html",
  styleUrls: ["./upload-button.component.css"],
})
export class UploadButtonComponent {


  constructor(private dialog: MatDialog, public uploadService: UploadService) {

  }

  showUpload(): void {
    this.dialog.open(UploadComponent, {});
  }

  ngOnInit(): void {

  }
}
