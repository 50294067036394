import {Component, Input} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {IUserInOrganization} from "../../../../../../model/IUser";
import {EUserRole} from "../../../../../../model/enums/EUserRole";
import {OrganizationUsersApi} from "../../../api/organization.users.api";

@Component({
  selector: "app-user-delete",
  templateUrl: "./user-delete.component.html",
  styleUrls: ["./user-delete.component.css"],
})
export class UserDeleteComponent {
  @Input() user: IUserInOrganization | undefined;
  isLoading: boolean = false;

  constructor(private organizationUsersApi: OrganizationUsersApi, private dialogRef: MatDialogRef<UserDeleteComponent>) {

  }

  deleteUser(): void {
    if (!this.user || !this.user.id || this.user.role === EUserRole.OWNER) { return; }
      this.isLoading = true;
      this.organizationUsersApi.delete(this.user.id).then(response => {
        this.dialogRef.close();
      }).catch(error=> {
        console.log(error);
      }).finally(()=>{
        this.isLoading = false;
      });
  }
}
