<div class="job-menu">
  <div class="job-menu-item" routerLinkActive="job-menu-item-selected" routerLink="applicants">
    Applicants
  </div>
  <div class="job-menu-item" routerLinkActive="job-menu-item-selected" routerLink="pipeline">
    Pipeline
  </div>
  <div class="job-menu-item" routerLinkActive="job-menu-item-selected" routerLink="job-description">
    Job Description
  </div>
</div>
