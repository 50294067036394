import { Component, Input } from '@angular/core';

@Component({
  selector: 'resume-content-hobbies-and-interests',
  templateUrl: './resume-content-hobbies-and-interests.component.html',
  styleUrls: ['./resume-content-hobbies-and-interests.component.css'],
})
export class ResumeContentHobbiesAndInterestsComponent {
  @Input() hobbiesAndInterests: string[] | null | undefined = [];
}
