import { Component } from "@angular/core";
import { PaymentsService } from "../../../services/payments.service";

@Component({
  selector: "checkouts-success",
  templateUrl: "./checkouts-success.component.html",
  styleUrls: ["./checkouts-success.component.css"],
})
export class CheckoutsSuccessComponent {
  constructor(private paymentService: PaymentsService) {}
}
