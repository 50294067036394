import { Injectable } from "@angular/core";
import { Auth, user } from "@angular/fire/auth";
import {
  combineLatest,
  filter,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
} from "rxjs";
import {
  IOrganizationWithRole,
  IUserInOrganizationFirebase,
  IUserWithRoles,
} from "../../../../model/IUser";
import { IOrganization } from "../../../../model/IOrganization";
import { UsersApi } from "../api/users.api";
import { OrganizationsApi } from "../api/organizations.api";

@Injectable({
  providedIn: "root",
})
export class UserWithOrganizationsService {
  user$ = user(this.auth);
  public userWithResolvedOrganizationsWithRole$: Observable<IUserWithRoles>;
  public userFavoriteOrganizations$: Observable<string | undefined>;

  constructor(
    private auth: Auth,
    private userService: UsersApi,
    private organizationService: OrganizationsApi,
  ) {
    this.userFavoriteOrganizations$ = this.user$.pipe(
      filter((user) => !!user), // Filter out null values,
      switchMap((user) => userService.getOne(user!.uid)),
      map((hireFoxUser) => {
        return hireFoxUser?.favoriteOrganization;
      }),
      tap((favoriteOrgId) => {}),
    );

    this.userWithResolvedOrganizationsWithRole$ = this.user$.pipe(
      filter((user) => !!user), // Filter out null values,
      switchMap((user) => userService.getOne(user!.uid)),
      map((hireFoxUser) => {
        // Get organizations collection from user

        let orgAndRoleSubscriptions: Observable<
          [IOrganization | undefined, IUserInOrganizationFirebase | undefined]
        >[] = [];
        if (hireFoxUser?.organizations?.length ?? 0 > 0) {
          for (const orgId of hireFoxUser!.organizations) {
            const orgRef = organizationService.getOne(orgId);
            const userInOrgRef = organizationService.getOneUser(
              orgId,
              hireFoxUser!.id,
            );

            orgAndRoleSubscriptions.push(combineLatest([orgRef, userInOrgRef]));
          }
        }
        return { hireFoxUser: hireFoxUser!, orgAndRoleSubscriptions };
      }),
      mergeMap(({ hireFoxUser, orgAndRoleSubscriptions }) => {
        // If there are no organizations, return the user with an empty resolvedOrganizationsWithRole array
        if (orgAndRoleSubscriptions.length === 0) {
          return of({
            ...hireFoxUser,
            resolvedOrganizationsWithRole: [],
            email: auth.currentUser?.email! ?? hireFoxUser.email,
          } satisfies IUserWithRoles);
        }

        // combineLatest org subscriptions into one and maintain the user object as well
        return combineLatest(orgAndRoleSubscriptions).pipe(
          map((organizationAndRoles) => ({
            hireFoxUser,
            organizationAndRoles,
          })),
          map(({ hireFoxUser, organizationAndRoles }) => {
            return {
              hireFoxUser,
              organizationAndRoles: organizationAndRoles.map(
                ([org, userInOrg]) =>
                  ({
                    ...userInOrg,
                    ...org,
                  }) as IOrganizationWithRole,
              ),
            };
          }),
          map(({ hireFoxUser, organizationAndRoles }) => {
            // combine user and organizations into one object
            return {
              ...hireFoxUser,
              resolvedOrganizationsWithRole: organizationAndRoles,
              email: auth.currentUser?.email! ?? hireFoxUser.email,
            } satisfies IUserWithRoles;
          }),
        );
      }),
      tap((userWithResolvedOrganizationsWithRole) => {}),
    );
  }
}
