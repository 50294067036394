import {Injectable} from '@angular/core';
import {EResumeIndicator} from '../../../../model/enums/EResumeIndicator';
import {EResumeIndicatorPolarity} from "../../../../model/enums/EResumeIndicatorPolarity";

export interface IIndicatorData {
  type: EResumeIndicator;
  description: string;
  polarity: EResumeIndicatorPolarity
}

@Injectable({
  providedIn: 'root',
})
export class IndicatorService {

  indicatorData: { [ key: string ] : IIndicatorData } = {
    diverseBackground: {
      type: EResumeIndicator.DIVERSE_BACKGROUND,
      description: 'Experience in multiple unrelated fields, potentially questioning focus or domain expertise.',
      polarity: EResumeIndicatorPolarity.NEGATIVE
    },
    jobHopping: {
      type: EResumeIndicator.JOB_HOPPING,
      description: 'Frequent job changes in short time spans, suggesting potential commitment issues.',
      polarity: EResumeIndicatorPolarity.NEGATIVE
    },
    careerShifts: {
      type: EResumeIndicator.CAREER_SHIFTS,
      description: 'Significant changes in career direction, hinting at possible lack of commitment or clarity',
      polarity: EResumeIndicatorPolarity.NEGATIVE
    },
    lackOfPracticalExperience: {
      type: EResumeIndicator.LACK_OF_PRACTICAL_EXPERIENCE,
      description: 'Experience in multiple unrelated fields, potentially questioning focus or domain expertise.',
      polarity: EResumeIndicatorPolarity.NEGATIVE
    },
    unfinishedDegrees: {
      type: EResumeIndicator.UNFINISHED_DEGREES,
      description: 'Starting but not completing a degree might raise questions, though the reasons can be multifaceted and valid.',
      polarity: EResumeIndicatorPolarity.NEGATIVE
    },
    extendedGraduation: {
      type: EResumeIndicator.EXTENDED_GRADUATION,
      description: "This term suggests that the candidate took more time than typically expected to complete their degree. It's still important to approach this with an open mind, as there can be valid reasons for extended study durations.",
      polarity: EResumeIndicatorPolarity.NEGATIVE
    },
    quantifiableAchievements: {
      type: EResumeIndicator.QUANTIFIABLE_ACHIEVEMENTS,
      description: 'Quantifiable achievements at previous roles (e.g., "increased sales by 20%")',
      polarity: EResumeIndicatorPolarity.POSITIVE
    },
    longTermCommitment: {
      type: EResumeIndicator.LONG_TERM_COMMITMENT,
      description: 'Having spent several years at a specific job, indicating loyalty and stability.',
      polarity: EResumeIndicatorPolarity.POSITIVE
    },
    leadership: {
      type: EResumeIndicator.LEADERSHIP,
      description: 'Holding managerial or supervisory roles, leading projects, mentoring junior team members, or being recognized as a team lead.',
      polarity: EResumeIndicatorPolarity.POSITIVE
    },
    teamPlayer: {
      type: EResumeIndicator.TEAM_PLAYER,
      description: 'Being a part of team projects for extended periods, receiving recognitions or awards as a team, or consistently working in environments that require collaboration',
      polarity: EResumeIndicatorPolarity.POSITIVE
    },
    educationExcellence: {
      type: EResumeIndicator.EDUCATION_EXCELLENCE,
      description: 'High academic performance is demonstrated by achieving top grades, high GPA scores, excelling in academically rigorous programs, or receiving awards and honors.',
      polarity: EResumeIndicatorPolarity.POSITIVE
    },
    poorResumeQuality: {
      type: EResumeIndicator.POOR_RESUME_QUALITY,
      description: 'Resumes with spelling/grammar mistakes, extensive use of cliché words, unclear sentence structures, and/or unprofessional formatting.',
      polarity: EResumeIndicatorPolarity.NEGATIVE
    }
  };

  constructor() {}
}
