<div class="pipeline-stage-applicant column" *ngIf="applicant">

  <div>
    <div class="column fill-space">
      <div class="pipeline-stage-applicant-header">
        <div class="pipeline-stage-applicant-photo column"> <resume-image [image]="applicant.image" [defaultProfileImage]="'user-pipeline.svg'"></resume-image> </div>
        <div class="pipeline-stage-applicant-name">{{applicant.info?.name?.toLowerCase()}} </div>
      </div>
      <!--<div class="pipeline-stage-applicant-name">{{applicant.pipelineStage}} {{applicant.pipelineStageIndex}} </div>-->
      <div class="pipeline-stage-applicant-header-details column">
        <div class="pipeline-stage-applicant-job">
          {{ applicant.info?.lastJobPosition ? applicant.info?.lastJobPosition: 'No work experience'}} &bullet; {{applicant.info?.lastJobCompany ? applicant.info?.lastJobCompany : 'No Company Info'}}
        </div>
        <div class="pipeline-stage-applicant-education">{{applicant.info?.educationInstitution}} </div>
      </div>
      <div class="pipeline-applicant-buttons">
        <div class="pipeline-applicant-button fill-space-equal gap-2 center">
          <div class="gap-1">
            <div class="pipeline-applicant-button-icon center"> <img [src]=" ratingsPositiveCount ? 'assets/images/thumbs-up.svg' : 'assets/images/thumbs-up-empty.svg'" /> </div>
            <div class="pipeline-applicant-button-text center"> {{ratingsPositiveCount}} </div>
          </div>
          <div class="gap-1">
            <div class="pipeline-applicant-button-icon center"> <img [src]=" ratingsNegativeCount ? 'assets/images/thumbs-down.svg' : 'assets/images/thumbs-down-empty.svg'" /> </div>
            <div class="pipeline-applicant-button-text center"> {{ratingsNegativeCount}} </div>
          </div>
        </div>
        <div class="pipeline-applicant-button fill-space-equal gap-1 center">
          <div class="pipeline-applicant-button-icon center"> <img [src]=" notesCount ? 'assets/images/notes.svg' : 'assets/images/notes-empty.svg'"/> </div>
          <div class="pipeline-applicant-button-text center"> {{notesCount}} </div>
        </div>

      </div>
    </div>
  </div>


</div>
