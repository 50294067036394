<div class="match-section-header column">
    <div class="match-section-title"> {{ title }} </div>
    <div class="match-section-progress row row-center-left">
        <div class="match-section-progress-bar column center">
            <progress-bar class="fill-space column center"
                          [progress]="score"
                          [progressClass]="utilService.getCategoryForScore(score).styleClass +'-bg'">
            </progress-bar>
        </div>
        <div class="match-section-progress-percentage column center" [class]="utilService.getCategoryForScore(score).styleClass"> {{ score }}% </div>
    </div>
</div>

<div class="match-section-content column">

    <div class="score-justification column">
        <div class="justifications-section-title column column-center-left fill-space"> {{title}} Evaluation </div>
        <div class="justifications-empty center" *ngIf="!scoreJustification"> No Evaluation Results </div>
        <div class="score-evaluation-text column column-center-left"> {{ scoreJustification }}</div>
    </div>

    <div class="justifications column">
        <div class="justifications-section column">
            <div class="space-bottom-1">
                <div class="justifications-section-title column column-center-left fill-space"> Requirements Matching </div>
                <div class="match-section-matched-count row row-center-right">
                    <div class="match-section-matched-count-option">
                        <div class="match-section-matched-count-option-icon center"> <img src="assets/images/tick-green.svg" /> </div>
                        <div class="match-section-matched-count-option-label center">Matched:</div>
                        <div class="match-section-matched-count-option-value center"> {{ matched }} </div>
                    </div>
                    <div class="match-section-matched-count-option">
                        <div class="match-section-matched-count-option-icon center"> <img src="assets/images/x-gray.svg" /> </div>
                        <div class="match-section-matched-count-option-label center">Unmatched:</div>
                        <div class="match-section-matched-count-option-value center"> {{ unMatched }} </div>
                    </div>
                </div>
            </div>
            <div class="justifications-section-content column">
                <div class="justifications-empty center" *ngIf="!justifications.length"> No Requirements In This Section </div>
                <div class="justification-entry" *ngFor="let j of justifications; let index = index;">
                    <div class="justification-entry-icon center">
                        <img *ngIf="j.isMatched" src="assets/images/tick-green.svg" />
                        <img *ngIf="!j.isMatched" src="assets/images/x-gray.svg" />
                    </div>
                    <div class="justification-entry-content column">
                        <div class="justification-entry-content-description column column-center-left"> {{ j.requirement }} </div>
                        <div class="justification-entry-content-justification column column-center-left"> {{ j.justification }} </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>