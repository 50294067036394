<div id="portal-settings" class="column" *ngIf="!isLoading; else loading">
  <div class="row-center-space-between">
    <div class="column">
      <div class="settings-section-title">Enable Jobs Portal</div>
      <div class="sub-note">This will display all your public jobs to the jobs portal</div>
    </div>
    <toggle-button [formControl]="isPortalEnabled" (change)="togglePortal($event)"></toggle-button>
  </div>
  <form
    *ngIf="companyForm && isPortalEnabled.value === true"
    [formGroup]="companyForm"
    (ngSubmit)="onSubmit()"
    class="flex column space-top-bottom-1 gap-2">
    <div class="settings-section-title">Job Portal Settings</div>
    <div class="column gap-1">
      <div class="column">
        <label class="form-field-label" for="portal-endpoint">Portal Url *</label>
        <div class="sub-note">
          This is the url that candidates will use to access your jobs portal. Please use a name without spaces or special characters apart
          from hyphens. Example portal URL: my-company.
        </div>
      </div>
      <div class="form-field-input gap-1">
        <div class="portal-domain">
          <img src="assets/images/web.svg" alt="" />
          <span>{{ portalDomain }}/</span>
        </div>
        <input
          id="portal-endpoint"
          type="text"
          pattern="[A-Za-z0-9\-]+"
          oninput="this.value = this.value.replace(/[^A-Za-z0-9\-]/g, '')"
          formControlName="portalEndpoint"
          [ngClass]="{ invalid: portalEndpointControl?.invalid && portalEndpointControl?.touched }" />
        <span>/jobs</span>
      </div>
      <!--      <div *ngIf="portalEndpoint?.invalid && portalEndpoint?.dirty">{{ portalEndpoint?.value }} is not available</div>-->
      <div *ngIf="portalEndpointControl?.invalid && portalEndpointControl?.touched" class="form-error">
        <span *ngIf="portalEndpointControl?.hasError('required')"> Portal Endpoint is required. </span>
        <span *ngIf="!isPortalEndpointAvailable"> {{ portalEndpointControl!.value }} has already been taken </span>
      </div>
      <a
        class="button open-portal-button"
        [ngClass]="{ 'disabled-link': !organization?.portalConfiguration?.portalEndpoint }"
        [attr.href]="
          organization?.portalConfiguration?.portalEndpoint
            ? environment.portalUrl + '/' + organization?.portalConfiguration?.portalEndpoint + '/jobs'
            : null
        "
        target="_blank"
        >Open Jobs Portal</a
      >
    </div>

    <div class="column gap-1">
      <div class="column">
        <div class="form-field-label">Company Logo *</div>
        <div class="sub-note">Upload your company’s logo</div>
      </div>
      <file-picker [fileControl]="fileControl" (fileChange)="onFileChange($event)"> </file-picker>
    </div>

    <div class="column gap-1">
      <div class="form-field-label-black">Company Description & Contact Details</div>
      <div class="column gap-2">
        <div class="column">
          <label class="form-field-label" for="company-info">Company Description *</label>
          <div class="form-field">
            <textarea
              oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
              rows="5"
              class="fill-space"
              id="company-info"
              type="text"
              formControlName="companyInfo"
              [ngClass]="{ invalid: companyInfoControl?.invalid && companyInfoControl?.touched }"></textarea>
          </div>
          <div *ngIf="companyInfoControl?.invalid && companyInfoControl?.touched" class="form-error">
            <span *ngIf="companyInfoControl?.hasError('required')"> Company Description is required. </span>
          </div>
        </div>

        <!--      <div class="column gap-2" id="contact-information">-->
        <!--        <div class="row-1 gap-3">-->
        <div class="column contact-details">
          <label class="form-field-label" for="email">Email *</label>
          <div class="form-field-input">
            <input id="email" type="text" formControlName="email" [ngClass]="{ invalid: emailControl?.invalid && emailControl?.touched }" />
          </div>
          <div *ngIf="emailControl?.invalid && emailControl?.touched" class="form-error">
            <span *ngIf="emailControl?.hasError('required')"> Email is required. </span>
            <span *ngIf="emailControl?.hasError('email')"> Please enter a valid email address. </span>
          </div>
        </div>

        <div class="column contact-details">
          <label class="form-field-label" for="phone">Phone</label>
          <div class="form-field-input">
            <input id="phone" type="tel" formControlName="phone" />
          </div>
          <div *ngIf="phoneControl?.invalid && phoneControl?.touched" class="form-error">
            <span *ngIf="phoneControl?.hasError('required')"> Phone is required. </span>
          </div>
        </div>

        <div class="column contact-details">
          <label class="form-field-label" for="website">Website *</label>
          <div class="form-field-input">
            <input
              id="website"
              type="url"
              formControlName="website"
              placeholder="https://example.com"
              [ngClass]="{ invalid: websiteControl?.invalid && websiteControl?.touched }" />
          </div>
          <div *ngIf="websiteControl?.invalid && websiteControl?.touched" class="form-error">
            <span *ngIf="websiteControl?.hasError('required')"> Website is required. </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row gap-2 row-center-space-between">
      <div class="column">
        <div class="form-field-label">Auto Resume Analysis</div>
        <div class="sub-note">
          When enabled, HireFox will automatically analyze candidate resumes using our Resume Analyzer and Job Matching Algorithm to provide
          insights and recommendations. If disabled, resumes will be stored as files without analysis.
        </div>
      </div>
      <toggle-button formControlName="autoProcess"></toggle-button>
    </div>

    <button [disabled]="!canSubmit()" type="submit">
      <loader *ngIf="isSubmitting"></loader>
      Save
    </button>
  </form>

  <div *ngIf="!isPortalEnabled.value" id="disabled-portal" class="column center">
    <img src="assets/images/note-info.svg" alt="" />
    <div>No Public Portal Active</div>
    <div>
      Use the toggle above to enable your public portal and create a branded careers page where candidates can discover and apply to your
      job positions.
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="center">
    <loader></loader>
  </div>
</ng-template>