<div class="popup column">

  <div class="popup-header column">
    <div class="row">
      <div class="popup-header-title fill-space"> Add Applicants To Job </div>
      <div class="popup-header-close" mat-dialog-close>
        <img src="assets/images/close.svg" />
      </div>
    </div>
  </div>

  <div class="popup-content  overflow-hidden gap-2" >

     <div class="applicant-type-selector column center" (click)="showApplicantSelector()" mat-dialog-close>
       <div class="applicant-type-selector-image"><img src="assets/images/select-from-existing.svg"/> </div>
       <div class="applicant-type-selector-content column">
         <div class="applicant-type-selector-content-title center"> Select From Existing </div>
         <div class="applicant-type-selector-content-text center"> Choose from resumes that you have already uploaded and match them to this job.</div>
       </div>
     </div>

    <div class="applicant-type-selector column center" (click)="showUpload()" mat-dialog-close>
      <div class="applicant-type-selector-image"><img src="assets/images/upload-from-disk.svg"/> </div>
      <div class="applicant-type-selector-content column">
        <div class="applicant-type-selector-content-title center"> Upload From Disk </div>
        <div class="applicant-type-selector-content-text center"> Upload files from your disk and automatically match them to this job. </div>
      </div>
    </div>


  </div>

</div>

