import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { UtilService } from "../../../../../../services/util.service";
import {
  IJustification,
  ISkillsMatching,
} from "../../../../../../../../../model/IApplicant";
import { IQualification } from "../../../../../../model/IQualification";

@Component({
  selector: "job-match-skills",
  templateUrl: "./job-match-skills.component.html",
  styleUrls: [
    "./job-match-skills.component.css",
    "./../job-applicant-match.component.css",
  ],
})
export class JobMatchSkillsComponent implements OnInit, OnChanges {
  @Input() justifications: IJustification[] = [];
  @Input() score: number = 0;
  @Input() scoreJustification: string | undefined;
  @Input() skills: string[] = [];
  @Input() relevantSkills: ISkillsMatching[] = [];
  matched: number = 0;
  unMatched: number = 0;

  constructor(public utilService: UtilService) {}

  private updateMatchCounts() {
    this.matched = 0;
    this.unMatched = 0;
    for (let justification of this.justifications) {
      justification.isMatched ? this.matched++ : this.unMatched++;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["justifications"]) {
      this.updateMatchCounts();
    }
  }

  ngOnInit() {
    this.updateMatchCounts();
  }
}
