import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'progress-bar-segmented',
  templateUrl: './progress-bar-segmented.component.html',
  styleUrls: ['./progress-bar-segmented.component.css'],
})
export class ProgressBarSegmentedComponent implements OnInit, OnChanges {
  @Input() segments: number = 5;
  @Input() activeSegments: number = 0;
  @Input() dynamicColor: boolean = false; // If 'true' then the bar is colored dynamically based on level (E.g., level = 1 -> color = yellow)
  @Input() color: string = 'green';
  @Input() divider: number = 1; // Divide the active segments with this number to get the right class e.g., Active segments 10. divider 2. 10/2 = 5
  activeSegmentClass = 'green';
  normalSegmentsArray: number[] = [];
  activeSegmentsArray: number[] = [];

  updateBar() {
    this.normalSegmentsArray = Array.from({ length: this.segments - this.activeSegments }, (_, index: number) => index);
    this.activeSegmentsArray = Array.from({ length: this.activeSegments }, (_, index: number) => index);
    let segments =  Math.round(this.activeSegments/this.divider);
    this.activeSegmentClass = 'level-'+ segments;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateBar();
  }

  ngOnInit() {
    this.updateBar();
  }
}
