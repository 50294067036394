<div class="popup column" *ngIf="store.organization$ | async as organization">
    <div class="popup-header row">
        <div class="popup-header-title fill-space">Invite New User</div>
        <div class="popup-header-close" mat-dialog-close>
            <img src="../../../../assets/images/close.svg" />
        </div>
    </div>

    <div class="popup-loader row center" *ngIf="isLoading">
        <loader></loader>
    </div>

    <div class="popup-content user-form column" *ngIf="!isLoading">
        <div class="form-field column space-bottom-1">
            <div class="form-field-label">Email</div>
            <div class="form-field-input">
                <input class="fill-space" type="text" [(ngModel)]="email" />
            </div>
        </div>
        <div class="form-field column">

            <div class="form-field-label">Role</div>

            <div class="user-role-selector">
                <div class="role-selector-item column fill-space-equal" [class]="{'selected': role === EUserRole.ADMIN }" (click)="setRole(EUserRole.ADMIN)">
                    <div class="role-selector-item-icon center"><img src="assets/images/role-admin.svg"/> </div>
                    <div class="role-selector-item-role center"> Admin </div>
                </div>
                <div class="role-selector-item column fill-space-equal" [class]="{'selected': role === EUserRole.MANAGER }"  (click)="setRole(EUserRole.MANAGER)">
                    <div class="role-selector-item-icon center"> <img src="assets/images/role-hr-manager.svg"/> </div>
                    <div class="role-selector-item-role center"> HR Manager </div>
                </div>
            </div>
            <div class="info-panels">
                <div class="info" *ngIf="role === EUserRole.ADMIN"> <span class="semibold"> Admin: </span> Has access to all information. Can manage everything. </div>
                <div class="info" *ngIf="role === EUserRole.MANAGER"> <span class="semibold"> HR Manager: </span> Can upload resumes, create jobs and match candidates to jobs. Cannot manage users, organization or subscription data </div>
            </div>
        </div>
    </div>

    <div class="popup-buttons row" *ngIf="!isLoading">
        <div class="button button-gray" mat-dialog-close>Cancel</div>
        <div class="fill-space"></div>
        <div class="button button-action" (click)="inviteUser(organization)">Invite User</div>
    </div>
</div>
