import { Injectable } from '@angular/core';
import { addDoc, collection, collectionData, deleteDoc, doc, docData, Firestore, orderBy, query, where } from '@angular/fire/firestore';
import { FirebaseCollectionNames } from '../../../../model/FirebaseCollectionNames';
import { StoreService } from '../services/store.service';
import { Observable } from 'rxjs';
import { Api } from './api';
import { FirestoreFilter } from '../model/firestore.filter';
import { FirestoreSorting } from '../model/firestore.sorting';
import { QueryConstraint } from '@firebase/firestore';
import { IApplicantRating } from '../../../../model/IApplicant';

@Injectable({
  providedIn: 'root'
})
export class ApplicantRatingsApi extends Api<IApplicantRating> {
  constructor(
    private firestore: Firestore,
    private store: StoreService
  ) {
    super();
  }

  getAll(applicantId: string, filters: FirestoreFilter[] = [], sorting: FirestoreSorting[] = []) {
    const constraints: QueryConstraint[] = [
      ...filters.map((filter) => where(filter.field, filter.condition, filter.value)),
      ...sorting.map((sortCondition) => orderBy(sortCondition.field, sortCondition.order))
    ];
    const reference = query(collection(this.firestore, this.getPath(applicantId)), ...constraints);
    return collectionData<IApplicantRating>(reference as any, this.options);
  }

  getOne(applicantId: string, id: string): Observable<IApplicantRating | undefined> {
    const reference = doc(this.firestore, this.getPath(applicantId), id);
    return docData<IApplicantRating>(reference as any, this.options);
  }

  create(applicantId: string, item: Omit<IApplicantRating, 'id'>) {
    let reference = collection(this.firestore, this.getPath(applicantId));
    return addDoc(reference, item);
  }

  delete(applicantId: string, id: string): Promise<void> {
    const reference = doc(this.firestore, this.getPath(applicantId), id);
    return deleteDoc(reference);
  }

  private getPath(applicantId: string) {
    return (
      FirebaseCollectionNames.ORGANIZATIONS +
      '/' +
      this.store.organizationId! +
      '/' +
      FirebaseCollectionNames.APPLICANTS +
      '/' +
      applicantId +
      '/ratings'
    );
  }
}
