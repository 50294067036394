<ng-container *ngIf="job && job.jobAnalysis && job.jobAnalysis.requirements">
  <div class="job-match column" *ngIf="matchAnalysis as ma">
  <!--  <job-match-experience
            *ngIf="job.jobAnalysis.requirements.experienceRequirements"
            [score]="ma.experienceScore"
            [scoreJustification]="ma.experienceScoreReasoning"
            [justifications]="ma.requirementsJustification.experienceRequirementsJustification!">
    </job-match-experience>
    <job-match-education
            *ngIf="ma.requirementsJustification.educationRequirementsJustification"
            [score]="ma.educationScore"
            [scoreJustification]="ma.educationScoreReasoning"
            [justifications]="ma.requirementsJustification.educationRequirementsJustification!">
    </job-match-education>
    <job-match-skills
            *ngIf="job.jobAnalysis.skills && job.jobAnalysis.requirements.skillsRequirements"
            [score]="ma.skillsScore"
            [scoreJustification]="ma.skillsScoreReasoning"
            [justifications]="ma.requirementsJustification.skillsRequirementsJustification"
            [skills]="job.jobAnalysis.skills"
            [relevantSkills]="matchAnalysis.skillsMatching">
    </job-match-skills>
    <job-match-languages
            *ngIf="ma.requirementsJustification.languagesRequirementsJustification"
            [justifications]="ma.requirementsJustification.languagesRequirementsJustification!">
    </job-match-languages>
    <job-match-licences-and-certifications
            *ngIf="ma.requirementsJustification.licenceAndCertificationsRequirementsJustification"
            [justifications]="ma.requirementsJustification.licenceAndCertificationsRequirementsJustification!">
    </job-match-licences-and-certifications>
    <job-match-optional
            *ngIf="ma.requirementsJustification.optionalRequirementsJustification"
            [justifications]="ma.requirementsJustification.optionalRequirementsJustification!">
    </job-match-optional>-->
  </div>
</ng-container>