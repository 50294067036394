<div class="applicant-ratings fill-space overflow-hidden column gap-1" *ngIf="applicant$ | async as applicant">

  <div class="center">
    <div class="button button-action center gap-1" (click)="showCreateRating(applicant)">
      <div> <img src="assets/images/plus-white.svg"/> </div>
      <div class="semibold"> Add Review</div>
    </div>
  </div>
  <ng-container *ngIf="ratings$ | async as ratings">
    <div class="ratings-list column fill-space scrollbar ">
      <applicant-rating-panel *ngFor="let r of ratings" [rating]="r">   </applicant-rating-panel>
    </div>
  </ng-container>


</div>