export enum EEducationLevel {
  PRE_HIGH_SCHOOL_EDUCATION = 'Pre-high school education',
  HIGH_SCHOOL_DIPLOMA_GED = 'High School Diploma/GED',
  PROFESSIONAL_CERTIFICATION_TRAINING = 'Professional Certification/Training',
  VOCATIONAL_TRADE_SCHOOL = 'Vocational/Trade School',
  ASSOCIATES_DEGREE = "Associate's Degree",
  BACHELORS_DEGREE = "Bachelor's Degree",
  POSTGRADUATE_CERTIFICATE_DIPLOMA = 'Postgraduate Certificate/Diploma',
  MASTERS_DEGREE = "Master's Degree",
  DOCTORATE = 'Doctorate (PhD)',
  POST_DOCTORATE = 'Post-Doctorate',
}
