<div class="resume-insights fill-space column">

    <div class="header fill-space" (click)="showInsights = !showInsights">
        <div class="header-icon  fill-space"> <img src="assets/images/hirefox-insights.svg"/> </div>
        <div class="header-arrow center"> <img src="assets/images/arrow-down.svg"/> </div>
    </div>

    <div class="resume-insights-content column" *ngIf="showInsights">

        <div class="resume-insights-title space-bottom-1"> Positive/Negative Indicators </div>

        <div class="resume-insights-list row wrap">

          <div class="resume-insights-empty center" *ngIf="!insights || !insights.length"> No Insights </div>

          <ng-container *ngFor="let i of insights">
            <ng-container *ngIf="i">
              <div class="indicator column fill-space" *ngIf="i.isMatch" (click)="showIndicatorInfo(i)">
                  <div class="indicator-header" >
                    <div class="indicator-header-icon center"> <img [src]="'assets/images/indicator-' + indicatorService.indicatorData[i.key].polarity.toLowerCase() + '.svg'"/> </div>
                    <div class="indicator-header-name column column-center-left fill-space"> {{ indicatorService.indicatorData[i.key].type }} </div>
                    <div class="indicator-header-info center"> <img src="assets/images/info.svg"/> </div>
                  </div>
                  <div class="indicator-content"> {{i.reasoning}} </div>
              </div>
            </ng-container>
          </ng-container>

        </div>

    </div>

</div>
