import { Component, OnInit } from "@angular/core";
import { of, switchMap } from "rxjs";
import { Auth } from "@angular/fire/auth";
import { StoreService } from "../../services/store.service";
import { OrganizationsApi } from "../../api/organizations.api";
import { UsersApi } from "../../api/users.api";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.css"],
})
export class PageComponent implements OnInit {
  userHasValidOrganization: boolean = false;

  constructor(
    private store: StoreService,
    private router: Router,
    private organizationApi: OrganizationsApi,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    const organizationId = this.route.snapshot.data["organizationId"];

    // in a next version, this will subscribe to the user's organizations and check if the user has access to this organization and redirect to /no-access-company if not
    this.organizationApi
      .getOneOnce(organizationId)
      .then((org) => org.exists())
      .then((exists) => {
        this.userHasValidOrganization = exists;
        if (!exists) {
          this.router.navigate(["/no-access-company"]);
        }
      });
  }
}
