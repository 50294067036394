import {Component, OnInit} from '@angular/core';
import {IJob} from "../../../../../../../model/IJob";
import {IReplacement} from "../../../../../../../model/replacements/IReplacement";
import {JobsApi} from "../../../../api/jobs.api";

@Component({
  selector: 'job-ai-instructions',
  templateUrl: './job-ai-instructions.component.html',
  styleUrls: ['./job-ai-instructions.component.css']
})
export class JobAiInstructionsComponent implements OnInit {

  job: IJob | undefined;
  isLoading: boolean = false;
  isSaved: boolean = false;

  importanceLevels: string[] = ['Very Low', 'Low', 'Moderate', 'High', 'Critical']

  constructor(private jobsApi: JobsApi) {
  }

  save(): void {
    if(!this.job) { return; }
    this.isLoading = true;
    this.jobsApi.set(this.job.id, this.job).then( response => {
      this.isSaved = true;
    }).catch( error => {
      console.log(error);
    }).finally( () => {
      this.isLoading = false;
    });
  }


  ngOnInit() {
    if(this.job && !this.job.userAiGuidelines) {
      this.job.userAiGuidelines = {
        scoringInstructions: "If the candidate doesn't match any of the technical skills make sure to give them a score below 50.",
        rejectReasons: ""
      };
    }
  }

}
