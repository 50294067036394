import { Injectable } from "@angular/core";
import {
  deleteObject,
  getDownloadURL, listAll,
  ref,
  Storage, uploadBytes,
  uploadBytesResumable
} from '@angular/fire/storage';
import { StoreService } from "../services/store.service";
import { UtilService } from "../services/util.service";
import { UploadTask } from "firebase/storage";
import { FirebaseCollectionNames } from "../../../../model/FirebaseCollectionNames";

@Injectable({
  providedIn: "root",
})
export class StorageApi {
  constructor(
    private storage: Storage,
    private store: StoreService,
    private utilService: UtilService,
  ) {}

  // Returns the URL of a file on firebase using the firebase storage path
  getFileUrl(path: string): Promise<string> {
    const fileRef = ref(this.storage, path);
    return getDownloadURL(fileRef);
  }

  deleteFile(path: string): Promise<void> {
    const fileRef = ref(this.storage, path);
    return deleteObject(fileRef);
  }

  uploadFile(path: string, fileName: string, file: File): UploadTask {
    //const path = FirebaseCollectionNames.ORGANIZATIONS + "/" + this.store.organizationId + "/" + FirebaseCollectionNames.RESUMES + "/";
    //const fileName = resumeId + "." + this.utilService.getFilenameExtension(file.name);
    const storageRef = ref(this.storage, path + fileName);
    return uploadBytesResumable(storageRef, file);
  }

  getResumeStoragePath() {
    return FirebaseCollectionNames.ORGANIZATIONS + "/" + this.store.organizationId + "/" + FirebaseCollectionNames.RESUMES + "/";
  }
  getImageStoragePath() {
    return FirebaseCollectionNames.ORGANIZATIONS + "/" + this.store.organizationId + "/" + "images" + "/";
  }
  getPortalLogoStoragePath() {
    return FirebaseCollectionNames.ORGANIZATIONS + "/" + this.store.organizationId + "/assets" + "/" + "logo" + "/";
  }

  async uploadPortalLogo(fileName: string, file: File) {

    const storageRef = ref(this.storage, this.getPortalLogoStoragePath());
    try {
      const listResult = await listAll(storageRef);
      const deletionPromises = listResult.items.map((itemRef) =>
        deleteObject(itemRef)
      );
      await Promise.all(deletionPromises);

      // Upload the new file
      const filePath = `${this.getPortalLogoStoragePath()}${fileName}`;
      const fileRef = ref(this.storage, filePath);

      const uploadResult = await uploadBytes(fileRef, file);
      // Get the download URL for the uploaded file
      const downloadURL = await getDownloadURL(fileRef);
      return downloadURL;
    } catch (error) {
      console.error('Error during logo upload process:', error);
      return error;
    }

  }
}
