import { Component, Input } from '@angular/core';
import { ESkeletonShape } from '../../model/enum/ESkeletonShape';

@Component({
  selector: 'skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.css'],
})
export class SkeletonLoaderComponent {
  @Input() shape: ESkeletonShape = ESkeletonShape.LINE;

  protected readonly ESkeletonType = ESkeletonShape;
}
