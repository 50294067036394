import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'job-menu',
  templateUrl: './job-menu.component.html',
  styleUrls: ['./job-menu.component.css']
})
export class JobMenuComponent {

  selectedMenuOption: string = "applicants";

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  navigateToApplicants() {
    this.router.navigate(['applicants'], { relativeTo: this.route });
  }

  navigateToJobDescription() {
    this.router.navigate(['job-description'], { relativeTo: this.route });
  }

}
