import { Component, OnInit } from "@angular/core";
import {
  BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, startWith, Subject, switchMap
} from "rxjs";
import { FormControl } from "@angular/forms";
import { UserFormComponent } from "../users/user-form/user-form.component";
import { MatDialog } from "@angular/material/dialog";
import { EFilterType } from "../../model/enum/EFilterType";
import { ActivatedRoute } from "@angular/router";
import { IJob } from "../../../../../model/IJob";
import { JobFormComponent } from "./job-form/job-form.component";
import { JobsApi } from "../../api/jobs.api";
import { ITag } from "../../../../../model/ITag";
import { StoreService } from "../../services/store.service";
import { FiltersService } from "../../services/filters/filters.service";
import {FirestoreFilter} from "../../model/firestore.filter";
import {IFilter} from "../../model/IFilter";
import {environment} from "../../../environments/environment";

@Component({
  selector: "jobs",
  templateUrl: "./jobs.component.html",
  styleUrls: ["./jobs.component.css"],
})
export class JobsComponent implements OnInit {
  jobs$: Observable<IJob[]> | undefined;
  filteredJobs$: Observable<IJob[] | null> | undefined;
  searchControl = new FormControl();
  searchTags: string[] = [];
  searchTagsSubject: Subject<string[]> | undefined;
  searchTags$: Observable<string[]> | undefined;

  constructor(
    public dialog: MatDialog,
    private jobsApi: JobsApi,
    private store: StoreService,
    public filtersService: FiltersService,
  ) {

  }

  addSearchTag(element: HTMLInputElement): void {
    this.searchTags.push(element.value);
    this.searchTagsSubject?.next(this.searchTags);
    element.value = "";
  }
  removeSearchTag(value: string) {
    let index = this.searchTags.indexOf(value);
    if(index !== -1) {
      this.searchTags.splice(index,1);
      this.searchTagsSubject?.next(this.searchTags);
    }
  }

  showCreateJob(): void {
    let dialog = this.dialog.open(JobFormComponent, {
      data: {
        orgId: this.store.organizationId,
      },
    });
  }

  ngOnInit() {

    this.filtersService.updateFilters(this.filtersService.jobFilters, environment.typesense.collections.jobs);

    // Make an observable to watch the searchTags
    this.searchTagsSubject = new BehaviorSubject(this.searchTags);
    this.searchTags$ = this.searchTagsSubject.asObservable();

    // Get the jobs from firestore filtered by the jobStatus filter
    this.jobs$ = this.filtersService.filters.job.jobStatus.selectedOptions$!.pipe(
        switchMap(jobStatusFilterValues => {
          if(!jobStatusFilterValues.length) { return this.jobsApi.getAll(); }
          return this.jobsApi.getAll([{field: 'jobStatus', condition: 'in', value: jobStatusFilterValues }])
        })
    )

    // Filter the retrieved jobs client-side using the search terms
    this.filteredJobs$ = combineLatest([
      this.jobs$ ?? of([]),
      this.searchTags$,
    ]).pipe(
      map(([jobs, searchTags]: [IJob[] | null, string[]]) => {
        if (!jobs) {
          return null;
        }
        if (searchTags && searchTags.length > 0) {
          return jobs.filter((job) =>
              searchTags.some(tag =>
                  job.title.toLowerCase().includes(tag.toLowerCase()) ||
                  job.description.toLowerCase().includes(tag.toLowerCase())
              )
          );
        }
        return jobs;
      }),
    );
  }

  protected readonly EFilterType = EFilterType;
}
