import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IOrganization } from "../../../../model/IOrganization";
import { OrganizationsApi } from "../api/organizations.api";

@Injectable({
  providedIn: "root",
})
export class StoreService {
  organizationId: string | null | undefined;
  organizationName: string | undefined;
  userId: string | null | undefined;
  organization$: Observable<IOrganization | undefined> | undefined;

  constructor(private organizationApi: OrganizationsApi) {}

  updateOrganizationId(newId: string | null | undefined): void {
    this.organizationId = newId;
    this.organization$ = newId
      ? this.organizationApi.getOne(newId)
      : of(undefined);
  }
}
