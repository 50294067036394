<div class="resume-content-section column fill-space">
  <div class="resume-content-section-header fill-space">
    <div class="resume-content-section-header-icon center">
      <img src="assets/images/section-languages.svg" />
    </div>
    <div class="resume-content-section-header-title center">Languages</div>
  </div>

  <div class="resume-content-section-data column fill-space">

    <empty-list text="No Languages" *ngIf="!languages || !languages.length"></empty-list>

    <ng-container *ngIf="languages">
      <div class="resume-node column" *ngFor="let l of languages">

        <div class="resume-node-header">
          <div class="resume-node-icon"> <img src="assets/images/resume-list-languages.svg"/> </div>
          <div class="resume-node-header-content column">
            <div class="resume-node-title capitalize"> {{ l.language.toLowerCase() }}</div>
            <div class="capitalize" [class]="{'resume-node-subtitle': l.proficiencyLevel, 'empty-info': !l.proficiencyLevel}">
              {{ l.proficiencyLevel ? l.proficiencyLevel : 'Proficiency Level Unknown' }}
            </div>
          </div>
        </div>

        <ng-container *ngIf="l.certification as c">
          <div class="certification column column-center-left" *ngIf="c.name">
            <div class="row">
              <div class="certification-icon"> <img src="assets/images/language-certificate.svg"/> </div>
              <div class="certification-label fill-space">Language Certificate </div>

            </div>
            <div class="certification-name">{{ c.name }}</div>
            <div class="certification-info row">
              <div class="certification-info-data">
                Awarded By: {{ c.awardedBy ? c.awardedBy : "Unknown"}}
              </div>
              <div class="certification-info-data">
                Obtained On: {{ c.dateObtained ? c.dateObtained : "Unknown" }}
              </div>
            </div>
          </div>
        </ng-container>

      </div>
    </ng-container>

  </div>
</div>
