<div id="login">
  <div id="login-content" class="column" *ngIf="!isLoading">
    <div id="login-logo" class="fill-space center">
      <img src="assets/images/logo-vertical.svg" />
    </div>
    <div id="register-title" class="fill-space center"> Create A New Account </div>
    <form id="credentials" class="column fill-space">
      <div class="form-field column fill-space space-bottom-1">
        <div class="form-field-label">Full Name</div>
        <div class="form-field-input fill-space">
          <input name="name" class="fill-space" type="text" autocomplete="username" [(ngModel)]="newUser.name" (keydown.enter)="register()" />
        </div>
      </div>
      <div class="form-field column fill-space space-bottom-1">
        <div class="form-field-label">Email</div>
        <div class="form-field-input fill-space">
          <input name="username" class="fill-space" type="text" autocomplete="username" [(ngModel)]="newUser.email" (keydown.enter)="register()" />
        </div>
      </div>
      <div class="form-field column fill-space">
        <div class="form-field-label">Password</div>
        <div class="form-field-input fill-space">
          <input name="password" class="fill-space" type="password" autocomplete="current-password" [(ngModel)]="newUser.password" (keydown.enter)="register()"/>
        </div>
      </div>
      <div class="form-field-info">Must be at least 6 characters</div>
    </form>
    <div id="login-error" class="form-error" *ngIf="error">{{ error }}</div>

    <div id="signup-button" class="fill-space" (click)="register()">
      Create Account
    </div>

    <div id="login-link" class="fill-space" routerLink="/login"> Already Have An Account ? &nbsp; <span class="semibold"> Sign In  </span> </div>


  </div>
  <loader loaderType="badge" *ngIf="isLoading"></loader>
</div>
