<ng-container *ngIf="uploads$ | async as uploads">
  <div class="job-applicant-uploads column" *ngIf="uploads.length">

    <div class="job-applicant-uploads-header row">
      <div class="job-applicant-uploads-header-icon"> <img src="assets/images/upload.svg" /> </div>
      <div class="job-applicant-uploads-header-label fill-space"> Uploading & Parsing </div>
      <div class="job-applicant-uploads-header-total">  {{uploads.length}} Resumes </div>
    </div>

    <div class="job-applicant-uploads-list column">
      <div class="applicant-upload" *ngFor="let u of uploads">
        <div class="applicant-upload-icon center"> <img src="assets/images/file.svg"/> </div>
        <div class="applicant-upload-content gap-1 fill-space">
          <div class="applicant-upload-content-name fill-space row-center-left"> {{u.file.originalName}}.{{u.file.type}} </div>
          <div class="applicant-upload-content-loader row-center-left"> <loader></loader> </div>
          <div class="applicant-upload-content-status row-center-left"> Uploading & Processing... </div>
        </div>
      </div>
    </div>

  </div>
</ng-container>