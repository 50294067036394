import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'text-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit, OnDestroy {
  @Input() editorFormControl?: FormControl;
  @Input() model?: string;
  text?: string;
  localFormControl: FormControl = new FormControl();

  @Output() modelDataChange = new EventEmitter<string>();
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '20em',
    maxHeight: '30em',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'strikeThrough',
        'subscript',
        'superscript',
        'fontName',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  htmlString?: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.editorFormControl) {
      this.editorFormControl.valueChanges.subscribe((res) => {
        this.htmlString = this.sanitizer.bypassSecurityTrustHtml(res);
      });
    }else{
      this.localFormControl.valueChanges.subscribe((res) => {
        this.modelDataChange.emit(res)
      })
    }
  }

  convertHtmlToText(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.innerText || '';
  }

  ngOnDestroy(): void {}
}
