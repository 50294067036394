import { Component, OnInit } from '@angular/core';
import { IJob } from '../../../../../../../../model/IJob';
import { Observable } from 'rxjs';
import { JobService } from '../../../../../services/job.service';

@Component({
  selector: 'job-description',
  templateUrl: './job-description.component.html',
  styleUrls: ['./job-description.component.css']
})
export class JobDescriptionComponent implements OnInit {
  job$: Observable<IJob | undefined> | undefined;

  constructor(private jobService: JobService) {}

  ngOnInit() {
    this.job$ = this.jobService.job$;
  }

  serializeLocation(location: any) {
    if (!location) return null;
    // for backward compatibility
    if (typeof location === 'string') return location;
    const { country, state, city } = location;
    if (country === '' && state === '' && city === '') return null;
    return [city, state, country].filter(Boolean).join(', ');
  }
}
