import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { JobsApi } from "../../../api/jobs.api";
import { StoreService } from "../../../services/store.service";
import { EJobStatus } from "../../../../../../model/enums/EJobStatus";
import { IJob } from "../../../../../../model/IJob";
import { EStatus } from "../../../../../../model/enums/EResumeStatuses";
import {EPipelineStage} from "../../../../../../model/enums/EPipelineStage";

@Component({
  selector: "job-form",
  templateUrl: "./job-form.component.html",
  styleUrls: ["./job-form.component.css"],
})
export class JobFormComponent {
  job: Omit<IJob, "id"> = {
    organizationId: this.store.organizationId!,
    title: "",
    description: "",
    jobStatus: EJobStatus.OPEN,
    applicantsCount: 0,
    creationTime: this.jobsApi.getServerTimestamp(),
    status: EStatus.STORED,
    pipeline: Object.values(EPipelineStage)
  };
  isLoading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<JobFormComponent>,
    private jobsApi: JobsApi,
    private store: StoreService,
  ) {}

  createJob(): void {
    if (!this.job.title || !this.job.description) {
      return;
    }
    this.isLoading = true;
    this.jobsApi
      .create(this.job)
      .then(() => {
        this.dialogRef.close();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
