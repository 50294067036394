import { Component, Input } from "@angular/core";
import {
  IApplicantGroup,
  ICategoryDefinition,
} from "../../../../../../model/IApplicantGroup";
import { UtilService } from "../../../../../../services/util.service";
import { IApplicant } from "../../../../../../../../../model/IApplicant";
import { Observable } from "rxjs";

@Component({
  selector: "job-applicant-category",
  templateUrl: "./job-applicant-category.component.html",
  styleUrls: ["./job-applicant-category.component.css"],
})
export class JobApplicantCategoryComponent {
  @Input() group: IApplicantGroup | undefined;
  @Input() applicants$: Observable<IApplicant[] | undefined> | undefined;
  @Input() selectedApplicantId: string | undefined;
  protected categoryDefinition: ICategoryDefinition | undefined;
  applicantsCount: number = 0;

  constructor(protected utilService: UtilService) {}

  ngOnInit() {
    this.categoryDefinition = this.utilService.getCategory(this.group!.type!);
    this.applicants$?.subscribe((applicants) => {
      this.applicantsCount = 0;
      if (applicants) {
        applicants.forEach((applicant) => {
          if (
            this.utilService.getCategoryForApplicant(applicant).type === this.group!.type) {
            this.applicantsCount++;
          }
        });
      }
    });
  }
}
