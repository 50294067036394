<div id="job-details" class="gap-1 space-top-1">
  <div class="column">
    <form *ngIf="jobForm" [formGroup]="jobForm" (ngSubmit)="onSubmit()" class="flex wrap space-top-bottom-1 gap-3">
      <div class="column gap-2 fill-space-equal">
        <div class="settings-section-title">Job Info</div>
        <div class="column">
          <label class="form-field-label" for="title">Job Title *</label>
          <div class="form-field-input">
            <input
              id="title"
              type="text"
              formControlName="title"
              class="fill-space"
              [ngClass]="{ invalid: titleControl?.invalid && titleControl?.touched }" />
          </div>
          <div *ngIf="titleControl?.invalid && titleControl?.touched" class="form-error">
            <span *ngIf="titleControl?.hasError('required')"> Title is required. </span>
          </div>
        </div>

        <div class="column fill-space-equal">
          <label class="form-field-label">Job Description *</label>
          <text-editor [editorFormControl]="descriptionControl"></text-editor>
        </div>
      </div>

      <div class="column gap-2">
        <div class="gap-3">
          <div class="settings-section-title">Job Details</div>
          <div class="public-listing-container">
            <span> Public listing </span>
            <label class="switch">
              <input type="checkbox" formControlName="isPublic" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="column">
          <label class="form-field-label">Job Setting *</label>
          <div class="gap-1 wrap">
            <div
              class="option"
              *ngFor="let jobSetting of workArrangements"
              (click)="jobSettingControl.setValue(jobSetting)"
              [ngClass]="{ 'selected-option': jobSettingControl.value === jobSetting }">
              {{ jobSetting }}
            </div>
          </div>
        </div>

        <div class="column">
          <label class="form-field-label">Job Type *</label>
          <div class="gap-1 wrap">
            <div
              class="option"
              *ngFor="let jobType of employmentTypes"
              (click)="jobTypeControl.setValue(jobType)"
              [ngClass]="{ 'selected-option': jobTypeControl.value === jobType }">
              {{ jobType }}
            </div>
          </div>
        </div>

        <div class="column">
          <label class="form-field-label">Seniority Level *</label>
          <div class="gap-1 wrap">
            <div
              class="option"
              *ngFor="let level of seniorityLevels"
              (click)="seniorityLevelControl.setValue(level)"
              [ngClass]="{ 'selected-option': seniorityLevelControl.value === level }">
              {{ level }}
            </div>
          </div>
        </div>

        <div class="column gap-1" formGroupName="salary">
          <div class="column">
            <label class="form-field-label">Salary</label>
            <div class="gap-1 wrap">
              <div class="column">
                <label class="form-field-label">Min Salary</label>
                <div class="form-field-input">
                  <input
                    currencyMask
                    [options]="{
                      prefix: currencies[currencyControl?.value].symbol + ' ',
                      thousands: ',',
                      allowNegative: false,
                      precision: 0
                    }"
                    formControlName="min"
                    class="input" />
                </div>
              </div>
              <div class="column">
                <label class="form-field-label">Max Salary</label>
                <div class="form-field-input">
                  <input
                    currencyMask
                    [options]="{
                      prefix: currencies[currencyControl?.value].symbol + ' ',
                      thousands: ',',
                      allowNegative: false,
                      precision: 0
                    }"
                    formControlName="max"
                    class="input" />
                </div>
              </div>
              <div class="column">
                <label class="form-field-label">Pay Rate</label>
                <select formControlName="frequency" class="input">
                  <!--                  <option value="" disabled selected hidden>Year</option>-->
                  <option *ngFor="let freq of payFrequencies; index as i">
                    {{ freq | titlecase }}
                  </option>
                </select>
              </div>

          <div class="column">
            <label class="form-field-label">Currency</label>
            <select formControlName="currency" class="input">
              <option *ngFor="let currency of currencies; index as i" [value]="i" [selected]="i == 45">
                {{ currency.code + ' ' + currency.symbol }}
              </option>
            </select>
          </div>
        </div>
          </div>
        </div>
        <div class="column">
          <label class="form-field-label">Team/Department</label>
          <div class="form-field-input">
            <input type="text" formControlName="department" class="input fill-space" />
          </div>
        </div>

        <div class="column gap-1">
          <div class="settings-section-title">Location</div>
          <div formGroupName="location" class="location-container gap-1 fill-space">
            <div class="fill-space-equal">
              <label class="form-field-label">Country *</label>
              <div class="form-field-input">
                <input type="text" formControlName="country" class="input fill-space-equal" />
              </div>
            </div>
            <div class="fill-space-equal">
              <label class="form-field-label">State</label>
              <div class="form-field-input">
                <input type="text" formControlName="state" class="input fill-space-equal" />
              </div>
            </div>
            <div class="fill-space-equal">
              <label class="form-field-label">City</label>
              <div class="form-field-input">
                <input type="text" formControlName="city" class="input fill-space-equal" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <button [disabled]="!canSubmit()" (click)="onSubmit()" type="submit">Save</button>
  </div>
  <div class="column job-preview-container" *ngIf='job'>
    <div class="settings-section-title">Job Preview</div>
    <job-preview [jobDetails]="getJobDetails()" [job]="job"></job-preview>
  </div>
</div>
