<div class="popup column overflow-hidden scrollbar">
  <div class="popup-header row">
    <div class="popup-header-title fill-space">Add Rating</div>
    <div class="popup-header-close" mat-dialog-close>
      <img src="../../../../assets/images/close.svg" />
    </div>
  </div>

  <div class="popup-loader row center" *ngIf="isLoading">
    <loader></loader>
  </div>

  <div class="popup-content ratings-form column scrollbar" *ngIf="!isLoading">


    <div class="points fill-space gap-2">
      <div class="points-block fill-space-equal column">
        <div class="form-title"> Pros </div>
        <div>
          <div class="points-block-input fill-space">
            <input class="fill-space" placeholder="Add a pro..." type="text" [(ngModel)]="proText" (keydown.enter)="addPro()">
          </div>
          <div class="points-block-button"> <div class="button button-action" (click)="addPro()"> Add </div> </div>
        </div>
        <div class="points-block-list fill-space column ">
          <div class="points-block-list-empty center" *ngIf="!pros.length"> No Pros! </div>
          <div class="points-block-list-item" *ngFor="let item of pros; let i = index;">
            <div class="points-block-list-item-icon center"> <img src="assets/images/plus.svg"/> </div>
            <div class="points-block-list-item-text row-center-left fill-space"> {{ item }} </div>
            <div class="points-block-list-item-delete center" (click)="removePro(i)"> <img src="assets/images/delete.svg" /> </div>
          </div>
        </div>
      </div>
      <div class="points-block fill-space-equal column">
        <div class="form-title"> Cons </div>
        <div>
          <div class="points-block-input fill-space">
            <input class="fill-space" placeholder="Add a con..." type="text" [(ngModel)]="conText" (keydown.enter)="addCon()">
          </div>
          <div class="points-block-button"> <div class="button button-action" (click)="addCon()"> Add </div> </div>
        </div>
        <div class="points-block-list fill-space column">
          <div class="points-block-list-empty center" *ngIf="!cons.length"> No Cons! </div>
          <div class="points-block-list-item" *ngFor="let item of cons; let i = index;">
            <div class="points-block-list-item-icon center"> <img src="assets/images/minus.svg"/> </div>
            <div class="points-block-list-item-text row-center-left fill-space"> {{ item }} </div>
            <div class="points-block-list-item-delete center" (click)="removeCon(i)"> <img src="assets/images/delete.svg" /> </div>
          </div>
        </div>
      </div>
    </div>


    <div class="fill-space gap-2">
      <div class="rating-score fill-space-equal column">
        <div class="form-title fill-space row-center-left"> Rating </div>
        <div>
          <div class="rating-score-stars">
            <div class="rating-score-star center" [class]="{'selected': i < score }"
                 *ngFor="let star of [].constructor(10); let i = index" (click)="setScore(i)">
              <img [src]=" (i < score) ? 'assets/images/star-green.svg' : 'assets/images/star-gray.svg'"/>
            </div>
          </div>
          <div class="rating-score-value center"> {{score}} / 10 </div>
        </div>
      </div>
      <div class="decision column fill-space-equal">
        <div class="form-title fill-space row-center-left"> Suggestion </div>
        <div class="decision-items">
          <div class="decision-item hire center" [class]="{'decision-item-selected': isHire }" (click)="isHire = true">
            <div class="decision-item-icon center"> <img src="assets/images/decision-hire.svg"/> </div>
            <div class="decision-item-text row-center-left"> Hire </div>
          </div>
          <div class="decision-item reject center" [class]="{'decision-item-selected': !isHire }" (click)="isHire = false">
            <div class="decision-item-icon center"> <img src="assets/images/decision-reject.svg"/> </div>
            <div class="decision-item-text row-center-left"> Reject </div>
          </div>
        </div>
      </div>
    </div>

    <div class="comments column fill-space">
      <div class="form-title fill-space row-center-left"> Comments </div>
      <div class="comments-input fill-space">
        <textarea class="fill-space" rows="5"  [(ngModel)]="comments"></textarea>
      </div>
    </div>

  </div>

  <div class="popup-buttons row" *ngIf="!isLoading">
    <div class="button button-gray" mat-dialog-close>Cancel</div>
    <div class="fill-space"></div>
    <div class="button button-action" (click)="save()"> Save Rating </div>
  </div>
</div>
