export enum EStatus {
  UPLOADING = 'uploading',
  STORED = 'stored',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  ERROR = 'error'
}

export enum EParsingStatus {
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  ERROR = 'error'
}

export enum EImageStatus {
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  ERROR = 'error'
}
