import { Component, Input } from "@angular/core";
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";
import { IResume } from "../../../../../../../model/IResume";
import { FormControl } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ResumesApi } from "../../../../api/resumes.api";
import { ApplicantsApi } from "../../../../api/applicants.api";
import { IApplicant } from "../../../../../../../model/IApplicant";
import {
  EImageStatus,
  EStatus,
} from "../../../../../../../model/enums/EResumeStatuses";
import { EPipelineStage } from "../../../../../../../model/enums/EPipelineStage";
import { FiltersService } from "../../../../services/filters/filters.service";
import {
  IHit,
  ISearchIndexResponse,
  ISearchMultipleRequest,
} from "../../../../model/ITypesense";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { IndexApi } from "../../../../api/index.api";
import { StoreService } from "../../../../services/store.service";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: "job-applicants-selector",
  templateUrl: "./job-applicant-selector.component.html",
  styleUrls: ["./job-applicant-selector.component.css"],
})
export class JobApplicantSelectorComponent {
  @Input() jobId: string | null | undefined;
  isLoading: boolean = false;
  selectedResumes: IResume[] = [];
  applicants: IApplicant[] = [];
  allResumes$: Observable<IResume[]> | undefined;
  resumes$: Observable<IResume[]> | undefined;
  unsubscribe$ = new Subject<void>();
  searchControl = new FormControl();
  searchTags: string[] = [];
  resumeIds$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]); // The filtered resume ids retrieved from the index
  currentUrlParams: Params | undefined;

  constructor(
    private dialogRef: MatDialogRef<JobApplicantSelectorComponent>,
    private resumesApi: ResumesApi,
    private applicantsApi: ApplicantsApi,
    public filtersService: FiltersService,
    private route: ActivatedRoute,
    private indexApi: IndexApi,
    private store: StoreService,
    private router: Router,
  ) {}

  addResumesToJob() {
    if (!this.selectedResumes.length || !this.jobId) {
      return;
    }

    this.isLoading = true;

    this.selectedResumes.forEach((resume) => {
      let applicant: IApplicant = {
        jobId: this.jobId!,
        resumeId: resume.id,
        status: EStatus.STORED,
        pipelineStage: EPipelineStage.APPLIED,
        pipelineStageIndex: 0,
        isRejected: false,
        creationTime: this.applicantsApi.getServerTimestamp(),
        info: resume.resumeAnalysis!.info!,
        image: resume.image ? resume.image : { status: EImageStatus.COMPLETED },
      } as IApplicant;
      this.applicants.push(applicant);
    });

    this.applicantsApi
      .createBulk(this.applicants)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
        this.dialogRef.close();
      });
  }

  // Returns the index of a resume in the resumes array or -1 if not found
  getResumeIndexById(id: string): number {
    for (let i = 0; i < this.selectedResumes.length; i++) {
      if (this.selectedResumes[i].id === id) {
        return i;
      }
    }
    return -1;
  }

  selectResume(resume: IResume) {
    const index = this.getResumeIndexById(resume.id);
    if (index != -1) {
      this.selectedResumes.splice(index, 1);
      return;
    }
    this.selectedResumes.push(resume);
  }

  isSelected(id: string) {
    return this.getResumeIndexById(id) != -1;
  }

  refresh() {
    if (this.currentUrlParams) {
      this.getResumes(this.currentUrlParams).then((response) => {
        const resumeIds = response.map((resume) => resume.id);
        this.updateResumeIds(resumeIds);
      });
    }
  }

  updateResumeIds(newIds: string[]): void {
    this.resumeIds$.next(newIds);
  }

  updateQueryParams(searchTags: string[]) {
    const queryParams: { [key: string]: string } = {};
    queryParams["search"] = this.searchTags.length
      ? this.searchTags.join(",")
      : "";

    // Update the URL with the new query parameters
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: "merge",
        replaceUrl: true,
      })
      .then();
  }

  addSearchTag(element: HTMLInputElement): void {
    this.searchTags.push(element.value);
    element.value = "";
    this.updateQueryParams(this.searchTags);
  }

  removeSearchTag(value: string) {
    let index = this.searchTags.indexOf(value);
    if (index !== -1) {
      this.searchTags.splice(index, 1);
    }
    this.updateQueryParams(this.searchTags);
  }

  async getResumes(params: Params): Promise<IResume[]> {
    // Build the query using the provided params
    let query: ISearchMultipleRequest = this.filtersService.buildQuery(
      params,
      this.filtersService.resumeFilters,
    );

    try {
      const response = await firstValueFrom(
        this.indexApi.searchMultiple(query),
      );

      // Get the resumes from the index results object
      const hits = response.results
        .flatMap((result: ISearchIndexResponse) => result.hits)
        .map((hit: IHit) => hit.document);

      // Remove duplicates
      const uniqueResumes = hits.filter(
        (document: IResume, index: number, self: IResume[]) =>
          index === self.findIndex((t) => t.id === document.id),
      );
      return uniqueResumes;
    } catch (error) {
      console.error("Error fetching resumes:", error);
      throw error; // or handle it as preferred
    }
  }

  ngOnInit() {
    this.allResumes$ = this.resumesApi.getAll();

    this.resumes$ = combineLatest([this.allResumes$, this.resumeIds$]).pipe(
      map(([resumes, resumeIds]) =>
        resumeIds.length
          ? resumes.filter((resume) => resumeIds.includes(resume.id))
          : resumes,
      ),
      map((resumes) =>
        resumes.sort(
          (a, b) => b.creationTime.toMillis() - a.creationTime.toMillis(),
        ),
      ),
    );

    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.currentUrlParams = params;
        this.getResumes(params).then((response) => {
          const resumeIds = response.map((resume) => resume.id);
          this.updateResumeIds(resumeIds);
        });
        this.searchTags = this.filtersService.getSearchParamsFromUrl(params);
        this.filtersService.updateFilterCounts(this.filtersService.resumeFilters, params, environment.typesense.collections.resumes);
      });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
