import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IMatchAnalysis} from "../../../../../../../../../../../../model/IApplicant";
import {UtilService} from "../../../../../../../../../services/util.service";

@Component({
  selector: 'applicant-evaluation-score',
  templateUrl: './applicant-evaluation-score.component.html',
  styleUrls: ['./applicant-evaluation-score.component.css']
})
export class ApplicantEvaluationScoreComponent implements OnInit, OnChanges {

  @Input() title: string | undefined;
  @Input() score: number = 0;
  @Input() averageScore: number = 0;
  @Input() scoreJustification: string | undefined;
  @Input() isHeaderTitle: boolean = false; // This is to make larger the text for the 'Overall'
  scoreDiff: number = 0; // The difference between the candidate's score and the average score

  constructor(public utilService: UtilService) {
  }

  calculateScoreDifference() {
    this.scoreDiff = this.score - this.averageScore;
  }


  ngOnChanges(changes: SimpleChanges) {
    this.calculateScoreDifference();
  }

  ngOnInit() {
    this.calculateScoreDifference();
  }

  protected readonly Math = Math;
}
