<div class="job-card">
  <div class="job-card-header">
    <div>
      <div class="location-type-container">
        <ng-container *ngIf="jobDetails?.jobSetting === EWorkArrangement.ON_SITE">
          <img src="assets/images/on-site-icon.svg" alt="on-site" />
        </ng-container>
        <ng-container *ngIf="jobDetails?.jobSetting === EWorkArrangement.REMOTE">
          <img src="assets/images/remote-icon.svg" alt="remote" />
        </ng-container>
        <ng-container *ngIf="jobDetails?.jobSetting === EWorkArrangement.HYBRID">
          <img src="assets/images/hybrid-icon.svg" alt="hybrid" />
        </ng-container>
        <ng-container *ngIf="!jobDetails?.jobSetting">
          <img src="assets/images/on-site-icon.svg" alt="location" />
        </ng-container>
        <span class="location-type">
          {{ jobDetails?.jobSetting || '[Job Setting]' }}
        </span>
      </div>
      <span class="location-info">
        {{ serializeLocation(jobDetails?.location) || '[Location]' }}
      </span>
    </div>
    <div class="job-status" *ngIf="showJobStatus">
      <img *ngIf="job?.jobStatus === EJobStatus.OPEN" src="assets/images/status-open.svg" alt="status icon" />
      <img *ngIf="job?.jobStatus === EJobStatus.CLOSED" src="assets/images/status-closed.svg" alt="status icon" />
      <span>
        {{ job?.jobStatus | titlecase }}
      </span>
    </div>
  </div>
  <div class="justify-space-between gap-1">
    <div class="column gap-05">
      <h2 class="job-title">{{ jobDetails?.title || '[Job Title]' }}</h2>
      <div class="job-details">
        <span class="job-type">{{ jobDetails?.jobType || '[Job Type]' }}</span>
        <span class="salary" *ngIf="jobDetails?.salary?.min || jobDetails?.salary?.max; else noSalaryInfo">
          <ng-container *ngIf="jobDetails?.salary?.min && jobDetails?.salary?.max; else onlyMinOrMax">
            {{ jobDetails?.salary?.min ?? 0 | currency: jobDetails?.salary?.currency : 'symbol-narrow' : '1.0-0' }}
            -
            {{ jobDetails?.salary?.max ?? 0 | currency: jobDetails?.salary?.currency : 'symbol-narrow' : '1.0-0' }}
          </ng-container>
          <ng-template #onlyMinOrMax>
            {{
              jobDetails?.salary?.min || jobDetails?.salary?.max || 0 | currency: jobDetails?.salary?.currency : 'symbol-narrow' : '1.0-0'
            }}
          </ng-template>
          {{ jobDetails?.salary?.frequency === EPayFrequency.HOUR ? 'an' : 'a' }}

          {{ jobDetails?.salary?.frequency || '[Pay Rate]' }}
        </span>
        <ng-template #noSalaryInfo>
          <span class="salary">[Salary Info]</span>
        </ng-template>
        <span class="department" *ngIf="jobDetails?.department">{{ jobDetails?.department || '[Department]' }}</span>
        <span class="level">{{ jobDetails?.seniorityLevel || '[Seniority Level]' }}</span>
      </div>
    </div>

    <img *ngIf="showArrow" class="arrow" src="assets/images/arrow-right.svg" alt="arrow right" />
  </div>
  <div *ngIf="showPostedDate && jobDetails?.datePosted" class="post-date">
    Posted {{ (this.jobDetails?.datePosted?.nanoseconds ? jobDetails?.datePosted?.toDate() : Date()) | dateAgo }}
  </div>
</div>
