<ng-container *ngIf="job && job.jobAnalysis && job.jobAnalysis.requirements">
  <ng-container *ngIf="matchAnalysis as ma">

    <div class="applicant-evaluation-requirements column">

      <div class="match-section-content column">

        <div class="justifications column">
          <div class="justifications-section column">
            <div class="space-bottom-1">
              <div class="justifications-section-title column column-center-left fill-space"> Requirements Matching </div>
              <div class="match-section-matched-count row row-center-right">
                <div class="match-section-matched-count-option">
                  <div class="match-section-matched-count-option-icon center"> <img src="assets/images/tick-green.svg" /> </div>
                  <div class="match-section-matched-count-option-label center">Matched:</div>
                  <div class="match-section-matched-count-option-value center"> {{ matched }} </div>
                </div>
                <div class="match-section-matched-count-option">
                  <div class="match-section-matched-count-option-icon center"> <img src="assets/images/x-gray.svg" /> </div>
                  <div class="match-section-matched-count-option-label center">Unmatched:</div>
                  <div class="match-section-matched-count-option-value center"> {{ unMatched }} </div>
                </div>
              </div>
            </div>
            <div class="justifications-section-content column">
              <div class="justifications-empty center" *ngIf="!ma.requirementsJustifications.length"> No Requirements. </div>
              <div class="justification-entry column" *ngFor="let j of ma.requirementsJustifications; let index = index;">
                <div>
                  <div class="justification-entry-icon center">
                    <img *ngIf="j.isMatched" src="assets/images/tick-green.svg" />
                    <img *ngIf="!j.isMatched" src="assets/images/x-gray.svg" />
                  </div>
                  <div class="justification-entry-content column">
                    <div class="justification-entry-content-description column column-center-left"> {{ j.requirement }} </div>
                    <div class="justification-entry-content-justification column column-center-left"> {{ j.justification }} </div>
                  </div>
                </div>
                <div class="justification-inference" *ngIf="j.inferredJustification">
                  <div class="justification-inference-icon center"> <img src="assets/images/inferred.svg" /> </div>
                  <div class="justification-inference-text column-center-left">   {{ j.inferredJustification }}   </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

       <div class="skills-match" *ngIf="ma && ma.skillsMatching">
        <div class="table">
          <div class="tr header-row">
            <div class="th">Job Requirement Skills</div>
            <div class="th">Candidate's Relevant Skills</div>
            <div class="th align-center">Match</div>
          </div>
          <div class="tr" *ngFor="let s of job.jobAnalysis.skills; let index = index">
            <div class="td">{{ s }}</div>
            <div class="td">
              <div class="candidate-skills row wrap"
                   *ngIf="ma && ma.skillsMatching && ma.skillsMatching[index] as relevantSkillMatching">
                <div class="skill" *ngFor="let relevantSkill of relevantSkillMatching.relevantSkills">
                  {{ relevantSkill }}
                </div>
              </div>
            </div>
            <div class="td" *ngIf="ma.skillsMatching[index] as relevantSkillMatching">
              <div class="skill-match column center">
                <div class="skill-match-image center">
                  <img *ngIf="relevantSkillMatching.isMatched" src="assets/images/tick-green.svg" />
                  <img *ngIf="!relevantSkillMatching.isMatched" src="assets/images/x-gray.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </ng-container>

</ng-container>