<div class="popup column">
  <div class="popup-header row">
    <div class="popup-header-icon center"> <img src="assets/images/problem.svg"/> </div>
    <div class="fill-space column">
      <div class="popup-header-title">Report Issue </div>
      <div class="popup-header-subtitle">  Found a problem or have a suggestion to improve our app ? Submit a report to let us know. </div>
    </div>
    <div class="popup-header-close" mat-dialog-close>
      <img src="assets/images/close.svg" />
    </div>
  </div>

  <div class="popup-loader row center" *ngIf="isLoading">
    <loader></loader>
  </div>

  <div class="success fill-space column center cursor" *ngIf="isCreated" mat-dialog-close>
    <div class="success-icon center"> <img src="assets/images/tick-green.svg"> </div>
    <div class="success-title center"> Your report has been submitted. </div>
    <div class="success-text center"> Our team will try to resolve the issue as soon as possible. </div>

  </div>

  <div id="report-problem" class="popup-content column fill-space" *ngIf="!isLoading && !isCreated">

    <div class="fill-space">
      <div class="form-field column fill-space space-bottom-1">
        <div class="form-field-label">Please Describe The Issue Here:</div>
        <div class="form-field-input">
          <textarea rows="20" class="fill-space" type="text" [(ngModel)]="problemReport.description"></textarea>
        </div>
      </div>
      <!--<div class="robot column center"> <img src="assets/images/robot.svg"/> </div>-->
    </div>

    <div class="question">
      <div class="question-label center">Can we contact you regarding this issue ? </div>
      <div class="question-options">
        <div class="question-option" [class]="{'selected': problemReport.canContact}" (click)="problemReport.canContact = !problemReport.canContact">Yes</div>
        <div class="question-option" [class]="{'selected': !problemReport.canContact}" (click)="problemReport.canContact = !problemReport.canContact">No</div>
      </div>

    </div>

  </div>

  <div class="popup-buttons row" *ngIf="!isLoading && !isCreated">
    <div class="button button-gray" mat-dialog-close>Cancel</div>
    <div class="fill-space"></div>
    <div class="button button-action" *ngIf="userService.user$ | async as user" (click)="createProblemReport(user)">Submit Problem Report</div>
  </div>
</div>
