<div class="upload-file" *ngIf="resume">
  <div class="upload-file-icon column center">
      <img *ngIf="resume.status != EStatus.ERROR" [src]="resume.status === EStatus.COMPLETED ? 'assets/images/file-ready.svg' : 'assets/images/file-selected.svg'" />
      <img *ngIf="resume.status === EStatus.ERROR" src="assets/images/file-error.svg" />
  </div>
  <div class="upload-file-content column fill-space overflow-hidden">
    <div class="upload-file-name ellipsis"> {{ resume.file.originalName }} </div>
    <div class="upload-status column fill-space" *ngIf="resume.status === EStatus.UPLOADING && uploadService.uploads[resume.id]">
      <div class="row row-center-left">
        <div class="upload-file-size column center"> {{ resume.file.size / 1048576  | number:'1.2-2' }} MB </div>
        <div class="upload-file-dot column center"> <img src="assets/images/dot.svg" /> </div>
        <div class="upload-file-percentage column center"> {{ uploadService.uploads[resume.id].progress | number:'1.0-0'}}% </div>
      </div>
      <div class="upload-file-progress"> <progress-bar class="fill-space" [progress]="uploadService.uploads[resume.id].progress"></progress-bar></div>
    </div>
    <div class="upload-status column fill-space" *ngIf="resume.status === EStatus.STORED || resume.status === EStatus.PROCESSING">
      <div class="row row-center-left">
        <div class="upload-file-size column center"> UPLOADED </div>
        <div class="upload-file-dot column center"> <img src="assets/images/dot.svg" /> </div>
        <div class="upload-file-processing">
          <div class="upload-file-processing-label column column-center-left"> Processing...  </div>
          <div class="upload-file-processing-image column center"> <img src="assets/images/gear.svg" /> </div>
        </div>
      </div>
      <div class="upload-file-progress"> <progress-bar class="fill-space"  [progress]="100"></progress-bar></div>
    </div>
    <div class="upload-status column column-center-left fill-space" *ngIf="resume.status === EStatus.COMPLETED">
      <div class="row row-center-left">
        <div class="upload-file-size column column-center-left"> UPLOADED </div>
        <div class="upload-file-dot column column-center-left"> <img src="assets/images/dot.svg" /> </div>
        <div class="upload-file-processing">
          <div class="upload-file-processing-label column column-center-left"> COMPLETED  </div>
        </div>
      </div>
    </div>
    <div class="upload-status column column-center-left fill-space" *ngIf="resume.status === EStatus.ERROR">
          <div class="row row-center-left">
              <div class="upload-file-size error-label column column-center-left"> ERROR </div>
              <div class="upload-file-dot column column-center-left"> <img src="assets/images/dot.svg" /> </div>
              <div class="upload-file-processing">
                  <div class="upload-file-processing-label error-label column column-center-left"> Processing Failed  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="upload-file-close column center" *ngIf="resume.status === EStatus.ERROR || resume.status === EStatus.STORED || resume.status === EStatus.PROCESSING" (click)="showDeleteResume()"> <img src="assets/images/close.svg" /> </div>
</div>
