<div id="pipeline-applicant" class="column">
  <div class="pipeline-applicant-header">
    <div class="fill-space"></div>
    <div class="pipeline-applicant-back" (click)="hideApplicantInfo()">
      <div class="pipeline-applicant-back-text center"> Close </div>
      <div class="pipeline-applicant-back-icon center"> <img src="assets/images/arrow-right-square.svg"/> </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
