<div class="resume-content-section column fill-space" *ngIf="hobbiesAndInterests">

  <div class="resume-content-section-header fill-space">
    <div class="resume-content-section-header-icon center"> <img src="assets/images/section-hobbies.svg"/> </div>
    <div class="resume-content-section-header-title center"> Hobbies & Interests </div>
  </div>

  <div class="resume-content-section-data column fill-space">

    <empty-list text="No Hobbies/Interests" *ngIf="!hobbiesAndInterests || !hobbiesAndInterests.length"></empty-list>

    <ng-container *ngIf="hobbiesAndInterests">
      <div class="resume-node column">
        <div class="hobbies column">
          <div class="hobbies-content row wrap">
            <div class="hobby capitalize" *ngFor="let h of hobbiesAndInterests"> {{ h.toLowerCase() }}</div>
          </div>
        </div>
      </div>
    </ng-container>


  </div>


</div>
