<div class="overview-section column" *ngIf="education">

  <div class="overview-section-title"> Education </div>

  <div class="overview-section-content column">

    <div class="overview-subsection column">
      <div class="overview-subsection-title fill-space"> Highest Degree </div>
      <div class="last-degree">
        <div class="last-degree-content overview-section-data column fill-space">
          <div>
            <div class="last-degree-title overview-section-data-value capitalize fill-space">  {{ education.highestDegree?.degree?.toLowerCase() }}  </div>
            <div class="last-degree-details"> {{education.highestDegree?.graduationYear }}</div>
          </div>
          <div class="last-degree-subtitle fill-space capitalize"> {{education.highestDegree?.institution?.toLowerCase() }}</div>
        </div>
      </div>
    </div>

    <div class="overview-subsection column">
      <div class="overview-subsection-title fill-space"> Education Level </div>
      <div class="overview-section-data row">
        <div class="overview-section-data-value fill-space"> {{education.educationLevel}}</div>
        <div class="center">
          <progress-bar-segmented [dynamicColor]="true" [divider]="2" [segments]="10" [activeSegments]="utilService.getEnumValueIndex(EEducationLevel, education.educationLevel)"></progress-bar-segmented>
        </div>
        <div class="overview-section-data-info center" (click)="showInfo(EResumeSectionType.EDUCATION)"> <img src="assets/images/info.svg" /> </div>
      </div>
    </div>

  </div>

</div>
