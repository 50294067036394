import {Injectable} from "@angular/core";
import {EApplicantGroupType, ICategoryDefinition} from "../model/IApplicantGroup";
import {IApplicant} from "../../../../model/IApplicant";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor() {}

  // Return an Enum key given the Enum Value
  getKeyByEnumValue<T extends object>(
    enumType: T,
    value: string,
  ): keyof T | undefined {
    const key = Object.keys(enumType).find(
      (key) => enumType[key as keyof T] === value,
    );
    return key as keyof T | undefined;
  }

  isValidEmail(email: string): boolean {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  getCategory(type: EApplicantGroupType): ICategoryDefinition {
    return SortedCategoryDefinitions.get(type)!;
  }

  getCategoryForScore(applicantScore: number): ICategoryDefinition {
    const category = Array.from(SortedCategoryDefinitions.values()).find(
      (category) => applicantScore >= category.percentageAbove,
    );
    return (
      category ?? _UnsortedCategoryDefinitions.get(EApplicantGroupType.NotAFit)!
    );
  }

  getCategoryForApplicant(applicant: IApplicant): ICategoryDefinition {
    if (!applicant || !applicant.matchAnalysis) {
      return this.getCategory(EApplicantGroupType.Processing);
    }
    if(applicant?.matchAnalysis?.isRejected) {
      return  this.getCategory(EApplicantGroupType.Rejected);
    }
    const applicantScore = applicant.matchAnalysis.overallScore;
    return this.getCategoryForScore(applicantScore);
  }

  getFilenameWithoutExtension(filename: string): string {
    const lastDotPosition = filename.lastIndexOf(".");
    if (lastDotPosition === -1) return filename; // Return the whole filename if no extension found
    return filename.substring(0, lastDotPosition);
  }

  getFilenameExtension(filename: string): string {
    const lastDotPosition = filename.lastIndexOf(".");
    if (lastDotPosition === -1) return ""; // Return an empty string if no extension found
    return filename.substring(lastDotPosition + 1);
  }

  // Given an Enum and an Enum value will the return the index of the value in the enum structure starting with 1.
  getEnumValueIndex(enumObj: { [s: string]: string }, enumValue: string): number {
    // Find the key in the enum using the value
    const key = Object.keys(enumObj).find(key => enumObj[key] === enumValue);

    // Return the index of the key + 1, or 0 if not found
    return key ? Object.keys(enumObj).indexOf(key) + 1 : 0;
  }
}

// This is internal to the module, should not be exported
const _UnsortedCategoryDefinitions: Map<EApplicantGroupType, ICategoryDefinition> = new Map([
  [
    EApplicantGroupType.PerfectFit,
    {
      type: EApplicantGroupType.PerfectFit,
      label: "Perfect Fit",
      icon: "statistics-perfect-fit.svg",
      styleClass: "statistics-perfect-fit",
      percentageAbove: 90,
    },
  ],
  [
    EApplicantGroupType.GreatFit,
    {
      type: EApplicantGroupType.GreatFit,
      label: "Great Fit",
      icon: "statistics-great-fit.svg",
      styleClass: "statistics-great-fit",
      percentageAbove: 80,
    },
  ],
  [
    EApplicantGroupType.GoodFit,
    {
      type: EApplicantGroupType.GoodFit,
      label: "Good Fit",
      icon: "statistics-good-fit.svg",
      styleClass: "statistics-good-fit",
      percentageAbove: 70,
    },
  ],
  [
    EApplicantGroupType.PotentialFit,
    {
      type: EApplicantGroupType.PotentialFit,
      label: "Potential Fit",
      icon: "statistics-potential-fit.svg",
      styleClass: "statistics-potential-fit",
      percentageAbove: 50,
    },
  ],
  [
    EApplicantGroupType.NotAFit,
    {
      type: EApplicantGroupType.NotAFit,
      label: "Not A Fit",
      icon: "statistics-not-a-fit.svg",
      styleClass: "statistics-not-a-fit",
      percentageAbove: 0,
    },
  ],
  [
    EApplicantGroupType.Rejected,
    {
      type: EApplicantGroupType.Rejected,
      label: "Rejected",
      icon: "rejected.svg",
      styleClass: "statistics-rejected",
      percentageAbove: 0,
    },
  ],
  [
    EApplicantGroupType.Processing,
    {
      type: EApplicantGroupType.Processing,
      label: "Processing",
      icon: "processing.svg",
      styleClass: "statistics-not-a-fit",
      percentageAbove: 0,
    },
  ],
]);

const SortedCategoryDefinitions: Map<EApplicantGroupType, ICategoryDefinition> =
  new Map(
    [..._UnsortedCategoryDefinitions].sort(
      (a, b) => b[1].percentageAbove - a[1].percentageAbove,
    ),
  );
