import { Component } from "@angular/core";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  Observable,
  Subscription,
  tap,
} from "rxjs";
import { IUserWithRoles } from "../../../../../../model/IUser";
import { UserWithOrganizationsService } from "../../../services/user-with-organizations.service";
import { FormControl } from "@angular/forms";
import { UsersApi } from "../../../api/users.api";
import { Auth } from "@angular/fire/auth";

interface NameChangeWithSource {
  name: string;
  source: "server" | "local";
}

@Component({
  selector: "settings-account",
  templateUrl: "./settings-account.component.html",
  styleUrls: ["./settings-account.component.css"],
})
export class SettingsAccountComponent {
  user$: Observable<IUserWithRoles | null>;

  nameControl = new FormControl();
  private nameChangeSubscription?: Subscription = undefined;
  userNameIsUpdating: boolean = false;

  constructor(
    private auth: Auth,
    private userWithOrganizationsService: UserWithOrganizationsService,
    private usersApi: UsersApi,
  ) {
    this.user$ =
      this.userWithOrganizationsService.userWithResolvedOrganizationsWithRole$;
  }

  ngOnInit() {
    this.nameChangeSubscription = merge(
      this.user$.pipe(
        map((organization) => organization?.name),
        filter((name) => !!name),
        map(
          (name) =>
            ({
              name: name!,
              source: "server",
            }) satisfies NameChangeWithSource,
        ),
      ),
      this.nameControl.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map(
          (name) =>
            ({
              name: name as string,
              source: "local",
            }) satisfies NameChangeWithSource,
        ),
      ),
    )
      .pipe(
        distinctUntilChanged((a, b) => a.name === b.name),
        tap((nameChangeWithSource) => {
          console.log("nameChangeWithSource", nameChangeWithSource);
        }),
      )
      .subscribe((nameChangeWithSource) => {
        if (nameChangeWithSource.source === "server") {
          this.nameControl.setValue(nameChangeWithSource.name, {
            emitEvent: false,
          });
        } else if (
          nameChangeWithSource.source === "local" &&
          !!nameChangeWithSource.name
        ) {
          this.userNameIsUpdating = true;
          this.usersApi
            .set(this.auth.currentUser?.uid!, {
              name: nameChangeWithSource.name,
            })
            .finally(() => {
              this.userNameIsUpdating = false;
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.nameChangeSubscription?.unsubscribe();
  }
}
