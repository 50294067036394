<div id="settings-menu" class="column fill-space">
  <div class="header row space-bottom-1">
    <div class="fill-space column">
      <div class="title">Settings</div>
    </div>
  </div>
  <div id="settings-menu-content" class="column">
    <a class="settings-menu-item" routerLinkActive="settings-menu-item-selected" routerLink="account"> Account </a>
    <a class="settings-menu-item" routerLinkActive="settings-menu-item-selected" routerLink="company"> Company </a>
    <a class="settings-menu-item" routerLinkActive="settings-menu-item-selected" routerLink="subscription"> Subscription </a>

    <a class="settings-menu-item" routerLinkActive="settings-menu-item-selected" routerLink="invitations"> Invitations </a>
    <a class="settings-menu-item" routerLinkActive="settings-menu-item-selected" routerLink="companies"> Companies </a>
  </div>
  <div class="fill-space"></div>
  <div id="settings-menu-logout" class="row row-center-left" (click)="signOut()">
    <div id="settings-menu-logout-icon" class="space-right-1">
      <img src="assets/images/logout.svg" />
    </div>
    <div id="settings-menu-logout-text">Logout</div>
  </div>
</div>
