import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IResume, IResumeImage} from "../../../../../model/IResume";
import {StorageApi} from "../../api/storage.api";

@Component({
  selector: 'resume-image',
  templateUrl: './resume-image.component.html',
  styleUrls: ['./resume-image.component.css']
})
export class ResumeImageComponent implements OnInit, OnChanges {

  @Input() image: IResumeImage | undefined;
  @Input() defaultProfileImage: string = 'user-profile-gray.svg';
  profileImageUrl: string | undefined;

  constructor(private storageApi: StorageApi) {
  }

  getProfileImage() {
    this.profileImageUrl = undefined;
    if (!this.image || !this.image.path) {
      return;
    }
    this.storageApi
    .getFileUrl(this.image.path)
    .then((fileUrl) => {
      if(fileUrl) {
        this.profileImageUrl = fileUrl;
      } else {
        this.profileImageUrl = undefined;
      }
    })
    .catch((error) => {
      this.profileImageUrl = undefined;
    })
    .finally(() => {});
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getProfileImage();
  }

  ngOnInit() {
    this.getProfileImage();
  }

}
