import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { IUserInOrganization } from "../../../../../../model/IUser";
import { MatDialogRef } from "@angular/material/dialog";
import { IJob } from "../../../../../../model/IJob";
import { JobsApi } from "../../../api/jobs.api";

@Component({
  selector: "job-delete",
  templateUrl: "./job-delete.component.html",
  styleUrls: ["./job-delete.component.css"],
})
export class JobDeleteComponent {
  @Input() job: IJob | undefined;
  isLoading: boolean = false;

  constructor(
    private jobsApi: JobsApi,
    private dialogRef: MatDialogRef<JobDeleteComponent>,
  ) {}

  deleteJob(): void {
    if (this.job) {
      this.isLoading = true;
      this.jobsApi
        .delete(this.job.id)
        .then((response) => {
          this.dialogRef.close();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
}
