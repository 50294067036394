import { Component, Input } from '@angular/core';
import { IResumeContentEducation } from '../../../../../../../../model/IResume';

@Component({
  selector: 'resume-content-education',
  templateUrl: './resume-content-education.component.html',
  styleUrls: ['./resume-content-education.component.css'],
})
export class ResumeContentEducationComponent {
  @Input() education: IResumeContentEducation[] | null | undefined = [];
}
