import {Component, OnInit} from '@angular/core';
import {map, Observable, of, switchMap} from "rxjs";
import {IApplicant, IApplicantRating} from "../../../../../../../../../../model/IApplicant";
import {ApplicantRatingsApi} from "../../../../../../../api/applicant.ratings.api";
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {UserFormComponent} from "../../../../../../users/user-form/user-form.component";
import {ApplicantRatingFormComponent} from "./applicant-rating-form/applicant-rating-form.component";
import {ApplicantsApi} from "../../../../../../../api/applicants.api";

@Component({
  selector: 'applicant-ratings',
  templateUrl: './applicant-ratings.component.html',
  styleUrls: ['./applicant-ratings.component.css']
})
export class ApplicantRatingsComponent implements OnInit {

  ratings$: Observable<IApplicantRating[]|undefined> | undefined;
  applicant$: Observable<IApplicant|undefined> | undefined;

  constructor(private applicantRatingsApi: ApplicantRatingsApi, private route: ActivatedRoute, private dialog: MatDialog, protected applicantsApi: ApplicantsApi) {
  }

  showCreateRating(applicant: IApplicant): void {
    if(!applicant) { return; }
    let dialogRef = this.dialog.open(ApplicantRatingFormComponent, {});
    dialogRef.componentInstance.applicant = applicant;
  }

  ngOnInit() {

    this.ratings$ = this.route.paramMap.pipe(
        switchMap((params) => {
          let applicantId = params.get("applicantId");
          return applicantId ? this.applicantRatingsApi.getAll(applicantId) : of(undefined);
        }),
        map(ratings => ratings ? ratings.sort((a, b) => b.creationTime?.toDate().getTime() - a.creationTime?.toDate().getTime()) : undefined)
    );

    this.applicant$ = this.route.paramMap.pipe(
        switchMap((params) => {
          let applicantId = params.get("applicantId");
          return applicantId ? this.applicantsApi.getOne(applicantId) : of(undefined);
        })
    );
  }

}
