<div class="popup column">
  <div class="popup-header row">
    <div class="popup-header-title fill-space">Upload & Process CVs</div>
    <div class="popup-header-close" mat-dialog-close><img src="assets/images/close.svg" /></div>
  </div>

  <div class="popup-loader row center" *ngIf="isLoading">
    <loader></loader>
  </div>

  <div class="popup-content column" *ngIf="!isLoading">
    <div class="row">
      <div class="column space-right-2">
        <div class="column">
          <div class="upload-title">Selected CV Files To Upload</div>
          <label for="fileUpload">
            <div class="dropzone column center" *ngIf="!files.length">
              <div class="dropzone-icon"><img src="assets/images/upload.svg" /></div>
              <div class="dropzone-title">
                Drag & Drop Files Here Or &nbsp; <span class="flavor semibold"> Choose Files </span> &nbsp; To Upload
              </div>
              <div class="dropzone-subtitle space-bottom-1">PDF or DOCX</div>
            </div>
            <div class="dropzone column" *ngIf="files.length">
              <div class="dropzone-uploads fill-space column">
                <div class="dropzone-file" *ngFor="let file of files; let i = index">
                  <div class="dropzone-file-icon center"><img src="assets/images/file-upload.svg" /></div>
                  <div class="dropzone-file-name fill-space column column-center-left">{{ file.name }}</div>
                  <div class="dropzone-file-delete center" (click)="removeFile($event, i)"><img src="assets/images/close.svg" /></div>
                </div>
              </div>
            </div>
          </label>
          <input id="fileUpload" type="file" multiple #uploadElement (change)="onFileSelected($event)" />
        </div>
        <div class="upload-job" *ngIf="job">
          <div class="upload-job-icon"><img src="assets/images/job.svg" /></div>
          <div class="upload-job-content column">
            <div class="upload-job-content-title">Automatically match uploaded CVs to job:</div>
            <div class="upload-job-content-text">{{ job.title }}</div>
          </div>
        </div>
        <div class="space-top-bottom-1 column center">
          <div
            class="upload-files-button column center fill-space"
            [class]="{ 'upload-files-button-disabled': !files.length, 'upload-files-button-active': files.length }"
            (click)="uploadFiles()">
            {{ !job ? 'Upload & Process ' + files.length + ' CV(s)' : 'Upload, Process & Match ' + files.length + ' CV(s)' }}
          </div>
        </div>
        <div class="upload-credits-info">
          <div class="upload-credits-info-image center"><img src="assets/images/credits.svg" /></div>
          <div class="upload-credits-info-text center">CV parsing/job matching cost 1 credit each.</div>
        </div>
      </div>
      <div class="column">
        <ng-container *ngIf="uploadService.resumesWithErrors$ | async as resumes">
          <div class="upload-title">Upload Queue {{ resumes.length ? '(' + resumes.length + ')' : null }}</div>
          <!--<div class="file-list column" *ngIf="uploadService.resumes$ | async as resumes">
          <div class="file-list-empty fill-space column center" *ngIf="!resumes.length"> No Files </div>
          <upload-file *ngFor="let resume of resumes; let index = index;" [resume]="resume"></upload-file>
        </div>-->
          <cdk-virtual-scroll-viewport class="file-list" itemSize="75">
            <upload-file *cdkVirtualFor="let resume of resumes; let index = index" [resume]="resume"></upload-file>
          </cdk-virtual-scroll-viewport>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="popup-buttons row">
    <div class="fill-space"></div>
    <div class="button button-gray" mat-dialog-close>Close</div>
    <div class="fill-space"></div>
  </div>
</div>
