<div id="applicants" class="overflow-hidden fill-space">

  <div class="page-column column fill-space-equal">
    <div class="job-details-data column scrollbar">
      <div class="column">
        <div class="gap-1 space-bottom-1">
          <div class="job-details-search search row fill-space">
            <div class="search-icon"><img src="assets/images/search.svg" /></div>
            <div class="search-input fill-space">
              <input type="text" placeholder="Search Applicants" class="fill-space" [formControl]="searchControl"/>
            </div>
          </div>
          <div class="job-banner-button  center" (click)="showScoringGuidelines()">
            <div class="button button-action ai-settings-button">
              <div class="button-icon"> <img src="assets/images/magic-wand.svg"/> </div>
              <div class="button-text center">  AI Settings </div>
            </div>
          </div>
          <div class="job-banner-button  center" *ngIf="job?.status === EStatus.COMPLETED" (click)="showAddApplicant()">
            <div class="button button-action add-applicants-button">
              <div class="button-icon"> <img src="assets/images/plus-white.svg"/> </div>
              <div class="button-text center">  Add Applicants  </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="filteredApplicants$ | async as filteredApplicants">
          <div class="section column" *ngIf="filteredApplicants.length; else empty">
            <job-applicant-category
                *ngFor="let g of groups"
                [group]="g" [applicants$]="filteredApplicants$"
                [selectedApplicantId]="selectedApplicantId">
            </job-applicant-category>
          </div>
        </ng-container>
        <div class="section column">
          <job-applicant-uploads [jobId]="jobId"></job-applicant-uploads>
        </div>
      </div>
    </div>
  </div>

  <!-- Applicant -->
  <div class="page-column column fill-space-equal">
    <router-outlet></router-outlet>
  </div>

</div>


<!-- No items message -->
<ng-template #empty>
  <div class="empty column center" *ngIf="job?.status === EStatus.COMPLETED">
    <div class="empty-icon"> <img src="assets/images/empty.svg"/> </div>
    <div class="empty-title"> No Applicants  </div>
    <div class="empty-button space-top-1" (click)="showAddApplicant()">
      <div class="button-small button-action"> + Add Applicants To Job </div>
    </div>
  </div>
</ng-template>

<!-- Loading message -->
<ng-template #loading>
  <div class="empty column center">
    <loader></loader>
  </div>
</ng-template>