import { Component } from "@angular/core";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent {
  email: string | undefined;
  isLoading: boolean = false;
  error: string | undefined;
  isEmailSent: boolean = false;
  errorMessages: { [key: string]: string } = {
    "auth/invalid-email": "Invalid Email Address",
    "auth/user-not-found": "User Does Not Exist",
  };

  constructor(private authService: AuthService) {}

  sendPasswordResetEmail() {
    if (!this.email) {
      return;
    }
    this.isLoading = true;
    this.authService
      .sendPasswordResetEmail(this.email)
      .then((response) => {
        this.isEmailSent = true;
      })
      .catch((error) => {
        this.error = this.errorMessages[error.code];
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
